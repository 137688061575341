import React from 'react';
import { Navigate } from 'react-router-dom';
import SidebarPage from '../SidebarPage';
import Nabvar from '../Navbar';

type PrivateRouteProps = {
  auth: boolean;
  Component: any;
  show: string;
};

const PrivateRoute = ({ auth, Component, show }: PrivateRouteProps) => {
  if (!auth) {
    return <Navigate to="/login" />;
  }

  if (show) {
    return (
      <div>
        <SidebarPage component={Component} Show={show} />
      </div>
    );
  }

  return (
    <div>
      <Nabvar show={true} />
      {Component}
    </div>
  );
};

export default PrivateRoute;
