import React, { useState, useEffect } from 'react';
import 'rodal/lib/rodal.css';
import { notification } from '../../services/services';
import { GithubOwnersResponse } from '../../types/api';
import { createUdfProject, getGithubOwners } from '../../services/services';
import { Input, SelectPicker } from 'rsuite';
import cronstrue from 'cronstrue';
import { useSelector } from 'react-redux';
import { State } from '../../types/state';

const Rodal = require('rodal').default;

type DbtFormModalProps = {
  show: boolean;
  onHide: () => void;
  organizationId: number;
  destinationId: number;
};

const DbtFormModal = (props: DbtFormModalProps) => {
  const account = useSelector((state: State) => state.app?.account);
  const [githubOwners, setGithubOwners] = useState<GithubOwnersResponse>([]);
  const [selectedGithubOwner, setSelectedGithubOwner] = useState<string>('');
  const [repositoryName, setRepositoryName] = useState<string>('');
  const [branchName, setBranchName] = useState<string>('main');
  const [cronExpression, setCronExpression] = useState<string>('0 * * * *');
  const [cronExplanation, setCronExplanation] = useState<string>('Every hour');
  const handleSubmit = () => {
    if (!isValid()) {
      return;
    }

    (async () => {
      try {
        await createUdfProject({
          name: repositoryName,
          organization_id: props.organizationId,
          destination_id: props.destinationId,
          project_type: 'dbt',
          run_configuration: {
            all: cronExpression
          },
          github: {
            login: selectedGithubOwner,
            repository_name: repositoryName,
            branch: branchName
          }
        });
        props.onHide();
        notification('DBT project created successfully', 'success');
      } catch (e) {
        notification('Failed to create DBT project', 'warning');
        console.error(e);
      }
    })();
  };

  useEffect(() => {
    if (githubOwners.length > 0) {
      return;
    }

    (async () => {
      try {
        const response = await getGithubOwners();
        setGithubOwners(response);
      } catch (e) {
        console.error(e);
      }
    })();
  });

  if (!account) {
    return null;
  }
  const dbtOnly =
    account.organization.feature_flags &&
    account.organization.feature_flags['dbt_only'] === true;

  const handleCronExpressionChange = (value: string | null) => {
    if (value === null) {
      return;
    }
    setCronExpression(value);
    try {
      const explanation = cronstrue.toString(value);
      if (
        (dbtOnly && explanation.match(/^Every \d* minutes/)) ||
        explanation.startsWith('Every minute')
      ) {
        setCronExplanation('Invalid expression (must be at least hourly)');
        return;
      }
      setCronExplanation(explanation);
    } catch (e) {
      setCronExplanation('Invalid expression');
    }
  };

  const handleGithubOwnerSelect = (value: string | null) => {
    if (!value) {
      return;
    }
    setSelectedGithubOwner(value);
  };

  const handleRepositoryNameChange = (value: string | null) => {
    if (value === null) {
      return;
    }
    setRepositoryName(
      value.replace(/\s/g, '_').replace(/[^\w]/g, '').toLowerCase()
    );
  };

  const handleBranchNameChange = (value: string | null) => {
    if (value === null) {
      return;
    }
    setBranchName(
      value.replace(/\s/g, '_').replace(/[^\w]/g, '').toLowerCase()
    );
  };

  const isValid = (): boolean => {
    return (
      selectedGithubOwner !== '' &&
      repositoryName !== '' &&
      branchName !== '' &&
      !cronExplanation.startsWith('Invalid expression')
    );
  };

  return (
    <>
      <div className="dbt_form">
        <Rodal
          visible={props.show}
          onClose={props.onHide}
          className="popmodal"
          height={350}
          width={350}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex justfiy-content-center">
                  <h4>Create DBT Project</h4>
                </div>
              </div>
              <div className="d-flex justfiy-content-center mt-4">
                <SelectPicker
                  data={githubOwners.map((owner) => ({
                    label: owner.name,
                    value: owner.name
                  }))}
                  placeholder="Pick Github Owner"
                  menuStyle={{ zIndex: 9999 }}
                  style={{ width: '100%' }}
                  value={selectedGithubOwner}
                  onChange={handleGithubOwnerSelect}
                />
              </div>
              <div className="d-flex justfiy-content-center mt-4">
                <Input
                  placeholder="Repository Name"
                  value={repositoryName}
                  onChange={handleRepositoryNameChange}
                />
              </div>
              <div className="d-flex justfiy-content-center mt-4">
                <Input
                  placeholder="Branch Name"
                  value={branchName}
                  onChange={handleBranchNameChange}
                />
              </div>
              <div className="d-flex mt-2">
                <span
                  className="text-muted"
                  style={{ fontSize: 12, marginLeft: 4 }}
                >
                  {cronExplanation}
                </span>
              </div>
              <div className="d-flex justfiy-content-center">
                <Input
                  placeholder="Scheduling"
                  value={cronExpression}
                  onChange={handleCronExpressionChange}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="d-flex justify-content-center">
                {isValid() && (
                  <button
                    onClick={handleSubmit}
                    className="modalBtnMovinglake w-full"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </Rodal>
      </div>
    </>
  );
};

export default DbtFormModal;
