import React, { useState, useEffect } from 'react';
import { getDestinations } from '../../services/services';
import { DestinationListed } from '../../types/api';
import { Mixpanel } from '../common/Mixpanel';
import DatabaseBrowser from './DataBrowser';

const Queries = () => {
  const [dataSources, setDataSources] = useState<DestinationListed[]>([]);
  const [selectedDataSource, setSelectedDataSource] = useState<number | null>(
    null
  );

  Mixpanel.track('Dashboard - Data Visualization - Queries', {
    is_page_view: true
  });

  useEffect(() => {
    fetchDataSources();
  }, []);

  const fetchDataSources = async () => {
    const destinations = await getDestinations();

    setDataSources(destinations);
    setSelectedDataSource(destinations[0].id);
    return getDestinations();
  };

  return (
    <>
      <div className="container-setting">
        <h4 className="payment-details-1 mb-5">BROWSE YOUR DESTINATIONS</h4>
        <div className="row">
          <div className="col-md-12">
            <h4 className="mt-0 mb-3" style={{ marginLeft: 0 }}>
              Choose the datasource to browse
            </h4>
            <div title="Sources" className="my-4">
              <div id="dropdown-basic" style={{ display: 'flex' }}>
                {
                  <div>
                    <img
                      height="30px"
                      src={`${process.env.REACT_APP_API_URL}/static/media/${
                        dataSources.find(
                          (dataSource) => dataSource.id === selectedDataSource
                        )?.icon
                      }`}
                      alt={
                        dataSources.find(
                          (dataSource) => dataSource.id === selectedDataSource
                        )?.name
                      }
                      className="mx-2"
                    />
                    <span className="mx-2">
                      {' '}
                      {
                        dataSources.find(
                          (dataSource) => dataSource.id === selectedDataSource
                        )?.name
                      }
                    </span>
                  </div>
                }
              </div>
              <div>
                {dataSources.map((dataSource) => (
                  <div
                    key={dataSource.id}
                    //action
                    //active={selectedDataSource === dataSource.id}
                    onClick={() => setSelectedDataSource(dataSource.id)}
                  >
                    <img
                      height="30px"
                      src={`${process.env.REACT_APP_API_URL}/static/media/${dataSource.icon}`}
                      alt={dataSource.name}
                      className="mx-2"
                    />
                    <span className="mx-2"> {dataSource.name}</span>
                  </div>
                ))}
              </div>
            </div>
            <DatabaseBrowser
              destination={dataSources.find(
                (dataSource) => dataSource.id === selectedDataSource
              )}
            ></DatabaseBrowser>
          </div>
        </div>

        <div className="right-bg" style={{ height: '500px' }}></div>
      </div>
    </>
  );
};

export default Queries;
