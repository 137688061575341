import React from 'react';
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  LineChart,
  Line,
  PolarAngleAxis,
  ComposedChart,
  ScatterChart,
  PolarGrid,
  PieChart,
  PolarRadiusAxis,
  Pie,
  RadarChart,
  Radar,
  RadialBarChart,
  RadialBar,
  Treemap,
  FunnelChart,
  Funnel,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Scatter,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { SqlResponseGraphData } from '../../types/api';

const colors = [
  '#0884d8',
  '#82ca9d',
  '#ffc658',
  '#ff7300',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#0088FE'
];

/*
const chartTypes = [
  'area',
  'bar',
  'line',
  'composed',
  'scatter',
  'pie',
  'radar',
  'radial',
  'treemap',
  'funnel'
];
*/

interface ChartProps {
  data: SqlResponseGraphData;
  chartType: string;
  xAxisIndex: number;
  setChartType: (chartType: string) => void;
  setXAxis: (xAxisIndex: number) => void;
}

interface RechartsData {
  name: string;
  [key: string]: any;
}

export const Chart: React.FC<ChartProps> = ({
  data,
  chartType,
  xAxisIndex,
  setChartType,
  setXAxis
}) => {
  const renderChart = () => {
    chartType = chartType.toLowerCase();
    if (data.columns.length > 7) {
      return <div>Too many columns to render chart</div>;
    }
    const rechartsData = data.rows
      .map((row: any, idx: number) => {
        if (row.length === 0 || row[xAxisIndex] === null) {
          return [];
        }
        let retval: RechartsData = {
          name: row[xAxisIndex]
        };
        for (let i = 0; i < row.length; i++) {
          if (i !== xAxisIndex) {
            const colName = data.columns[i];
            retval[colName] = row[i];
          }
        }
        return retval;
      })
      .flat(1);
    if (chartType === undefined || chartType === null || chartType === '') {
      chartType = 'line';
    }
    switch (chartType) {
      case 'area':
        return (
          <AreaChart width={500} height={300} data={rechartsData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            {data.columns.map((colName: string, idx: number) => {
              if (idx !== xAxisIndex) {
                return (
                  <Area
                    type="monotone"
                    dataKey={colName}
                    fill={colors[idx % colors.length]}
                  />
                );
              }
              return null;
            })}
          </AreaChart>
        );
      case 'bar':
        return (
          <BarChart width={500} height={300} data={rechartsData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {data.columns.map((colName: string, idx: number) => {
              if (idx !== xAxisIndex) {
                return (
                  <Bar
                    type="monotone"
                    dataKey={colName}
                    fill={colors[idx % colors.length]}
                  />
                );
              }
              return null;
            })}
          </BarChart>
        );
      case 'line':
        return (
          <LineChart data={rechartsData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {data.columns.map((colName: string, idx: number) => {
              if (idx !== xAxisIndex) {
                return (
                  <Line
                    type="monotone"
                    dataKey={colName}
                    fill={colors[idx % colors.length]}
                  />
                );
              }
              return null;
            })}
          </LineChart>
        );
      case 'composed':
        return (
          <ComposedChart width={500} height={300} data={rechartsData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {data.columns.map((colName: string, idx: number) => {
              if (idx !== xAxisIndex && idx % 2 === 0) {
                return (
                  <Line
                    type="monotone"
                    dataKey={colName}
                    fill={colors[idx % colors.length]}
                  />
                );
              }
              return null;
            })}
            {data.columns.map((colName: string, idx: number) => {
              if (idx !== xAxisIndex && idx % 2 === 1) {
                return (
                  <Bar
                    dataKey={colName}
                    fill={colors[(idx + 1) % colors.length]}
                  />
                );
              }
              return null;
            })}
          </ComposedChart>
        );
      case 'scatter':
        return (
          <ScatterChart width={500} height={300} data={rechartsData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {data.columns.map((colName: string, idx: number) => {
              if (idx !== xAxisIndex) {
                return (
                  <Scatter
                    dataKey={colName}
                    fill={colors[idx % colors.length]}
                  />
                );
              }
              return null;
            })}
          </ScatterChart>
        );
      case 'pie':
        return (
          <PieChart width={500} height={500}>
            {data.columns.map((colName: string, idx: number) => {
              if (idx !== xAxisIndex) {
                return (
                  <Pie
                    data={rechartsData}
                    dataKey={colName}
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    fill={colors[idx % colors.length]}
                    label
                  />
                );
              }
              return null;
            })}
            <Tooltip />
            <Legend />
          </PieChart>
        );
      case 'doughnut':
        return (
          <PieChart width={500} height={500}>
            {data.columns.map((colName: string, idx: number) => {
              if (idx !== xAxisIndex) {
                return (
                  <Pie
                    data={rechartsData}
                    dataKey={colName}
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={30}
                    fill={colors[idx % colors.length]}
                    label
                  />
                );
              }
              return null;
            })}
            <Tooltip />
            <Legend />
          </PieChart>
        );
      case 'radar':
        return (
          <RadarChart
            cx={300}
            cy={250}
            outerRadius={150}
            width={600}
            height={500}
            data={rechartsData}
          >
            <PolarGrid />
            <PolarAngleAxis dataKey="name" />
            <PolarRadiusAxis />
            <Tooltip />
            <Legend />
            {data.columns.map((colName: string, idx: number) => {
              if (idx !== xAxisIndex) {
                return (
                  <Radar
                    fillOpacity={0.6}
                    dataKey={colName}
                    fill={colors[idx % colors.length]}
                    stroke={colors[idx % colors.length]}
                  />
                );
              }
              return null;
            })}
          </RadarChart>
        );
      case 'radialBar':
        return (
          <RadialBarChart
            width={500}
            height={300}
            cx={150}
            cy={150}
            innerRadius={20}
            outerRadius={140}
            barSize={10}
            data={rechartsData}
          >
            <RadialBar
              angleAxisId={15}
              label={{ position: 'insideStart', fill: '#fff' }}
              dataKey="value"
            />
            {data.columns.map((colName: string, idx: number) => {
              if (idx !== xAxisIndex) {
                return (
                  <RadialBar
                    angleAxisId={15}
                    dataKey={colName}
                    label={{ position: 'insideStart', fill: '#fff' }}
                  />
                );
              }
              return null;
            })}
            <Legend
              iconSize={10}
              width={120}
              height={140}
              layout="vertical"
              verticalAlign="middle"
              align="right"
            />
          </RadialBarChart>
        );
      case 'treemap':
        return (
          <div>
            {data.columns.map((colName: string, idx: number) => {
              if (idx !== xAxisIndex) {
                return (
                  <Treemap
                    width={500}
                    height={300}
                    data={rechartsData}
                    dataKey={colName}
                    stroke="#fff"
                    fill={colors[idx % colors.length]}
                  >
                    <Tooltip />
                  </Treemap>
                );
              }
              return null;
            })}
          </div>
        );
      case 'funnel':
        return (
          <FunnelChart width={500} height={300} data={rechartsData}>
            <Tooltip />
            <Funnel dataKey="value" />
            {data.columns.map((colName: string, idx: number) => {
              if (idx !== xAxisIndex) {
                return <Funnel dataKey={colName} />;
              }
              return null;
            })}
          </FunnelChart>
        );
      default:
        return <p>Unsupported chart type.</p>;
    }
  };

  return (
    <div>
      <div>
        <div>
          <h5 className="mx-2" style={{ display: 'inline-block' }}>
            XAxis Column:{' '}
          </h5>
          {/*
            <Dropdown
            title="X-Axis"
            className="my-4 mx-2"
            style={{ display: 'inline-block' }}
          >
            <Dropdown.Toggle id="dropdown-basic" style={{ display: 'flex' }}>
              {data.columns[xAxisIndex]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {data.columns.map((col: string, idx: number) => {
                return (
                  <Dropdown.Item key={idx} onClick={() => setXAxis(idx)}>
                    {col}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
            */}
        </div>
        <div>
          <h5 className="mx-2" style={{ display: 'inline-block' }}>
            Chart Type:
          </h5>
          {/*
            <Dropdown
            title="ChartType"
            className="my-4 mx-2"
            style={{ display: 'inline-block' }}
          >
            <Dropdown.Toggle id="dropdown-basic" style={{ display: 'flex' }}>
              {chartType.charAt(0).toUpperCase() + chartType.slice(1)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {chartTypes.map((type: string, idx: number) => {
                return (
                  <Dropdown.Item key={idx} onClick={() => setChartType(type)}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          */}
        </div>
      </div>
      <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
        {renderChart()}
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
