import React, { useEffect, useRef, useState } from 'react';
import { GrConnectivity } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../types/state';
import { AutoComplete, Input, InputGroup, Toggle } from 'rsuite';
import { ImSearch } from 'react-icons/im';
import SelectPicker from 'rsuite/SelectPicker';
import { EntityRepeatConfiguration, EntitySpec } from '../../types/api';
import { ConnectorActionType } from '../../reducers/connector';
import { useNavigate } from 'react-router-dom';
import Path from '../../Path';
import {
  ConnectorCreateRequest,
  createConnector,
  notification
} from '../../services/services';
import { AppActionType } from '../../reducers/app';
import cronstrue from 'cronstrue';
import { Mixpanel } from '../common/Mixpanel';
import PaginationC from '../common/PaginationC';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { Loader as Loading } from 'rsuite';
import CustomParamsModal from './modals/CustomParamsModal';
import ConfirmationConnector from './ConfirmationConnenctor';
import ConfirmationModal from './modals/ConfirmationModal';
import InfoModal from './modals/InfoModal';

const EntitiesForm = () => {
  const [stateLoaded, setStateLoaded] = useState(false);
  const [backfillDate, setBackfillDate] = useState<Date | null>(null);
  const [textFilter, setTextFilter] = useState('');
  const [active, setActivePage] = useState(1);
  const [activeParams, setActiveParamsPage] = useState(1);
  const [hasUrl, setHasUrl] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showParamsModal, setShowParamsModal] = useState(false);
  const [editingEntity, setEditingEntity] = useState({} as EntitySpec);
  const [customParams, setCustomParams] = useState({} as any);
  const [connectorCustomParams, setConnectorCustomParams] = useState({} as any);
  const [showConfirmationCreation, setShowConfirmationCreation] =
    useState(false);
  const [showMainEntityConfirmation, setShowMainEntityConfirmation] =
    useState(false);
  const [mainValue, setMainValue] = useState(null as any);
  const [mainEntity, setMainEntity] = useState({} as EntitySpec);
  const [
    showActivateMainEntityConfirmation,
    setShowActivateMainEntityConfirmation
  ] = useState(false);

  const connector = useSelector((state: State) => state.connector);
  const destination = useSelector(
    (state: State) => state.connector.destination
  );
  const entitiesConfigurations = useSelector(
    (state: State) => state.connector?.template?.entities || []
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const itemsPerPage = 9;
  const connectorRef = useRef(connector);
  const dispatchRef = useRef(dispatch);

  useEffect(() => {
    connectorRef.current = connector;
    Mixpanel.track('Connector Creation - Configure Entities', {
      is_page_view: true,
      source_type: connector.template?.connector.code
    });
  }, [connector]);
  useEffect(() => {
    dispatchRef.current = dispatch;
  }, [dispatch]);

  const sortMainEntities = (a: EntitySpec, b: EntitySpec): number => {
    if (
      tree(entitiesConfigurations)[a.name] &&
      !tree(entitiesConfigurations)[b.name]
    ) {
      return -1;
    } else if (
      !tree(entitiesConfigurations)[a.name] &&
      tree(entitiesConfigurations)[b.name]
    ) {
      return 1;
    }
    return 0;
  };

  const handleBackfillUpdate = (value: Date) => {
    setBackfillDate(value);
    dispatch({
      type: ConnectorActionType.CONNECTOR_SET_BACKFILL_DATE,
      payload: value
    });
  };

  const handleCreate = async () => {
    if (!connector.template) {
      return;
    }
    const payload: ConnectorCreateRequest = {
      name: connector.sourceFormValues.name,
      description: connector.sourceFormValues.description,
      connector_category_id: connector.template.connector.id,
      token_uid: connector.sourceFormValues.tokenUid,
      credentials: connector.sourceFormValues.credentials,
      targets: [
        {
          destination_id: connector.destination.id,
          entities: Object.values(connector.entities),
          custom_params: connectorCustomParams
        }
      ]
    };
    try {
      setLoading(true);
      const response = await createConnector(payload);
      dispatch({
        type: AppActionType.ADD_CONNECTOR,
        payload: {
          id: response.data.id,
          name: response.data.name,
          type: response.data.connector_cat_name
        }
      });
      setLoading(false);
      notification('Connector created successfully', 'success');
      navigate(Path.ConnectorList);
    } catch (e: any) {
      console.error(e);
      notification(
        'Could not create connector: ' + JSON.stringify(e.response.data),
        'warning'
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasUrl) {
      return;
    }
    if (!destination.type) {
      return;
    }
    if (destination?.type.toLowerCase().startsWith('webhook')) {
      setHasUrl(true);
    }
  }, [hasUrl, destination]);

  useEffect(() => {
    if (!connectorRef.current.template?.entities) {
      return;
    }

    dispatchRef.current({
      type: ConnectorActionType.CONNECTOR_SET_ENTITIES,
      payload: connectorRef.current.template.entities
    });

    setStateLoaded(true);
  }, []);

  const cronExpressionText = (value: string): string => {
    if (value.trim() === '') {
      return 'Enter cron expression';
    }
    try {
      return cronstrue.toString(value);
    } catch (e) {
      return 'Invalid cron expression';
    }
  };

  if (!stateLoaded) {
    return null;
  }

  const displayEntityName = (entity: string, owner: string) => {
    if (!entity) return;
    const reg_ex = new RegExp(owner + '_', 'g');
    let entityName = entity.replace(reg_ex, '');
    return entityName
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const onCustomParamsChange = (values: any, actions: any) => {
    actions.setValues({});
    const combinedParams = { ...values, ...customParams };

    const currentParams = { ...connectorCustomParams };
    currentParams[editingEntity.name] = {
      customParams: combinedParams
    };
    setConnectorCustomParams(currentParams);

    setEditingEntity({} as EntitySpec);
    setShowParamsModal(false);
    notification(`${editingEntity.name} custom params set`, 'success');
  };

  const onCustomChange = (value: any, name: string) => {
    setCustomParams({
      ...customParams,
      [name]: value
    });
  };

  const tree = (entitiesConfigs: EntitySpec[]) => {
    const data = entitiesConfigs.filter((entity) => {
      if (entity.skip_write) return false;
      return true;
    });

    const treeRecord: Record<any, any> = {};

    for (const entity of data) {
      const name = entity.name;
      const everyRecord = entity.every_record;
      if (everyRecord) {
        if (treeRecord[everyRecord.name]) {
          treeRecord[everyRecord.name].push(name);
        } else {
          treeRecord[everyRecord.name] = [name];
        }
      }
    }

    entitiesConfigs.forEach((entity) => {
      if (entity.skip_write) delete treeRecord[entity.name];
      return true;
    });

    return treeRecord;
  };

  const deactivateEntity = (entity: any, value: boolean) => {
    dispatch({
      type: ConnectorActionType.CONNECTOR_UPDATE_ENTITY,
      payload: { id: entity.id, is_active: value }
    });
    tree(entitiesConfigurations)[entity.name] &&
      tree(entitiesConfigurations)[entity.name].forEach((child: any) => {
        deactivateEntity(
          entitiesConfigurations.find((entity) => entity.name === child),
          value
        );
      });

    setShowMainEntityConfirmation(false);
  };

  const includeMainEntity = (entity: any, data: any) => {
    for (const value in data) {
      if (Object.prototype.hasOwnProperty.call(data, value)) {
        const values = data[value];
        if (values.includes(entity.name)) {
          return true;
        }
      }
    }
    return false;
  };

  const getMainEntity = (entity: any, data: any) => {
    for (const value in data) {
      if (Object.prototype.hasOwnProperty.call(data, value)) {
        const values = data[value];
        if (values.includes(entity.name)) {
          return value;
        }
      }
    }
    return false;
  };

  const getMainEntityStatus = (entity: any, data: any) => {
    for (const value in data) {
      if (Object.prototype.hasOwnProperty.call(data, value)) {
        const values = data[value];
        if (values.includes(entity.name)) {
          const main = entitiesConfigurations.find(
            (entity) => entity.name === value
          ) || { id: 0 };
          const mainActivated = connector.entities[main.id];
          return mainActivated?.is_active;
        }
      }
    }
    return false;
  };

  return (
    <>
      <div className="container mx-auto px-5 pb-10 lg:px-0">
        <div className="flex md:items-center md:justify-between pt-5 rounded-lg">
          <div className="min-w-0 flex-1 self-center">
            <h1 className="flex text-lg md:text-3xl text-gray-900 font-bold sm:truncate sm:tracking-tight">
              <GrConnectivity className="self-center h-9 w-9" /> -{' '}
              {connectorRef.current.sourceFormValues.name}
            </h1>
            <p className="mt-2 text-base">
              To personalize your connector, you can configure the entities you
              want to extract from the source activating or deactivating them by
              the switch. You can also configure the extraction frequency.
            </p>
          </div>
          <div className="flex">
            {!loading ? (
              <button
                type="button"
                className="h-fit btnMovinglake w-fit"
                onClick={() => setShowConfirmationCreation(true)}
              >
                <PlusCircleIcon className="h-6 w-6 mx-auto" />
                <span className="hidden lg:block lg:ml-1">
                  Create Connector
                </span>
              </button>
            ) : (
              <Loading vertical={true} size="sm" className="mt-4" />
            )}
          </div>
        </div>
        <hr className="h-px my-3 mb-6 bg-gray-200 border-0" />
        <div className="mt-4">
          <InputGroup>
            <AutoComplete
              data={entitiesConfigurations
                .filter((entity) => {
                  if (entity.skip_write) return false;
                  return true;
                })
                .map((item) => item.name)}
              placeholder="Search entity"
              onChange={setTextFilter}
              value={textFilter}
            />
            <InputGroup.Button tabIndex={-1}>
              <ImSearch />
            </InputGroup.Button>
          </InputGroup>
        </div>

        <ul className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
          {entitiesConfigurations
            .sort(sortMainEntities)
            .filter((entity) => {
              if (entity.skip_write) return false;
              return true;
            })
            .map((entity, key) => {
              if (
                !(
                  key >=
                    (active === 1 ? active - 1 : (active - 1) * itemsPerPage) &&
                  key < active * itemsPerPage
                )
              ) {
                return null;
              }
              if (textFilter && !entity.name.includes(textFilter)) {
                return null;
              }
              return (
                <li
                  key={entity.id}
                  className="overflow-hidden rounded-xl border border-gray-200"
                >
                  <div
                    className={`border-b border-gray-900/5 p-4 ${
                      tree(entitiesConfigurations)[entity.name]
                        ? 'text-white bg-[#223c7d]'
                        : 'bg-gray-50'
                    }`}
                  >
                    <div className="flex items-center gap-x-4 ">
                      <img
                        src={`${process.env.REACT_APP_API_URL}${connectorRef.current.template?.connector.icon}`}
                        alt={entity.name}
                        className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                      />
                      <div
                        className={`text-base font-bold ${
                          tree(entitiesConfigurations)[entity.name]
                            ? 'text-white'
                            : 'text-gray-900'
                        }`}
                      >
                        {displayEntityName(
                          entity.name,
                          connectorRef.current.template?.connector.code || ''
                        )}
                      </div>
                      <div className="relative ml-auto">
                        <Toggle
                          checked={connector.entities[entity.id]?.is_active}
                          onChange={(value) => {
                            if (tree(entitiesConfigurations)[entity.name]) {
                              if (value === true) {
                                if (
                                  includeMainEntity(
                                    entity,
                                    tree(entitiesConfigurations)
                                  ) &&
                                  getMainEntityStatus(
                                    entity,
                                    tree(entitiesConfigurations)
                                  ) === false
                                ) {
                                  let name = getMainEntity(
                                    entity,
                                    tree(entitiesConfigurations)
                                  );

                                  setMainEntity(
                                    entitiesConfigurations.find((entity) => {
                                      return entity.name === name;
                                    }) || ({} as EntitySpec)
                                  );

                                  setMainValue(value);
                                  setShowActivateMainEntityConfirmation(true);
                                } else {
                                  deactivateEntity(entity, value);
                                }
                              } else {
                                setMainEntity(entity);
                                setMainValue(value);
                                setShowMainEntityConfirmation(true);
                              }
                            } else {
                              if (
                                includeMainEntity(
                                  entity,
                                  tree(entitiesConfigurations)
                                ) &&
                                getMainEntityStatus(
                                  entity,
                                  tree(entitiesConfigurations)
                                ) === false
                              ) {
                                let name = getMainEntity(
                                  entity,
                                  tree(entitiesConfigurations)
                                );

                                setMainEntity(
                                  entitiesConfigurations.find((entity) => {
                                    return entity.name === name;
                                  }) || ({} as EntitySpec)
                                );

                                setMainValue(value);
                                setShowActivateMainEntityConfirmation(true);
                              } else {
                                dispatch({
                                  type: ConnectorActionType.CONNECTOR_UPDATE_ENTITY,
                                  payload: { id: entity.id, is_active: value }
                                });
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-5 flex flex-col">
                      {entity.description}
                      {includeMainEntity(
                        entity,
                        tree(entitiesConfigurations)
                      ) && (
                        <p
                          className={`${
                            tree(entitiesConfigurations)[entity.name]
                              ? 'text-white'
                              : 'text-red-600'
                          } font-bold`}
                        >
                          Requires the entity{' '}
                          {displayEntityName(
                            entity.every_record?.name || '',
                            connectorRef.current.template?.connector.code || ''
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                  <dl className="-my-3 divide-y divide-gray-100 p-4 text-sm leading-6">
                    <div className="grid grid-cols-2 gap-x-2 py-3">
                      <dt className="text-gray-500">Extraction frequency</dt>
                      <dd>
                        {entity.timing_configuration.strategy ===
                        'repeat_every' ? (
                          <SelectPicker
                            size="xs"
                            className="w-full"
                            placement="autoVerticalEnd"
                            value={
                              (
                                connector.entities[entity.id]
                                  ?.timing_configuration as {
                                  repeat_every: number;
                                }
                              )?.repeat_every
                            }
                            onChange={(value) => {
                              dispatch({
                                type: ConnectorActionType.CONNECTOR_UPDATE_ENTITY,
                                payload: {
                                  id: entity.id,
                                  timing_configuration: {
                                    repeat_every: value
                                  }
                                }
                              });
                            }}
                            data={(
                              entity.timing_configuration as EntityRepeatConfiguration
                            ).validation_data.allowed_repeat_every.map(
                              (item) => ({
                                label: item.label,
                                value: item.seconds
                              })
                            )}
                            cleanable={false}
                          />
                        ) : (
                          <td style={{ width: '20%', padding: '18px 0 0 0 ' }}>
                            <div
                              className="d-flex flex-column justify-content-between"
                              style={{ height: '100%' }}
                            >
                              <Input
                                size="sm"
                                style={{
                                  width: '90%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto'
                                }}
                                placeholder="Cron expression"
                                onChange={(value) => {
                                  dispatch({
                                    type: ConnectorActionType.CONNECTOR_UPDATE_ENTITY,
                                    payload: {
                                      id: entity.id,
                                      timing_configuration: {
                                        cron_expression: value
                                      }
                                    }
                                  });
                                }}
                                value={
                                  (
                                    connector.entities[entity.id]
                                      ?.timing_configuration as {
                                      cron_expression: string;
                                    }
                                  ).cron_expression || ''
                                }
                              />
                              <span
                                className="text-muted"
                                style={{
                                  fontSize: 11,
                                  textAlign: 'left',
                                  marginLeft: 4
                                }}
                              >
                                {cronExpressionText(
                                  (
                                    connector.entities[entity.id]
                                      ?.timing_configuration as {
                                      cron_expression: string;
                                    }
                                  ).cron_expression || ''
                                )}
                              </span>
                            </div>
                          </td>
                        )}
                      </dd>
                      {hasUrl && (
                        <dd>
                          <Input
                            size="sm"
                            placeholder="URL"
                            onChange={(value) => {
                              dispatch({
                                type: ConnectorActionType.CONNECTOR_UPDATE_ENTITY,
                                payload: {
                                  id: entity.id,
                                  url_request: value
                                }
                              });
                            }}
                            value={
                              connector.entities[entity.id]
                                ?.url_request as string
                            }
                          />
                        </dd>
                      )}
                    </div>
                    {entity.custom_params && (
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Custom Entity</dt>
                        <dd className="flex items-start gap-x-2">
                          <button
                            onClick={() => {
                              setEditingEntity(entity);
                              setShowParamsModal(true);
                              setCustomParams({});
                            }}
                            className="btnMovinglake w-fit"
                          >
                            Custom Entity
                          </button>
                        </dd>
                      </div>
                    )}
                  </dl>
                </li>
              );
            })}
        </ul>

        <PaginationC
          activePage={active}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={
            entitiesConfigurations.filter((entity) => {
              if (entity.skip_write) return false;
              return true;
            }).length
          }
          pageRangeDisplayed={itemsPerPage}
          onPageChange={(pageNumber: number) => setActivePage(pageNumber)}
        />
      </div>

      <CustomParamsModal
        show={showParamsModal}
        entity={editingEntity}
        activeParams={activeParams}
        itemsPerPage={itemsPerPage}
        customParams={customParams}
        setActiveParamsPage={setActiveParamsPage}
        onCustomChange={(value: any, name: string) => {
          onCustomChange(value, name);
        }}
        onHandleSubmit={(values: any, actions: any) => {
          onCustomParamsChange(values, actions);
        }}
        onCancel={() => {
          setShowParamsModal(false);
          setEditingEntity({} as EntitySpec);
          setActiveParamsPage(1);
          setCustomParams({});
        }}
      />

      <ConfirmationModal
        show={showMainEntityConfirmation}
        title="Are you sure you want to deactivate this main entity?"
        description={`If you deactivate this entity the next entities will also be deactivated.`}
        elements={
          tree(entitiesConfigurations)[mainEntity.name] &&
          tree(entitiesConfigurations)[mainEntity.name].map(
            (entitySon: string) =>
              displayEntityName(
                entitySon,
                connectorRef.current.template?.connector.code || ''
              )
          )
        }
        onConfirm={() => {
          deactivateEntity(mainEntity, mainValue);
        }}
        onCancel={() => {
          setShowMainEntityConfirmation(false);
        }}
      />

      <ConfirmationModal
        show={showActivateMainEntityConfirmation}
        title={`The main entity ${displayEntityName(
          mainEntity.name,
          connectorRef.current.template?.connector.code || ''
        )} is disabled`}
        description={`To activate their children you must activate the main entity first.`}
        btnLabel="Activate main entity"
        onConfirm={() => {
          deactivateEntity(mainEntity, mainValue);
        }}
        onCancel={() => {
          setMainEntity({} as EntitySpec);
          setShowActivateMainEntityConfirmation(false);
        }}
      />

      <ConfirmationConnector
        show={showConfirmationCreation}
        setShow={() => setShowConfirmationCreation(false)}
        onOk={handleBackfillUpdate}
        onCreate={handleCreate}
        backfillDate={backfillDate}
        loading={loading}
      />

      <InfoModal
        show={true}
        title="About main entities"
        description="These entities are the ones that extract the most important information from the source. You can identify them because they have a blue background."
      />
    </>
  );
};

export default EntitiesForm;
