import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../types/state';
import {
  BillingDetailsResponse,
  BillingInvoice,
  BillingSubscriptionGetResponse
} from '../types/api';
import { Mixpanel } from '../components/common/Mixpanel';
import {
  getBillingDetails,
  getBillingHistory,
  getBillingSubscription,
  notification
} from '../services/services';
import React from 'react';

interface ProviderProps {
  billingDetails: BillingDetailsResponse | null;
  billingSubscription: BillingSubscriptionGetResponse | null;
  billingHistory: BillingInvoice[];
  clearBillingDetails?: () => void;
}

export const BillingContext = createContext<ProviderProps>({
  billingDetails: null,
  billingSubscription: null,
  billingHistory: []
});

export const useBilling = () => {
  const context = useContext(BillingContext);
  if (!context) {
    throw new Error(
      'useBilling debe estar dentro del proveedor BillingContext'
    );
  }
  return context;
};

export const BillingProvider = ({ children }: any) => {
  const account = useSelector((state: State) => state.app?.account);
  const billingEnabled = account?.organization.billing_enabled;
  const [billingDetails, setBillingDetails] =
    useState<BillingDetailsResponse | null>(null);
  const [billingSubscription, setBillingSubscription] =
    useState<BillingSubscriptionGetResponse | null>(null);
  const [billingHistory, setBillingHistory] = useState<BillingInvoice[]>([]);

  useEffect(() => {
    if (!billingEnabled) {
      return;
    }
    if (billingDetails !== null) {
      return;
    }

    (async () => {
      try {
        const response = await getBillingDetails();
        if (response.message) {
          notification(response.message, 'warning');
        } else {
          setBillingDetails(response);
        }
      } catch (e) {
        notification('Error in BD', 'danger');
      }
    })();
  }, [billingDetails, billingEnabled]);

  useEffect(() => {
    if (!billingEnabled) {
      return;
    }
    (async () => {
      try {
        const response = await getBillingHistory();
        if (response.message) {
          notification(response.message, 'warning');
        } else {
          setBillingHistory(response.data);
        }
      } catch (e) {
        notification('Error in BH', 'danger');
      }
    })();
  }, [billingEnabled]);

  useEffect(() => {
    if (!billingEnabled) {
      return;
    }
    if (billingSubscription !== null) {
      return;
    }

    (async () => {
      try {
        const response = await getBillingSubscription();
        if (response.message) {
          notification(response.message, 'warning');
        } else {
          setBillingSubscription(response);
          Mixpanel.track('Dashboard - Billing Subscription', {
            is_page_load_event: true,
            status: response.status
          });
        }
      } catch (e) {
        notification('Error getting billing subscription', 'danger');
      }
    })();
  }, [billingSubscription, billingEnabled]);

  const clearBillingDetails = () => {
    setBillingDetails(null);
    setBillingHistory([]);
    setBillingSubscription(null);
  };

  return (
    <BillingContext.Provider
      value={{
        billingDetails,
        billingSubscription,
        billingHistory,
        clearBillingDetails
      }}
    >
      {children}
    </BillingContext.Provider>
  );
};
