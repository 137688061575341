import { Fragment, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  ArrowLeftOnRectangleIcon,
  PencilSquareIcon
} from '@heroicons/react/24/outline';
import { HomeIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { cleanupSessionAndAccount } from '../services/account';
import { AppActionType } from '../reducers/app';
import Path from '../Path';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ChangePassword from './authentication/ChangePassword';
import { State } from '../types/state';
import { useBilling } from '../contexts/BillingContext';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function daysBetween(timestamp: number) {
  const timeStamp = new Date(timestamp * 1000).getTime();
  const currentDate = new Date().getTime();
  return Math.floor((timeStamp - currentDate) / (1000 * 60 * 60 * 24)) + 1;
}

type NavBarProps = {
  show: boolean;
};

export default function Nabvar({ show }: NavBarProps) {
  const [modalShow, setModalShow] = useState(false);
  const account = useSelector((state: State) => state.app?.account);
  const { billingSubscription, clearBillingDetails } = useBilling();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    setTimeout(() => {
      cleanupSessionAndAccount();
      dispatch({
        type: AppActionType.LOGOUT,
        payload: null
      });
      navigate(Path.Login);
      try {
        if (clearBillingDetails && typeof clearBillingDetails === 'function') {
          clearBillingDetails();
        }
      } catch {
        console.log('Error cleaning billing details');
      }
    }, 600);
  };

  return (
    <>
      <Disclosure as="nav" className="bg-white shadow-md border-b-gray-400">
        {({ open }) => (
          <>
            <div className={`${show ? '' : 'hidden'} lg:block mx-auto px-4`}>
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden"></div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"></div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <Menu as="div" className="flex items-center gap-x-5 ml-3">
                    <div
                      id="navbarOrg"
                      className={
                        'text-indigo-200 hover:text-white hover:bg-sky-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                      }
                    >
                      <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-indigo-400 bg-sky-500 text-[0.625rem] font-medium text-white">
                        O
                      </span>
                      <span className="truncate text-gray-700">
                        {account?.organization.name}
                      </span>
                    </div>
                    {show && (
                      <div>
                        <HomeIcon
                          className="h-6 w-6 text-gray-700 cursor-pointer"
                          aria-hidden="true"
                          onClick={() => navigate('/connectors')}
                        />
                      </div>
                    )}
                    <div>
                      <Menu.Button className="relative flex rounded-full bg-transparent text-sm focus:outline-none ">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Menu</span>
                        <UserCircleIcon
                          className="h-6 w-6 text-gray-700"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute top-10 right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'flex gap-x-3 px-3 py-2 text-sm text-gray-800'
                              )}
                              onClick={() => setModalShow(true)}
                            >
                              <PencilSquareIcon className="h-6 w-6" /> Change
                              Password
                            </span>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'flex gap-x-3 px-3 py-2 text-sm text-gray-800'
                              )}
                              onClick={handleLogout}
                            >
                              <ArrowLeftOnRectangleIcon className="h-6 w-6" />{' '}
                              Log out
                            </span>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
      {show &&
        billingSubscription !== null &&
        billingSubscription.status === 'trialing' && (
          <div className="flex items-center bg-[#223c7d] px-6 py-1">
            <div className="text-sm leading-6 text-white mx-auto">
              <strong className="mr-2">
                Your free trial ends in{' '}
                {daysBetween(billingSubscription?.current_period_end || 0)}{' '}
                days.
              </strong>
              <Link to="/billing" className="text-white hover:text-white">
                Add a payment method to avoid service interruption.
              </Link>
            </div>
          </div>
        )}
      {show &&
        billingSubscription !== null &&
        billingSubscription.status === 'canceled' && (
          <div className="flex items-center bg-[#223c7d] px-6 py-1">
            <div className="text-sm leading-6 text-white mx-auto">
              <strong className="mr-2">
                Your subscription has been canceled.
              </strong>
              <Link to="/billing" className="text-white hover:text-white">
                Get a new subscription to avoid service interruption.
              </Link>
            </div>
          </div>
        )}
      <ChangePassword show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
