import React, { useEffect } from 'react';
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

type ConnectorInfoModalProps = {
  show: boolean;
  item: any;
  onHandleSubmit: (values: any) => void;
  onCancel: () => void;
};

export default function ConnectorInfoModal({
  show,
  item,
  onHandleSubmit,
  onCancel
}: ConnectorInfoModalProps) {
  const [open, setOpen] = useState(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const cancelButtonRef = useRef(null);

  const onCloseModal = () => {
    setOpen(false);
    onCancel();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This data is required')
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => onCloseModal()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-0 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <Formik
                  initialValues={
                    item
                      ? { name: item.name, description: '' }
                      : { name: '', description: '', id: 0 }
                  }
                  validationSchema={validationSchema}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={onHandleSubmit}
                >
                  {({ errors }) => (
                    <Form>
                      <div>
                        <div className="mx-auto flex items-center justify-center rounded-full">
                          <img
                            src={`${process.env.REACT_APP_API_URL}${
                              item && item.icon
                            }`}
                            alt=""
                            className="p-0 h-16"
                          />
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 ml-3"
                          >
                            {item && item.name}
                          </Dialog.Title>
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                          <div className="mt-2">
                            <div>
                              <div className="relative mt-2 rounded-md shadow-sm">
                                <div className="relative mt-2">
                                  <Field
                                    type="text"
                                    name="name"
                                    className={`peer block w-full border-0 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 ${
                                      errors.name && 'placeholder:text-red-500'
                                    }`}
                                    placeholder="Choose a name"
                                    aria-invalid="true"
                                    aria-describedby="email-error"
                                  />
                                  {errors.name && (
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                      <ExclamationCircleIcon
                                        className="h-5 w-5 text-red-500"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  )}
                                  <div
                                    className={`absolute inset-x-0 bottom-0 border-t border-gray-400 peer-focus:border-t-2 peer-focus:border-b-gray-900 ${
                                      errors.name && 'border-red-500'
                                    }`}
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                              {errors.name && (
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  id="email-error"
                                >
                                  Name is required
                                </p>
                              )}

                              <div className="relative mt-2">
                                <Field
                                  name="description"
                                  placeholder="Enter a description"
                                  className="mt-3 block w-full rounded-md border-0 py-1.5 shadow-sm sm:text-sm sm:leading-6"
                                />
                                <div
                                  className={`absolute inset-x-0 bottom-0 border-t border-gray-400`}
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 gap-y-3">
                        <button type="submit" className="modalBtnMovinglake">
                          Next
                        </button>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                          onClick={() => onCloseModal()}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
