import React, { useState, useEffect } from 'react';
import { getSchema, getDataPreview } from '../../services/services';
import {
  Table,
  DestinationListed,
  SqlResponseGraphData
} from '../../types/api';
import { Mixpanel } from '../common/Mixpanel';

const dataTypeToIcon = new Map<string, string>([
  ['int', '🔢'],
  ['integer', '🔢'],
  ['string', '🔡'],
  ['date', '📅'],
  ['datetime', '📅'],
  ['timestamp', '📅'],
  ['float', '🔢'],
  ['double', '🔢'],
  ['boolean', '✅'],
  ['long', '🔢'],
  ['short', '🔢'],
  ['byte', '✅'],
  ['binary', '✅'],
  ['array', '[]'],
  ['map', '{}'],
  ['struct', '{}'],
  ['uniontype', '{}'],
  ['decimal', '🔢'],
  ['varchar', '🔡'],
  ['char', '🔡']
]);

interface DatabaseBrowserProps {
  destination: DestinationListed | undefined;
}

const DatabaseBrowser: React.FC<DatabaseBrowserProps> = ({ destination }) => {
  const [tables, setTables] = useState<Table[]>([]);
  const [selectedTable, setSelectedTable] = useState<Table | null>(null);
  const [queryResults, setQueryResults] = useState<
    SqlResponseGraphData | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (destination) {
      getSchema(destination).then(
        (data) => {
          data.tables.sort((a, b) => a.name.localeCompare(b.name));
          data.tables.forEach((table) =>
            table.columns.sort((a, b) => a.name.localeCompare(b.name))
          );
          setTables(data.tables);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
          Mixpanel.track('DataBrowser - Fetch Schema failed', {
            is_success: true,
            destination: destination.name
          });
        }
      );
      setLoading(true);
      Mixpanel.track('DataBrowser - Fetch Schema', {
        is_click: true,
        destination: destination.name
      });
    }
  }, [destination]);

  const handleTableClick = (table: Table, idx: number) => {
    setSelectedTable(table);
    tables.forEach((table) => (table.active = false));
    tables[idx].active = true;
    setTables([...tables]);

    // Issue query to get data preview.
    if (destination && table) {
      getDataPreview(destination, table.name).then(
        (data) => {
          setQueryResults(data.results);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
          Mixpanel.track('DataBrowser - Fetch Table Preview failed', {
            is_success: true,
            destination: destination.name
          });
        }
      );
      setLoading(true);
      Mixpanel.track('DataBrowser - Fetch Table Preview', {
        is_click: true,
        destination: destination.name,
        table: table.name
      });
    }
  };

  return (
    <div>
      <div style={{ border: 'solid' }}>
        {/* Table List */}
        <div style={{ maxHeight: '300px', overflowY: 'scroll', padding: 0 }}>
          <span style={{ fontSize: '1.5em', margin: '10px' }}>TABLES</span>
          <div style={{ marginTop: '10px' }}>
            {tables &&
              tables.map((table, idx) => (
                <div
                  //action
                  //active={table.active}
                  onClick={() => handleTableClick(table, idx)}
                  key={idx}
                  style={{ overflow: 'auto' }}
                >
                  {table.name}
                </div>
              ))}
          </div>
        </div>

        {/* Column List */}
        <div style={{ maxHeight: '300px', overflowY: 'scroll', padding: 0 }}>
          <span style={{ fontSize: '1.5em', margin: '10px' }}>COLUMNS</span>
          {selectedTable && (
            <div style={{ marginTop: '10px' }}>
              {selectedTable.columns.map((column, idx) => (
                <div key={idx} style={{ overflow: 'auto' }}>
                  <span title={column.type}>
                    {dataTypeToIcon.get(column.type.toLowerCase())}
                  </span>{' '}
                  {column.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="my-4">
        <div>
          {loading && (
            <div className="my-4">
              {/*
              <Spinner
                className="mr-2"
                animation="border"
                role="status"
              ></Spinner>
              */}
              <span className="sr-only">
                Loading... this can take upto 30 seconds
              </span>
            </div>
          )}
          <h3 className="my-4">DATA PREVIEW</h3>

          <div style={{ overflow: 'scroll' }}>
            {selectedTable && queryResults && (
              <table className="table table-striped">
                <thead
                  style={{ position: 'sticky', top: 0, background: 'white' }}
                >
                  <tr>
                    {selectedTable.columns.map((column, idx) => (
                      <th key={idx}>{column.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {queryResults.rows.map((row, idx) => (
                    <tr key={idx}>
                      {row.map((value, idx) => (
                        <td key={idx}>{value}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatabaseBrowser;
