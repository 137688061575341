import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Path from './Path';
import ForgetPassword from './components/authentication/ForgetPassword';
import LogIn from './components/authentication/LogIn';
import SignUp from './components/authentication/SignUp';
import PasswordReset from './components/authentication/PasswordReset';
import PrivateRoute from './components/common/PrivateRoute';
import DataSourceForm from './components/connector/DataSourceForm';
import DestinationTemplates from './components/destination/DestinationTemplates';
import ConnectorDestinationsForm from './components/connector/ConnectorDestinationsForm';
import SourceTypeSelector from './components/connector/SourceTypeSelector';
import ConnectorList from './components/connector/ConnectorList';
import Settings from './components/pages/Settings';
import DataOverview from './components/data-visualization/DataOverview';
import Queries from './components/data-visualization/Queries';
import Dashboards from './components/data-visualization/Dashboards';
import Stories from './components/data-visualization/Stories';
import Billing from './components/pages/Billing';
import Apikey from './components/pages/Apikey';
import ConnectorDetails from './components/connector/ConnectorDetails';
import DestinationForm from './components/destination/DestinationForm';
import Root from './components/pages/Root';
import DestinationList from './components/destination/DestinationList';
import AcceptInvite from './components/authentication/AcceptInvite';
import EntitiesForm from './components/connector/EntitiesForm';
import AmazonSPLogin from './components/partners/AmazonSPLogin';
import GithubLogin from './components/partners/GithubLogin';
import DbtDetails from './components/destination/DbtDetails';
import UsersOrganization from './components/pages/UsersOrganization';
import Test from './testing/Test';
import { BillingProvider } from './contexts/BillingContext';

type RoutersProps = {
  auth: boolean;
};

const Routers = ({ auth }: RoutersProps) => {
  return (
    <Router>
      <BillingProvider>
        <Routes>
          {/* Authentication routes */}
          <Route path={Path.Login} element={<LogIn />} />
          <Route path={Path.SignUp} element={<SignUp />} />
          <Route path={Path.ForgotPassword} element={<ForgetPassword />} />
          <Route path={Path.ResetPassword} element={<PasswordReset />} />
          <Route path={Path.AcceptInvite} element={<AcceptInvite />} />

          {/* Partner routes */}
          <Route path={Path.AmazonSPLogin} element={<AmazonSPLogin />} />
          <Route path={Path.GithubLogin} element={<GithubLogin />} />

          {/* testing */}
          <Route
            path={Path.Test}
            element={
              <PrivateRoute Component={<Test />} auth={auth} show={''} />
            }
          />

          {/* Connector form routes */}
          <Route
            path={Path.ChooseDataSource}
            element={
              <PrivateRoute
                Component={<SourceTypeSelector />}
                auth={auth}
                show={''}
              />
            }
          />
          <Route
            path={Path.NewDataSourceForm}
            element={
              <PrivateRoute
                Component={<DataSourceForm />}
                auth={auth}
                show={''}
              />
            }
          />
          <Route
            path={Path.EditDataSourceForm}
            element={
              <PrivateRoute
                Component={<DataSourceForm />}
                auth={auth}
                show={''}
              />
            }
          />
          <Route
            path={Path.NewConnectorEntitiesForm}
            element={
              <PrivateRoute
                Component={<EntitiesForm />}
                auth={auth}
                show={''}
              />
            }
          />

          {/* Destination form routes */}
          <Route
            path={Path.ChooseDestination}
            element={
              <PrivateRoute
                Component={<DestinationTemplates />}
                auth={auth}
                show={''}
              />
            }
          />
          <Route
            path={Path.NewDestinationForm}
            element={
              <PrivateRoute
                Component={<DestinationForm />}
                auth={auth}
                show={''}
              />
            }
          />
          <Route
            path={Path.EditDestinationForm}
            element={
              <PrivateRoute
                Component={<DestinationForm />}
                auth={auth}
                show={''}
              />
            }
          />

          {/* Dashboard routes */}
          <Route
            path={Path.Root}
            element={
              <PrivateRoute
                Component={<Root />}
                auth={auth}
                show={Path.ConnectorList}
              />
            }
          />
          <Route
            path={Path.ConnectorList}
            element={
              <PrivateRoute
                Component={<ConnectorList />}
                auth={auth}
                show={Path.ConnectorList}
              />
            }
          />
          <Route
            path={Path.DestinationList}
            element={
              <PrivateRoute
                Component={<DestinationList />}
                auth={auth}
                show={Path.DestinationList}
              />
            }
          />
          <Route
            path={Path.DbtProjectDetails}
            element={
              <PrivateRoute
                Component={<DbtDetails />}
                auth={auth}
                show={Path.DbtProjectDetails}
              />
            }
          />
          <Route
            path={Path.ShowConnector}
            element={
              <PrivateRoute
                Component={<ConnectorDetails />}
                auth={auth}
                show={Path.ShowConnector}
              />
            }
          />

          <Route
            path={Path.SelectedDestination}
            element={
              <PrivateRoute
                Component={<ConnectorDestinationsForm />}
                auth={auth}
                show={''}
              />
            }
          />
          <Route
            path={Path.DataOverview}
            element={
              <PrivateRoute
                Component={<DataOverview />}
                auth={auth}
                show={Path.DataOverview}
              />
            }
          />
          <Route
            path={Path.Queries}
            element={
              <PrivateRoute
                Component={<Queries />}
                auth={auth}
                show={Path.Queries}
              />
            }
          />
          <Route
            path={Path.Dashboards}
            element={
              <PrivateRoute
                Component={<Dashboards />}
                auth={auth}
                show={Path.Dashboards}
              />
            }
          />
          <Route
            path={Path.Stories}
            element={
              <PrivateRoute
                Component={<Stories />}
                auth={auth}
                show={Path.Stories}
              />
            }
          />
          <Route
            path={Path.Settings}
            element={
              <PrivateRoute
                Component={<Settings />}
                auth={auth}
                show={Path.Settings}
              />
            }
          />
          <Route
            path={Path.Billing}
            element={
              <PrivateRoute
                Component={<Billing />}
                auth={auth}
                show={Path.Billing}
              />
            }
          />
          <Route
            path={Path.Apikey}
            element={
              <PrivateRoute
                Component={<Apikey />}
                auth={auth}
                show={Path.Apikey}
              />
            }
          />

          {/* Users organization module */}
          <Route
            path={Path.UsersOrg}
            element={
              <PrivateRoute
                Component={<UsersOrganization />}
                auth={auth}
                show={Path.UsersOrg}
              />
            }
          />
        </Routes>
      </BillingProvider>
    </Router>
  );
};

export default Routers;
