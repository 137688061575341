import React, { useState, useEffect } from 'react';
import 'react-tabs/style/react-tabs.css';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  ConnectorDetailResponse,
  ConnectorTemplateResponse
} from '../../types/api';
import {
  deleteConnector,
  getConnectorDetails,
  getConnectorTemplate,
  notification,
  partialConnectorUpdate
} from '../../services/services';
import Path from '../../Path';
import UsageHistogram from './UsageHistogram';
import SchedulingConfiguration from './SchedulingConfiguration';
import ConnectorAdministration from './ConnectorAdministration';
import { AppActionType } from '../../reducers/app';
import { useDispatch } from 'react-redux';
import { Mixpanel } from '../common/Mixpanel';
import { ImCloudDownload, ImPencil, ImSwitch } from 'react-icons/im';
import { AiFillClockCircle, AiFillDelete } from 'react-icons/ai';
import ConfirmationModal from './modals/ConfirmationModal';
import ConnectorInfoModal from './modals/ConnectorInfoModal';
import { Popover, Whisper } from 'rsuite';
import Loader from '../common/Loader';
import { FaDatabase, FaSlidersH } from 'react-icons/fa';

type Status = {
  [key: string]: string;
  CREATION_STARTED: string;
  DISABLED: string;
  RUNNING: string;
};

const status: Status = {
  CREATION_STARTED: 'Your connector is preparing for data extraction',
  DISABLED: 'Make sure you have validated your email address',
  RUNNING: 'Your connector is extracting data'
};

const statusColor1: Status = {
  CREATION_STARTED: 'bg-blue-500/20',
  DISABLED: 'bg-yellow-500/20',
  RUNNING: 'bg-emerald-500/20'
};

const statusColor2: Status = {
  CREATION_STARTED: 'bg-blue-500',
  DISABLED: 'bg-yellow-500',
  RUNNING: 'bg-emerald-500'
};

const ConnectorDetails = () => {
  const searchParams = useSearchParams()[0];
  const params = useParams();
  const [searchParamsLoaded, setSearchParamsLoaded] = useState(false);
  const [connector, setConnector] = useState<ConnectorDetailResponse | null>(
    null
  );
  const [connectorConfiguration, setConnectorConfiguration] =
    useState<ConnectorTemplateResponse | null>(null);
  const [connectorLoaded, setConnectorLoaded] = useState(false);
  const [defaultSelected, setDefaultSelected] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showActiveStatusModal, setShowActiveStatusModal] = useState(false);

  useEffect(() => {
    if (searchParamsLoaded) {
      return;
    }

    if (!searchParams.get('tab')) {
      return;
    }

    setSearchParamsLoaded(true);
  }, [searchParams, searchParamsLoaded]);

  useEffect(() => {
    if (connectorLoaded) {
      return;
    }
    const connectorId = parseInt(params['id'] || '');
    (async () => {
      try {
        const response = await getConnectorDetails(connectorId);
        const connectorTemplate = await getConnectorTemplate(
          response.connector_cat
        );
        setConnector(response);
        setConnectorConfiguration(connectorTemplate);
        setConnectorLoaded(true);
        Mixpanel.track('Dashboard - Connector Details', {
          is_page_view: true,
          connector_id: connectorId,
          connector_code: connectorTemplate.connector.code
        });
      } catch (e) {
        notification('Error fetching connector', 'warning');
        navigate(Path.ConnectorList);
        console.error(e);
      }
    })();
  }, [connectorLoaded, connector, navigate, params]);

  const handleDelete = async () => {
    if (!connector) {
      return;
    }

    try {
      await deleteConnector(connector.id);
      dispatch({ type: AppActionType.REMOVE_CONNECTOR, payload: connector.id });
      notification('Connector deleted successfully', 'success');
      navigate(Path.ConnectorList);
      Mixpanel.track('Dashboard - Connector Details Delete', {
        is_click: true,
        connector_id: connector.id
      });
    } catch (e) {
      notification('Error deleting connector', 'warning');
      Mixpanel.track('Dashboard - Connector Details Delete Error', {
        is_click: true,
        connector_id: connector.id
      });
      console.error(e);
    }
  };

  if (!connector || !connectorConfiguration) {
    return null;
  }

  const reloadConnector = async () => {
    try {
      const newConnector = await getConnectorDetails(connector.id);
      setConnector(newConnector);
    } catch (e) {
      notification('Error fetching connector', 'warning');
      console.error(e);
    }
  };

  const tabs = [
    { name: 'Monitoring', icon: FaSlidersH, index: 0 },
    { name: 'Scheduling', icon: AiFillClockCircle, index: 1 },
    { name: 'Destinations', icon: FaDatabase, index: 2 }
  ];

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  const handleNameUpdate = async (values: {
    name: string;
    description: string;
  }) => {
    if (values.name.length === 0) {
      notification('Cannot set an empty name', 'warning');
      return;
    }
    try {
      await partialConnectorUpdate(connector.id, values);
      notification('Connector name updated', 'success');
      await reloadConnector();
    } catch (e) {
      notification('Failed to update connector name', 'warning');
    }
    setShowEditModal(false);
  };

  const handleActiveStatus = async () => {
    if (!connector) {
      return;
    }

    try {
      await partialConnectorUpdate(connector.id, {
        is_active: !connector.is_active
      });
      notification('Connector updated successfully', 'success');
      await reloadConnector();
    } catch (e) {
      notification('Error updating connector', 'warning');
      console.error(e);
    }
    setShowActiveStatusModal(false);
  };

  return (
    <>
      {connectorLoaded ? (
        <>
          <div className="px-4 py-6">
            <div className="md:flex md:items-center md:justify-between">
              <div className="min-w-0 flex self-center">
                <img
                  className="h-16 w-16 flex-none"
                  src={`${process.env.REACT_APP_API_URL}${connectorConfiguration.connector.icon}`}
                  alt=""
                />
                <h1 className="ml-2 self-center text-xl md:text-3xl text-gray-500 font-bold sm:truncate sm:tracking-tight">
                  {connector.name}
                </h1>
                <Whisper
                  followCursor
                  speaker={
                    <Popover>{status[connector.lifecycle_status]}</Popover>
                  }
                >
                  <div className="ml-5 mt-1 flex items-center gap-x-1.5">
                    <div
                      className={`flex-none rounded-full ${
                        statusColor1[connector.lifecycle_status]
                      } p-1`}
                    >
                      <div
                        className={`h-1.5 w-1.5 rounded-full ${
                          statusColor2[connector.lifecycle_status]
                        }`}
                      />
                    </div>
                    <p className="text-xs leading-5 text-gray-500">
                      {connector.lifecycle_status}
                    </p>
                  </div>
                </Whisper>
              </div>
              <div className="mt-2 grid grid-cols-2 gap-1 md:flex md:gap-0 md:mt-0">
                <button
                  type="button"
                  className={`${
                    connector.is_active
                      ? 'bg-black text-white hover:bg-gray-600'
                      : 'bg-submarine-500 text-white hover:bg-sky-800'
                  } inline-flex items-center md:rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10`}
                  onClick={() => setShowActiveStatusModal(true)}
                >
                  <ImSwitch className="h-4 w-4 mr-1" />{' '}
                  {connector.is_active ? 'Disable' : 'Enable'}
                </button>
                <button
                  type="button"
                  className="-ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  onClick={() => setShowEditModal(true)}
                >
                  <ImPencil className="h-4 w-4 mr-1" /> Edit
                </button>
                <button
                  type="button"
                  className="-ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  onClick={() => {
                    navigate(
                      Path.EditDataSourceForm.replace(
                        ':id',
                        connector.id.toString()
                      )
                    );
                  }}
                >
                  <ImCloudDownload className="h-4 w-4 mr-1" /> Credentials
                </button>
                <button
                  type="button"
                  className="-ml-px inline-flex items-center md:rounded-r-md bg-red-800 text-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-red-900 focus:z-10"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <AiFillDelete className="h-4 w-4 mr-1" /> Delete
                </button>
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1">
              <div className="col-span-1">
                <b>ID: </b> <span> {connector.id} </span>
                <b>Description:</b> <span>{connector.description}</span>
              </div>
            </div>
          </div>
          <hr className="h-px my-0 mb-1 bg-gray-200 border-0" />
          <div>
            <div className="sm:hidden">
              <label className="sr-only">Select a tab</label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md"
                defaultValue={
                  tabs.find((tab) => tab.index === defaultSelected)?.index
                }
                onChange={(event) => {
                  setDefaultSelected(parseInt(event.target.value));
                }}
              >
                {tabs.map((tab) => (
                  <option key={tab.name} value={tab.index}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab, index) => (
                    <button
                      key={tab.name}
                      className={classNames(
                        index === defaultSelected
                          ? 'tabMovinglake'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                      )}
                      aria-current={
                        index === defaultSelected ? 'page' : undefined
                      }
                      onClick={() => {
                        setDefaultSelected(index);
                      }}
                    >
                      <tab.icon
                        className={classNames(
                          index === defaultSelected
                            ? 'iconTabMovinglake'
                            : 'text-gray-400 group-hover:text-gray-500',
                          '-ml-0.5 mr-2 h-5 w-5'
                        )}
                        aria-hidden="true"
                      />
                      <span>{tab.name}</span>
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          <div className="container mt-5">
            {defaultSelected === 0 && (
              <>
                <h5 className="mb-3">Events Saved</h5>
                <UsageHistogram connector={connector} />
              </>
            )}

            {defaultSelected === 1 && (
              <>
                <SchedulingConfiguration
                  connector={connector}
                  configuration={connectorConfiguration}
                  reloadConnector={reloadConnector}
                  imgSrc={`${process.env.REACT_APP_API_URL}${connectorConfiguration.connector.icon}`}
                />
              </>
            )}

            {defaultSelected === 2 && (
              <>
                <ConnectorAdministration
                  connector={connector}
                  reloadConnector={reloadConnector}
                />
              </>
            )}
          </div>

          <ConfirmationModal
            show={showActiveStatusModal}
            title={`${connector.is_active ? 'Disable' : 'Enable'} connector`}
            description={`Are you sure you want to ${
              connector.is_active ? 'disable' : 'enable'
            } this connector?`}
            onConfirm={handleActiveStatus}
            onCancel={() => setShowActiveStatusModal(false)}
          />
          <ConfirmationModal
            show={showDeleteModal}
            title="Delete Connector"
            description="Are you sure you want to delete this connector?"
            onConfirm={handleDelete}
            onCancel={() => setShowDeleteModal(false)}
          />
          <ConnectorInfoModal
            show={showEditModal}
            item={connector}
            onHandleSubmit={handleNameUpdate}
            onCancel={() => {
              setShowEditModal(false);
            }}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ConnectorDetails;
