import { AccountSummaryResponse, LoginResponse } from '../types/api';

export const sessionData = (): LoginResponse | null => {
  const session = localStorage.getItem('sessionData');
  if (session == null) {
    return null;
  }
  try {
    return JSON.parse(session);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const setSessionData = (session: LoginResponse): void => {
  localStorage.setItem('sessionData', JSON.stringify(session));
};
export const accountData = (): AccountSummaryResponse | null => {
  const account = localStorage.getItem('accountData');
  if (account === null) {
    return null;
  }

  return JSON.parse(account);
};

export const setAccountData = (account: AccountSummaryResponse): void => {
  localStorage.setItem('accountData', JSON.stringify(account));
};

export const cleanupSessionAndAccount = () => {
  localStorage.removeItem('sessionData');
  localStorage.removeItem('accountData');
};
