import { combineReducers } from 'redux';
import app from './reducers/app';
import destination from './reducers/destination';
import connector from './reducers/connector';

export default combineReducers({
  app,
  destination,
  connector
});
