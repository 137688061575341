import React, { useEffect, useRef } from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { DatePicker } from 'rsuite';
import {
  InformationCircleIcon,
  PlusCircleIcon
} from '@heroicons/react/24/solid';
import { useSelector } from 'react-redux';
import { Loader as Loading } from 'rsuite';
import { State } from '../../types/state';

type Props = {
  show: boolean;
  setShow: () => void;
  onOk: (arg: Date) => void;
  backfillDate: Date | null;
  onCreate: () => void;
  loading: boolean;
};

type Icons = {
  [key: string]: string;
  BigQuery: string;
};

const icons: Icons = {
  BigQuery: 'bigquery.png'
};

export default function ConfirmationConnector({
  show,
  setShow,
  onOk,
  backfillDate,
  onCreate,
  loading
}: Props) {
  const [open, setOpen] = useState(false);

  const connector = useSelector((state: State) => state.connector);
  const destination = useSelector(
    (state: State) => state.connector.destination
  );
  const connectorRef = useRef(connector);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[5]" onClose={setShow}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-[#223c7d] px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-2xl font-semibold leading-6 text-white">
                            Connector Summary
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-[#223c7d] text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={setShow}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between py-4">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="text-base text-gray-900 py-5">
                            <p className="font-bold text-xl">Connector</p>
                            <div className="flex items-center gap-3 mt-3">
                              <img
                                src={`${process.env.REACT_APP_API_URL}${connectorRef.current.template?.connector.icon}`}
                                alt={connector?.template?.connector.name}
                                className="h-16 w-16 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                              />
                              <p className="font-bold">
                                {connector?.template?.connector.name} Source -{' '}
                                {connector.sourceFormValues.name}
                              </p>
                            </div>
                            <div className="rounded-md bg-blue-100 p-4 mt-3">
                              <div className="flex items-center">
                                <InformationCircleIcon
                                  className="h-5 w-5 text-blue-400"
                                  aria-hidden="true"
                                />
                                <div className="ml-3">
                                  <div className="text-sm text-blue-700">
                                    <p className="font-bold">
                                      {' '}
                                      {
                                        Object.entries(connector.entities)
                                          .map(([key, value]) => value)
                                          .filter(
                                            (entity: any) => entity.is_active
                                          ).length
                                      }{' '}
                                      entities selected for extraction.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-base text-gray-900 py-5">
                            <p className="font-bold text-xl">Destination</p>
                            <div className="flex items-center gap-3 mt-3">
                              <img
                                className="h-16 w-16 flex-none rounded-full bg-gray-50"
                                src={`${
                                  process.env.REACT_APP_API_URL
                                }/static/media/icons/adapters/${
                                  icons[destination.type]
                                }`}
                                alt="selected destination"
                              />
                              <p className="font-bold">
                                {destination.type} - {destination.name}
                              </p>
                            </div>
                          </div>
                          <div className="space-y-3 pb-5 pt-6">
                            <p className="font-bold text-xl">
                              Extra Configurations
                            </p>
                            <div>
                              <DatePicker
                                className="w-full"
                                value={backfillDate}
                                onOk={(date) => {
                                  onOk(date);
                                }}
                                placeholder="Backfill Date"
                              />
                              <label
                                htmlFor="project-name"
                                className="block mt-3 text-sm text-justify text-gray-900"
                              >
                                Backfill Date is the date from which the
                                connector will resume fetching data. If you
                                don't set a Backfill Date, the connector will
                                start fetching data from the current date.
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end px-4 py-4">
                      {!loading ? (
                        <button
                          type="button"
                          className="h-fit btnMovinglake w-fit"
                          onClick={() => {
                            onCreate();
                          }}
                        >
                          <PlusCircleIcon className="h-6 w-6 mx-auto" />
                          <span className="hidden lg:block lg:ml-1">
                            Create
                          </span>
                        </button>
                      ) : (
                        <Loading vertical={true} className="mr-5" />
                      )}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
