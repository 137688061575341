import { PlusIcon } from '@heroicons/react/20/solid';
import React from 'react';

type EmptyDataProps = {
  title: string;
  description: string;
  Icon: any;
  onNewClicked: () => void;
};

const EmptyData = ({
  title,
  description,
  Icon,
  onNewClicked
}: EmptyDataProps) => {
  return (
    <div className="text-center">
      <Icon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-500">{description}</p>
      <div className="mt-6">
        <button
          type="button"
          className="btnMovinglake w-fit"
          onClick={onNewClicked}
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Create a new one
        </button>
      </div>
    </div>
  );
};

export default EmptyData;
