import React, { useEffect, useState } from 'react';
import { Mixpanel } from '../common/Mixpanel';
import {
  ClientAPIKeysListResponse,
  ClientAPIKeysCreateResponse
} from '../../types/api';
import {
  getClientAPIKeys,
  createClientAPIKey,
  deleteClientAPIKey,
  notification
} from '../../services/services';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { AppActionType } from '../../reducers/app';
import { SiAurelia } from 'react-icons/si';
import ConfirmationModal from '../connector/modals/ConfirmationModal';
import ApiKeyModal from './modals/ApiKeyModal';

const Apikey = () => {
  Mixpanel.track('Dashboard - API Key', { is_page_view: true });
  const [clientAPIKeys, setClientAPIKeys] = useState<ClientAPIKeysListResponse>(
    []
  );
  const [loaded, setLoaded] = useState(false);
  const [newAPIKey, setNewAPIKey] = useState<ClientAPIKeysCreateResponse>();
  const [apiKeyToDelete, setAPIKeyToDelete] = useState<number>(-1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loaded) {
      return;
    }

    (async () => {
      try {
        const response = await getClientAPIKeys();
        setClientAPIKeys(response);
        setLoaded(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [loaded, clientAPIKeys]);

  const handleCreate = () => async () => {
    try {
      const response = await createClientAPIKey();
      notification('API key created successfully', 'success');
      dispatch({
        type: AppActionType.ADD_APIKEY,
        payload: {
          id: response.id,
          client_id: response.client_id
        }
      });
      setNewAPIKey(response);
      clientAPIKeys.push({ id: response.id, client_id: response.client_id });
      setClientAPIKeys(clientAPIKeys);
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status === 400) {
        notification('API key not deletable', 'warning');
        return;
      } else if (error.response?.status === 401) {
        notification(
          "You don't have permission to delete this api key",
          'warning'
        );
        return;
      } else {
        notification('Something went wrong', 'danger');
        console.error(e);
      }
    }
  };

  const handleDelete = (id: number) => async () => {
    if (id < 0) return;
    try {
      await deleteClientAPIKey(id);
      notification('API key deleted successfully', 'success');
      dispatch({
        type: AppActionType.REMOVE_APIKEY,
        payload: id
      });
      setClientAPIKeys(clientAPIKeys.filter((item) => item.id !== id));
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status === 400) {
        notification('API key not deletable', 'warning');
      } else if (error.response?.status === 401) {
        notification(
          "You don't have permission to delete this api key",
          'warning'
        );
      } else if (error.response?.status === 404) {
        notification('API key no longer present', 'warning');
      } else {
        notification('Something went wrong', 'danger');
        console.error(e);
      }
    }
    setAPIKeyToDelete(-1);
    return;
  };

  return (
    <>
      <div>
        <div className="px-4 sm:px-0">
          <h3 className="flex gap-3 text-base font-semibold leading-7 text-gray-900">
            <img src="./images/apioauth.png" alt="img" />{' '}
            <span className="self-center">API OAuth Token</span>
          </h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            Generate your personal API tokens.
          </p>
          <button className="btnMovinglake mt-5" onClick={handleCreate()}>
            Generate Token
          </button>
        </div>
        <div className="mt-6 border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                My Tokens
              </dt>
              <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                  {clientAPIKeys.map((item, key) => (
                    <li
                      key={key}
                      className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
                    >
                      <div className="flex w-0 flex-1 items-center">
                        <SiAurelia
                          className="h-5 w-5 flex-shrink-0"
                          aria-hidden="true"
                        />
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="truncate font-medium">
                            {item.client_id}
                          </span>
                        </div>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <button
                          className="font-medium text-red-600 hover:text-red-500"
                          onClick={() => setAPIKeyToDelete(item.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </li>
                  ))}
                  {clientAPIKeys.length === 0 && (
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        <SiAurelia
                          className="h-5 w-5 flex-shrink-0"
                          aria-hidden="true"
                        />
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <span className="truncate font-medium">
                            You dont't have tokens
                          </span>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="right-bg">
        <ConfirmationModal
          show={apiKeyToDelete >= 0}
          title="Are you sure you want to proceed?"
          description={`Deleting your API key may affect your production environment.`}
          onConfirm={handleDelete(apiKeyToDelete)}
          onCancel={() => setAPIKeyToDelete(-1)}
        />

        <ApiKeyModal
          show={newAPIKey !== undefined}
          newAPIKey={newAPIKey}
          onClose={() => setNewAPIKey(undefined)}
        />
      </div>
    </>
  );
};

export default Apikey;
