import React from 'react';
import { Field } from 'formik';
import { FieldSpec } from '../../types/api';

type DynamicFormFieldProps = {
  fieldName: string;
  fieldSpec: FieldSpec;
  sourceFormValues: Record<string, any>;
  updateValue: (key: string, value: any) => void;
};

export const DynamicFormField = (props: DynamicFormFieldProps) => {
  const fieldSpec = props.fieldSpec;

  if (fieldSpec.type === 'CHECKBOX') {
    return <Checkbox {...props} />;
  } else if (fieldSpec.type === 'INPUT' || fieldSpec.type === 'TEXTAREA') {
    return <TextField {...props} />;
  } else if (fieldSpec.type === 'SELECT') {
    return <SelectField {...props} />;
  }

  return <></>;
};

const Checkbox = ({
  fieldName,
  fieldSpec,
  sourceFormValues,
  updateValue
}: DynamicFormFieldProps) => {
  const checked = !!getFieldValue(fieldName, sourceFormValues);

  if (shouldHide(fieldSpec, sourceFormValues)) {
    return null;
  }

  const onChange = () => {
    const value = !checked;
    updateValue(fieldName, value);
  };

  return (
    <div className="sm:col-span-full">
      <div className="relative flex items-start">
        <div className="flex h-6 items-center">
          <input
            aria-describedby="comments-description"
            name={fieldName}
            checked={checked}
            onChange={onChange}
            required={fieldSpec.required}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600"
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label className="font-medium text-gray-900">
            <span dangerouslySetInnerHTML={{ __html: fieldSpec.label }} />{' '}
            {fieldSpec.required ? '*' : ''}:
          </label>
        </div>
      </div>
    </div>
  );
};

const TextField = ({
  fieldName,
  fieldSpec,
  sourceFormValues,
  updateValue
}: DynamicFormFieldProps) => {
  if (shouldHide(fieldSpec, sourceFormValues)) {
    return null;
  }

  const onChange = (e: any) => {
    updateValue(fieldName, e.target.value);
  };

  const value = getFieldValue(fieldName, sourceFormValues);

  return (
    <div className="sm:col-span-4">
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        <span>{fieldSpec.label} </span> {fieldSpec.required ? '*' : ''}
      </label>
      <div className="relative">
        <Field
          type={fieldSpec.inputType}
          name={fieldName}
          id={fieldName}
          onChange={onChange}
          value={value}
          placeholder={fieldSpec.placeholder}
          required={fieldSpec.required}
          aria-invalid="true"
          className="peer block w-full border-0 py-1.5 text-gray-900 focus:border-0 focus:ring-0 sm:text-sm sm:leading-6"
        />
        <div
          className="absolute inset-x-0 bottom-0 border-t border-gray-400 peer-focus:border-t-2 peer-focus:border-b-gray-900"
          aria-hidden="true"
        />
      </div>
    </div>
  );
};

const SelectField = ({
  fieldName,
  fieldSpec,
  sourceFormValues,
  updateValue
}: DynamicFormFieldProps) => {
  if (shouldHide(fieldSpec, sourceFormValues)) {
    return null;
  }

  const onChange = (e: any) => {
    updateValue(fieldName, e.target.value);
  };

  const value = getFieldValue(fieldName, sourceFormValues);

  return (
    <div className="sm:col-span-4">
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        <span>{fieldSpec.label} </span> {fieldSpec.required ? '*' : ''}:
      </label>
      <div className="relative">
        <Field
          as="select"
          name={fieldName}
          id={fieldName}
          value={value}
          onChange={onChange}
          required={fieldSpec.required}
          className="mt-0 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 sm:text-sm sm:leading-6"
        >
          {fieldSpec?.options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Field>
        <div
          className="absolute inset-x-0 bottom-0 border-t border-gray-400 peer-focus:border-t-2 peer-focus:border-b-gray-900"
          aria-hidden="true"
        />
      </div>
    </div>
  );
};

const getFieldValue = (key: string, credentials: Record<string, any>) => {
  const v = credentials[key];
  // Update value if it's not an Array
  if (v) {
    return v;
  }
  // Update value if it's an Array
  // 'key' is in the form of '<field>.<index>.<subfield>'
  const path = key.split('.');
  if (path.length === 3) {
    const [field, index, subfield] = path;
    const array: Record<string, any>[] = credentials[field];
    const i = parseInt(index);
    if (array && !isNaN(i)) {
      return array[i] ? array[i][subfield] : '';
    }
  }
};

const shouldHide = (
  fieldSpec: FieldSpec,
  values: Record<string, any>
): boolean => {
  if (!fieldSpec.relations || values === undefined) {
    return false;
  }

  if (fieldSpec.relations.length > 1) {
    console.warn('More than one relation is not supported yet');
    return false;
  }
  if (fieldSpec.relations[0].match !== 'HIDDEN') {
    console.warn('Only HIDDEN relation is supported');
    return false;
  }

  const relation = fieldSpec.relations[0];
  // Conditions inside a when block are handled as OR conditions
  const condResult = relation.when.reduce((result, condition) => {
    const target = values[condition.id];
    const cond = target ? condition.value === target : true;
    return result || cond;
  }, false);
  console.log('Condition res', condResult);

  return condResult;
};
