import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon
} from '@heroicons/react/24/solid';
import React from 'react';

type paginationProps = {
  activePage: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
  pageRangeDisplayed: number;
  onPageChange: (page: number) => void;
};

const PaginationC = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  pageRangeDisplayed,
  onPageChange
}: paginationProps) => {
  const pagesCount = Math.ceil(totalItemsCount / itemsCountPerPage);

  return (
    <nav className="mt-10 flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <span className="border-t-2 border-transparent pt-4 pr-1 inline-flex">
          <button
            type="button"
            className="btnMovinglake w-fit"
            onClick={() => onPageChange(activePage === 1 ? 1 : activePage - 1)}
          >
            <ArrowLongLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </button>
        </span>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pagesCount > 0 &&
          [...Array(pagesCount)].map((x, i) => (
            <button
              key={i}
              type="button"
              className={`rounded-xl ${
                i + 1 === activePage
                  ? 'border-submarine-500 text-submarine-600 inline-flex items-center px-4 py-3 border text-sm font-medium'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-4 py-3 border text-sm font-medium'
              }`}
              onClick={() => onPageChange(i + 1)}
            >
              {i + 1}
            </button>
          ))}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <span className="border-t-2 border-transparent pt-4 pr-1 inline-flex">
          <button
            className="btnMovinglake w-fit"
            onClick={() =>
              onPageChange(
                activePage + 1 <= pagesCount ? activePage + 1 : pagesCount
              )
            }
          >
            Next
            <ArrowLongRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </span>
      </div>
    </nav>
  );
};

export default PaginationC;
