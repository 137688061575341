import React from 'react';
import ReactDOM from 'react-dom/client';
import './test.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import 'animate.css';

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('No root element found');
}

const root = ReactDOM.createRoot(rootElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
reportWebVitals(undefined);
