import React, { useState, useEffect } from 'react';
import {
  getDestinations,
  issueAiQuery,
  issueSqlQuery,
  notification
} from '../../services/services';
import {
  AiQueryResponse,
  SqlResponseGraphData,
  SqlResponse,
  DestinationListed
} from '../../types/api';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Mixpanel } from '../common/Mixpanel';
import Chart from './ChartType';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const
    },
    title: {
      display: true,
      text: 'SQL Query Results Graph'
    }
  }
};

const colors = [
  '#8884d8',
  '#82ca9d',
  '#ffc658',
  '#ff7300',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#0088FE'
];

const Queries = () => {
  const [dataSources, setDataSources] = useState<DestinationListed[]>([]);
  const [selectedDataSource, setSelectedDataSource] = useState<number | null>(
    null
  );
  const [question, setQuestion] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [sqlQuery, setSqlQuery] = useState<string>('');
  const [chartData, setChartData] = useState<any[] | undefined>(undefined);
  const [tableData, setTableData] = useState<SqlResponseGraphData | null>(null);
  const [chartType, setChartType] = useState<string>('line');

  Mixpanel.track('Dashboard - AI Insights', { is_page_view: true });

  useEffect(() => {
    fetchDataSources();
  }, []);

  const fetchDataSources = async () => {
    const destinations = await getDestinations();
    const sqlDestinations = destinations.filter(
      (destination) => destination.type_adapter_name !== 'Google Sheets'
    );

    setDataSources(sqlDestinations);
    return getDestinations();
  };

  const handleQuestion = async () => {
    if (!selectedDataSource) {
      notification('Please choose a destination', 'warning');
      return;
    }
    if (!question) {
      notification('Please write a question', 'warning');
      return;
    }
    // Get the desintation from the selectedDataSource.
    const destination = dataSources.find(
      (dataSource) => dataSource.id === selectedDataSource
    );
    if (!destination) {
      notification('Please choose a destination', 'warning');
      return;
    }
    Mixpanel.track('Dashboard - AI Insights Question Submit', {
      is_click: true,
      destination: destination.name
    });
    setLoading(true);
    let response: AiQueryResponse;
    try {
      response = await issueAiQuery(destination, question);
    } catch (error: any) {
      if (error.response.data && error.response.data.reason) {
        Mixpanel.track('Dashboard - AI Insights Question Error', {
          is_error: true,
          destination: destination.name
        });
        setSqlQuery(error.response.data.query || '');
      }
      Mixpanel.track('Dashboard - AI Insights Question Error', {
        is_error: true,
        destination: destination.name
      });
      setLoading(false);
      notification('There was an error while querying the database', 'danger');
      return;
    }
    setLoading(false);
    notification('Success!', 'success');
    Mixpanel.track('Dashboard - AI Insights Question Submit Successful', {
      is_success: true,
      destination: destination.name
    });
    if (response && response.results) {
      const data = response.results.rows
        .map((row: any, idx: number) => {
          return {
            name: row[0],
            value: row[1],
            fill: colors[idx % colors.length]
          };
        })
        .flat(1);
      setChartType(response.chart_type || 'line');
      setSqlQuery(response.ai_query || '');
      setChartData(data);
      setTableData(response.results);
    }
  };

  const handleQuery = async () => {
    if (!selectedDataSource) {
      notification('Please choose a destination', 'warning');
      return;
    }
    if (!sqlQuery) {
      notification('Please write a SQL query', 'warning');
      return;
    }
    // Get the desintation from the selectedDataSource.
    const destination = dataSources.find(
      (dataSource) => dataSource.id === selectedDataSource
    );
    if (!destination) {
      notification('Please choose a destination', 'warning');
      return;
    }
    Mixpanel.track('Dashboard - AI Insights Question Submit', {
      is_click: true,
      destination: destination.name
    });
    setLoading(true);
    let response: SqlResponse;
    try {
      response = await issueSqlQuery(destination, sqlQuery);
    } catch (error: any) {
      notification(
        'SQL query failed ' + JSON.stringify(error.response.data),
        'danger'
      );
      setLoading(false);
      return;
    }
    setLoading(false);
    notification('Success!', 'success');
    Mixpanel.track('Dashboard - AI Insights Query Submit Successful', {
      is_success: true,
      destination: destination.name
    });
    if (response && response.results) {
      const data = response.results.rows
        .map((row: any, idx: number) => {
          return {
            name: row[0],
            value: row[1],
            fill: colors[idx % colors.length]
          };
        })
        .flat(1);
      setChartData(data);
      setTableData(response.results);
    }
  };

  return (
    <>
      <div className="container-setting">
        <h4 className="payment-details-1 mb-5">
          GET YOUR DATA QUESTIONS SOLVED BY OUR AI MODEL
        </h4>
        <div className="row">
          <div className="col-md-6">
            <h4 className="mt-0 mb-3">Choose the datasource to query</h4>
            <div className="my-4">
              {dataSources.map((dataSource) => (
                <div
                  key={dataSource.id}
                  //action
                  //active={selectedDataSource === dataSource.id}
                  onClick={() => setSelectedDataSource(dataSource.id)}
                >
                  <img
                    height="30px"
                    src={`${process.env.REACT_APP_API_URL}/static/media/${dataSource.icon}`}
                    alt={dataSource.name}
                    className="mx-2"
                  />
                  <span className="mx-2"> {dataSource.name}</span>
                </div>
              ))}
            </div>
            <div>
              <div>
                <div>Write your question</div>
                <div>
                  <textarea
                    rows={3}
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                </div>
                <button onClick={handleQuestion} className="my-4">
                  Submit question
                </button>
              </div>
            </div>
            {loading && (
              <div className="my-4">
                {/*
                <Spinner
                  className="mr-2"
                  animation="border"
                  role="status"
                ></Spinner>
                */}
                <span className="sr-only">
                  Loading... this can take upto 30 seconds
                </span>
              </div>
            )}
            <div className="my-5">
              <div>
                <div>SQL Query Used</div>
                <div>
                  <textarea
                    rows={3}
                    value={sqlQuery}
                    onChange={(e) => {
                      setSqlQuery(e.target.value);
                    }}
                  />
                </div>
                <button
                  onClick={handleQuery}
                  className="my-4"
                  disabled={!sqlQuery}
                >
                  Resubmit SQL Query
                </button>
              </div>
            </div>
          </div>
        </div>
        {tableData && (
          <div>
            <div>
              <h4 className="mt-0 mb-3">Results</h4>

              <Chart
                data={tableData}
                chartType={chartType}
                xAxisIndex={0}
                setChartType={(ct) => {}}
                setXAxis={(e) => {}}
              />
            </div>
          </div>
        )}
        {tableData && (
          <div>
            <div>
              {
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {tableData.columns.map((column) => (
                        <th key={column}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.rows.map((row) => (
                      <tr>
                        {row.map((column) => (
                          <td>
                            {column}
                            {chartData}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
            </div>
          </div>
        )}
      </div>

      <div className="right-bg" style={{ height: '500px' }}></div>
    </>
  );
};

export default Queries;
