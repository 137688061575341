import React, { useEffect, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { AiFillDelete, AiOutlineCodeSandbox } from 'react-icons/ai';
import { SiDbt } from 'react-icons/si';
import {
  DestinationsResponse,
  DestinationListed,
  DestinationTemplatesResponse
} from '../../types/api';
import {
  getDestinations,
  deleteDestination,
  notification,
  getDestinationTemplates
} from '../../services/services';
import { useNavigate } from 'react-router-dom';
import Path from '../../Path';
import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { AppActionType } from '../../reducers/app';
import { Mixpanel } from '../common/Mixpanel';
import { State } from '../../types/state';
import DbtFormModal from './DbtFormModal';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import EmptyData from '../common/EmptyData';
import Loader from '../common/Loader';

const DestinationList = () => {
  const [destinationTemplates, setDestinationTemplates] =
    useState<DestinationTemplatesResponse>([]);
  const [destinations, setDestinations] = useState<DestinationsResponse>([]);
  const [loaded, setLoaded] = useState(false);
  const [showDbtFormModal, setShowDbtFormModal] = useState(false);
  const [selectedDbtDestinationId, setSelectedDbtDestinationId] =
    useState<number>(0);
  const organizationId = useSelector(
    (state: State) => state.app?.account?.organization?.id
  );
  const githubEnabled = useSelector(
    (state: State) => state.app?.account?.accounts?.github?.connected
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (destinationTemplates.length > 0) {
      return;
    }

    (async () => {
      try {
        const response = await getDestinationTemplates();
        setDestinationTemplates(response);
      } catch (e) {
        console.error(e);
      }
    })();
  });

  useEffect(() => {
    if (loaded) {
      return;
    }
    Mixpanel.track('Dashboard - Destination List', { is_page_view: true });

    (async () => {
      try {
        const response = await getDestinations();
        setDestinations(response);
        setLoaded(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [loaded, destinations]);

  const supportsDBT = (destination: DestinationListed): boolean => {
    const template = destinationTemplates.find(
      (item) => item.name === destination.type_adapter_name
    );
    return template?.supports_dbt ?? false;
  };

  const handleDelete = (id: number) => async () => {
    try {
      await deleteDestination(id);
      notification('Destination deleted successfully', 'success');
      dispatch({
        type: AppActionType.REMOVE_DESTINATION,
        payload: id
      });
      setDestinations(destinations.filter((item) => item.id !== id));
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status === 400) {
        notification('Destination not deletable', 'warning');
        return;
      } else if (error.response?.status === 401) {
        notification(
          "You don't have permission to delete this destination",
          'warning'
        );
        return;
      } else if (error.response?.status === 404) {
        notification('Destination no longer present', 'warning');
        return;
      } else {
        notification('Something went wrong', 'warning');
        console.error(e);
      }
    }
  };

  const onNewDestinationClick = () => {
    navigate('/choose-destination');
  };

  return (
    <div>
      {loaded ? (
        <>
          <DbtFormModal
            show={showDbtFormModal}
            onHide={() => setShowDbtFormModal(false)}
            organizationId={organizationId || 0}
            destinationId={selectedDbtDestinationId}
          />
          <div className="flex md:items-center md:justify-between px-4 py-6 rounded-lg">
            <div className="min-w-0 flex-1 self-center">
              <h1 className="text-xl md:text-3xl text-gray-500 font-bold sm:truncate sm:tracking-tight">
                Destinations
              </h1>
            </div>
            <div className="flex">
              <button
                type="button"
                className="btnMovinglake w-fit"
                onClick={() => {
                  navigate('/choose-destination');
                }}
              >
                <PlusCircleIcon className="h-6 w-6 mr-1" />
                New Destination
              </button>
            </div>
          </div>
          <hr className="h-px my-3 mb-6 bg-gray-200 border-0" />
          <ul className="divide-y divide-gray-100 mt-3">
            {destinations.length > 0 &&
              destinations.map((destination) => (
                <li
                  key={destination.id}
                  className="relative py-4 hover:bg-gray-50 animateList"
                >
                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
                      <div className="flex min-w-0 gap-x-4">
                        <img
                          className="h-16 w-16 flex-none rounded-full bg-gray-50"
                          src={`${process.env.REACT_APP_API_URL}/static/media/${destination.icon}`}
                          alt=""
                        />
                        <div className="min-w-0 flex-auto">
                          <p className="text-lg font-semibold leading-6 text-gray-900">
                            <span className="absolute -top-px bottom-0" />
                            {destination.name}
                          </p>
                          <p className="mt-1 flex text-xs leading-5 text-gray-500 gap-3">
                            <b>ID: {destination.id}</b>{' '}
                            {destination.type_adapter_name}
                          </p>
                        </div>
                      </div>
                      <div className="flex shrink-0 items-center gap-x-4">
                        <FiEdit2
                          size={22}
                          onClick={() => {
                            navigate(
                              Path.EditDestinationForm.replace(
                                ':id',
                                destination.id.toString()
                              )
                            );
                          }}
                        />
                        <AiFillDelete
                          size={24}
                          onClick={handleDelete(destination.id)}
                        />
                        {supportsDBT(destination) && githubEnabled && (
                          <SiDbt
                            size={24}
                            onClick={() => {
                              if (destination.dbt_project_id) {
                                navigate(
                                  Path.DbtProjectDetails.replace(
                                    ':id',
                                    destination.dbt_project_id.toString()
                                  )
                                );
                              } else {
                                setSelectedDbtDestinationId(destination.id);
                                setShowDbtFormModal(true);
                              }
                            }}
                            aria-label="DBT"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            {destinations.length === 0 && (
              <EmptyData
                onNewClicked={onNewDestinationClick}
                Icon={AiOutlineCodeSandbox}
                title="No destinations yet"
                description="Create a new destination to have your data sync"
              />
            )}
          </ul>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default DestinationList;
