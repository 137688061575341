import React, { useState } from 'react';
import { BillingInvoice, BillingErrorResponse } from '../../types/api';
import {
  createSubscription,
  getBillingPortal,
  notification
} from '../../services/services';
import { AiFillCreditCard } from 'react-icons/ai';
import { Mixpanel } from '../common/Mixpanel';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { State } from '../../types/state';
import {
  CalendarDaysIcon,
  CheckIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  UserCircleIcon
} from '@heroicons/react/24/solid';
import { FaAddressBook } from 'react-icons/fa';
import { RadioGroup } from '@headlessui/react';
import { useBilling } from '../../contexts/BillingContext';

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/ month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/ each month' }
];
const tiers = [
  {
    name: 'Starter',
    id: 'tier-starter',
    prod_id: 'prod_OvYeTFpXpAUEC6',
    price: { monthly: '$230', annually: '$199' },
    description: 'The essentials to provide basic usage of Movinglake sources.',
    //features: ['1 Data source + 50USD/m per extra source', 'Up to 3 users', 'MovingLake Cloud Support (30USD/Month)', 'Daily Data Updates', "Data Transformer Layer (DBT)"],
    features: ['Daily Data Updates', 'Data Transformer Layer (DBT)'],
    featured: false,
    action: { monthly: 'starter_monthly', annually: 'starter_anually' },
    isComing: false
  },
  {
    name: 'Professional',
    id: 'tier-profesional',
    prod_id: 'prod_OxQQoSlvn0NI6c',
    price: { monthly: '$499', annually: '$420' },
    description: 'For users who need more data sources and users.',
    features: [
      '5 Data sources + 15USD/m per extra source',
      'Up to 10 users',
      'Movinglake Cloud Support free',
      'Uncapped Update Speed',
      'Pre-built Transformations'
    ],
    featured: false,
    action: {
      monthly: 'professional_monthly',
      annually: 'professional_anually'
    },
    isComing: true
  },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    prod_id: 'prod_OxQScIUNSNOzxL',
    price: { monthly: '$1090', annually: '$950' },
    description: 'Dedicated support and infrastructure for your company.',
    features: [
      'Unlimited Sources',
      'Unlimited Users',
      'Custom Deployment Scenarios',
      'SSO Support',
      'Dedicated Data Analysis Hours',
      'Integrated Custom Dashboards',
      'Access to AI Insights Preview'
    ],
    featured: true,
    action: { monthly: 'enterprise_monthly', annually: 'enterprise_anually' },
    isComing: true
  }
];

const Billing = () => {
  const { billingDetails, billingHistory, billingSubscription } = useBilling();
  const account = useSelector((state: State) => state.app?.account);
  const [frequency, setFrequency] = useState(frequencies[0]);

  Mixpanel.track('Dashboard - Billing Details', { is_page_view: true });

  const formatAmount = (invoice: BillingInvoice): string => {
    const formatter = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: invoice.currency,
      minimumFractionDigits: 2
    });
    return formatter.format(invoice.amount_due / 100);
  };

  const handleManageSubscription = async () => {
    try {
      const response = await getBillingPortal();
      if (response.message) {
        notification(response.message, 'warning');
      } else {
        Mixpanel.track('Dashboard - Billing Manage Subscription', {
          is_click: true
        });
        window.open(response.url, '_blank');
      }
    } catch (e) {
      console.error(e);
      const error = e as AxiosError;
      const billingError = error.response?.data as BillingErrorResponse;
      if (billingError.message) {
        notification(billingError.message, 'warning');
      } else {
        notification('Something went wrong', 'danger');
      }
    }
  };

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  const handleCreateCheckoutSession = async (action: any) => {
    try {
      const response = await createSubscription({ plan: action });
      if (response.message) {
        notification(response.message, 'warning');
      } else {
        Mixpanel.track('Dashboard - Billing Create Checkout Session', {
          is_click: true
        });
        window.open(response.url, '_blank');
      }
    } catch (e) {
      console.error(e);
      const error = e as AxiosError;
      const billingError = error.response?.data as BillingErrorResponse;
      if (billingError.message) {
        notification(billingError.message, 'warning');
      } else {
        notification('Something went wrong', 'danger');
      }
    }
  };

  return (
    <>
      <div className="bg-white sm:py-8">
        <div className="mx-auto mc:max-w-7xl md:px-6 lg:px-8">
          <div className="mx-auto md:max-w-2xl sm:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Billing & Subscription Info
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              You can check your billing and subscription info here. Adding a
              payment method is required to use MovingLake once your free trial
              expires.
            </p>
          </div>
          <div className="mx-auto md:max-w-7xl md:px-6 lg:px-8">
            <div className="mt-5 flex justify-center">
              <RadioGroup
                value={frequency}
                onChange={setFrequency}
                className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
              >
                <RadioGroup.Label className="sr-only">
                  Payment frequency
                </RadioGroup.Label>
                {frequencies.map((option) => (
                  <RadioGroup.Option
                    key={option.value}
                    value={option}
                    className={({ checked }) =>
                      classNames(
                        checked
                          ? 'bg-submarine-600 text-white'
                          : 'text-gray-500',
                        'cursor-pointer rounded-full px-2.5 py-1'
                      )
                    }
                  >
                    <span>{option.label}</span>
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>
            <div className="rounded-md bg-blue-100 p-4 mt-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-base text-blue-700">
                    Webhooks are applied immediately despite frequency caps.
                  </p>
                </div>
              </div>
            </div>
            <div className="isolate mx-auto mt-5 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className={classNames(
                    tier.prod_id ===
                      billingSubscription?.items.data[0].plan.product
                      ? 'featuredBilling'
                      : 'ring-gray-200',
                    'p-8 ring-1 xl:p-10'
                  )}
                >
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.prod_id ===
                        billingSubscription?.items.data[0].plan.product
                        ? 'text-white'
                        : 'text-gray-900',
                      'text-3xl font-bold leading-8'
                    )}
                  >
                    {tier.name}
                  </h3>
                  <p
                    className={classNames(
                      tier.prod_id ===
                        billingSubscription?.items.data[0].plan.product
                        ? 'text-gray-300'
                        : 'text-gray-600',
                      'mt-4 text-sm leading-6'
                    )}
                  >
                    {tier.description}
                  </p>
                  {!tier.isComing ? (
                    <div className="flex justify-between items-center mt-3">
                      <p className="flex items-baseline gap-x-1">
                        <span
                          className={classNames(
                            tier.prod_id ===
                              billingSubscription?.items.data[0].plan.product
                              ? 'text-white'
                              : 'text-gray-900',
                            'text-4xl font-bold tracking-tight'
                          )}
                        >
                          {typeof tier.price === 'string'
                            ? tier.price
                            : tier.price[
                                frequency.value as 'monthly' | 'annually'
                              ]}
                        </span>
                        {typeof tier.price !== 'string' ? (
                          <span
                            className={classNames(
                              tier.prod_id ===
                                billingSubscription?.items.data[0].plan.product
                                ? 'text-gray-300'
                                : 'text-gray-600',
                              'text-sm font-semibold leading-6'
                            )}
                          >
                            {frequency.priceSuffix}
                          </span>
                        ) : null}
                      </p>
                      <button
                        aria-describedby={tier.id}
                        className={classNames(
                          tier.prod_id ===
                            billingSubscription?.items.data[0].plan.product
                            ? 'featuredBtn'
                            : 'btnMovinglake',
                          'h-fit block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6'
                        )}
                        disabled={
                          !(billingSubscription?.status === 'canceled') &&
                          tier.prod_id ===
                            billingSubscription?.items.data[0].plan.product
                        }
                        onClick={() =>
                          handleCreateCheckoutSession(
                            tier.action[
                              frequency.value as 'monthly' | 'annually'
                            ]
                          )
                        }
                      >
                        {!(billingSubscription?.status === 'canceled') &&
                        tier.prod_id ===
                          billingSubscription?.items.data[0].plan.product
                          ? 'Suscribed'
                          : 'Suscribe'}
                      </button>
                    </div>
                  ) : (
                    <p className="text-4xl font-bold tracking-tight">
                      Coming soon
                    </p>
                  )}
                  <ul
                    className={classNames(
                      tier.prod_id ===
                        billingSubscription?.items.data[0].plan.product
                        ? 'text-gray-300'
                        : 'text-gray-600',
                      'mt-8 space-y-3 text-sm leading-6 xl:mt-6'
                    )}
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className={classNames(
                            tier.prod_id ===
                              billingSubscription?.items.data[0].plan.product
                              ? 'text-white'
                              : 'text-submarine-600',
                            'h-6 w-5 flex-none'
                          )}
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 border-b border-gray-200 pb-2">
        <div className="-ml-2 -mt-2 flex flex-wrap gap-2 items-baseline">
          <h3 className="ml-2 mt-2 text-xl font-semibold leading-6 text-gray-900">
            My Subscription
          </h3>
          <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            {billingSubscription?.status.toUpperCase() || 'Undefined'}
          </span>
          <button className="btnMovinglake" onClick={handleManageSubscription}>
            Manage subscription
          </button>
        </div>
      </div>

      <div>
        <div className="px-4 sm:px-0 mt-3">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Payment Method
          </h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
            Details of your payment method.
          </p>
        </div>
        {!billingDetails || !billingDetails?.card_last4 ? (
          <div className="rounded-md bg-yellow-50 p-4 mt-3">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  No payment method associated
                </h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    Please add a payment method to avoid losing access to your
                    connectors
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="mt-3">
          <dl className="grid grid-cols-1 sm:grid-cols-2">
            <div className="border-t border-gray-100 px-4 py-3 sm:col-span-2 sm:px-0">
              <dd className="mt-2 text-sm text-gray-900">
                <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                  <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                    <div className="flex w-0 flex-1 items-center">
                      <AiFillCreditCard
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <div className="ml-4 flex min-w-0 flex-1 gap-2">
                        {!billingDetails || !billingDetails?.card_last4 ? (
                          <p className="self-center text-sm leading-6 text-gray-500">
                            None
                          </p>
                        ) : (
                          <input
                            type="text"
                            className="border-0 self-center focus:ring-0 focus:outline-none p-0"
                            readOnly={true}
                            placeholder={`${billingDetails?.card_brand.toLocaleUpperCase()}  **** **** **** ${
                              billingDetails?.card_last4 || '****'
                            }`}
                          />
                        )}
                      </div>
                    </div>
                  </li>
                  {billingDetails?.billing_details && (
                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                      <div className="flex w-0 flex-1 items-center">
                        <FaAddressBook
                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <div className="ml-4 flex min-w-0 flex-1 gap-2">
                          <p className="self-center text-sm leading-6 text-gray-500">
                            Billing address:{' '}
                            {billingDetails?.billing_details?.address.country}
                          </p>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <h3 className="text-base font-semibold leading-7 text-gray-900 mb-5">
        Invoices
      </h3>
      <div className="grid grid-cols-1 sm:grid-cols-4 md:gap-2 lg:gap-5 gap-5">
        {billingHistory.map((item, key) => (
          <div key={key}>
            <h2 className="sr-only">Summary</h2>
            <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
              <dl className="flex flex-wrap">
                <div className="flex-auto pl-6 pt-2">
                  <dt className="text-sm font-semibold leading-6 text-gray-900">
                    Amount
                  </dt>
                  <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                    {formatAmount(item)}
                  </dd>
                </div>
                <div className="flex-none self-end px-6">
                  <dt className="sr-only">Status</dt>
                  <dd className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    {item.status.toUpperCase()}
                  </dd>
                </div>
                <div className="mt-1 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                  <dt className="flex-none">
                    <span className="sr-only">Client</span>
                    <UserCircleIcon
                      className="h-6 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd className="text-sm font-medium leading-6 text-gray-900">
                    {account?.user.email}
                  </dd>
                </div>
                <div className="mt-2 flex w-full flex-none gap-x-4 px-6">
                  <dt className="flex-none">
                    <span className="sr-only">Due date</span>
                    <CalendarDaysIcon
                      className="h-6 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd className="text-sm leading-6 text-gray-500">
                    Created on{' '}
                    {new Date(item.created * 1000).toLocaleDateString()}
                  </dd>
                </div>
                <div className="mt-2 flex w-full flex-none gap-x-4 px-6">
                  <dt className="flex-none">
                    <span className="sr-only">Due date</span>
                    <CalendarDaysIcon
                      className="h-6 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd className="text-sm leading-6 text-gray-500">
                    Due on {new Date(item.due_date * 1000).toLocaleDateString()}
                  </dd>
                </div>
                <div className="mt-2 flex w-full flex-none gap-x-4 px-6">
                  <dt className="flex-none">
                    <span className="sr-only">Due on</span>
                    <CalendarDaysIcon
                      className="h-6 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd className="text-sm leading-6 text-gray-500">
                    Paid at{' '}
                    {item.status_transitions.paid_at &&
                      new Date(
                        item.status_transitions.paid_at * 1000
                      ).toLocaleDateString()}
                  </dd>
                </div>
              </dl>
              <div className="mt-1 border-t border-gray-900/5 px-6 py-2">
                <a
                  href={item.invoice_pdf}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Invoice <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Billing;
