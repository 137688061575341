import React, { useCallback, useEffect, useState } from 'react';
import {
  ConnectorDetailResponse,
  ConnectorTemplateResponse,
  EntitySpec,
  PersistedEntityConfiguration
} from '../../types/api';
import 'rsuite/dist/rsuite.min.css';
import {
  Toggle,
  DatePicker,
  Input,
  InputGroup,
  AutoComplete,
  Whisper,
  Popover
} from 'rsuite';
import {
  executeConnectorNow,
  notification,
  setConnectorBackfillDate,
  updateConnectorEntity,
  updateCustomParams
} from '../../services/services';
import { VscDebugRerun } from 'react-icons/vsc';
import { AiFillInfoCircle } from 'react-icons/ai';
import cronstrue from 'cronstrue';
import SelectPicker from 'rsuite/SelectPicker';
import { ImSearch } from 'react-icons/im';
import {
  BsChevronBarLeft,
  BsChevronBarRight,
  BsChevronLeft,
  BsChevronRight
} from 'react-icons/bs';
import { Field, Form, Formik } from 'formik';
import PaginationC from '../common/PaginationC';
import ConfirmationModal from './modals/ConfirmationModal';
const Rodal = require('rodal').default;

const Pagination = require('react-js-pagination').default;

type SchedulingConfigurationProps = {
  connector: ConnectorDetailResponse;
  configuration: ConnectorTemplateResponse;
  reloadConnector: () => Promise<void>;
  imgSrc: string;
};

type internalEntityState = Record<
  string,
  {
    cron_expression: string;
    cron_text: string;
    is_valid: boolean;
    next_time: Date | null;
    active_by_target: Record<number, boolean>;
  }
>;

const SchedulingConfiguration = ({
  connector,
  configuration,
  reloadConnector,
  imgSrc
}: SchedulingConfigurationProps) => {
  const itemsPerPage = 6;
  const [activePage, setActivePage] = useState(1);
  const [activeParams, setActiveParamsPage] = useState(1);
  const [textFilter, setTextFilter] = useState('');
  const [backfillDate, setBackfillDate] = useState<Date | null>(null);
  const [internalEntityState, setInternalEntityState] =
    useState<internalEntityState | null>(null);
  const entityConfiguration = useCallback(
    (entity: PersistedEntityConfiguration): EntitySpec => {
      return configuration.entities.find(
        (item) => item.name === entity.name
      ) as EntitySpec;
    },
    [configuration.entities]
  );
  const [showParamsModal, setShowParamsModal] = useState(false);
  const [editingEntity, setEditingEntity] = useState({} as any);
  const [customParams, setCustomParams] = useState({} as any);
  const [connectorCustomParams, setConnectorCustomParams] = useState({} as any);
  const [showMainEntityConfirmation, setShowMainEntityConfirmation] =
    useState(false);
  const [mainValue, setMainValue] = useState(null as any);
  const [mainEntity, setMainEntity] = useState(
    {} as PersistedEntityConfiguration
  );
  const [
    showActivateMainEntityConfirmation,
    setShowActivateMainEntityConfirmation
  ] = useState(false);
  const [mainTargetId, setMainTargetId] = useState(0);

  useEffect(() => {
    (async () => {
      if (Object.keys(connectorCustomParams).length !== 0)
        await updateCustomParams(connectorCustomParams, connector.id);
    })();
  }, [connectorCustomParams, connector.id]);

  const parsedCron = (cron: string): [string, boolean] => {
    try {
      return [cronstrue.toString(cron), true];
    } catch (e) {
      return ['Invalid cron expression', false];
    }
  };

  useEffect(() => {
    if (internalEntityState !== null) {
      return;
    }
    setInternalEntityState(
      connector.targets[0].entities.reduce((acc, entity) => {
        const config = entityConfiguration(entity);
        if (!config) {
          return acc;
        }
        const activeByTarget: Record<number, boolean> = {};
        connector.targets.forEach((target) => {
          const targetEntity = target.entities.find(
            (targetEntity) => targetEntity.name === entity.name
          );
          if (targetEntity) {
            activeByTarget[target.destination_id] = targetEntity.is_active;
          }
        });
        if (config.timing_configuration.strategy !== 'cron_expression') {
          const nextTime =
            entity.next_time !== null ? new Date(entity.next_time) : null;
          return {
            ...acc,
            [entity.name]: {
              cron_expression: '',
              cron_text: '',
              is_valid: true,
              active_by_target: activeByTarget,
              next_time: nextTime
            }
          };
        }
        const cronText = parsedCron(entity.cron_expression)[0];
        const nextTime =
          entity.next_time !== null ? new Date(entity.next_time) : null;
        return {
          ...acc,
          [entity.name]: {
            cron_expression: entity.cron_expression,
            cron_text: cronText,
            is_valid: cronText !== 'Invalid cron expression',
            active_by_target: activeByTarget,
            next_time: nextTime
          }
        };
      }, {})
    );
  }, [internalEntityState, connector.targets, entityConfiguration]);

  const timingSelectData = (
    entity: EntitySpec
  ): { label: string; value: string }[] => {
    if (entity.timing_configuration.strategy === 'cron_expression') {
      return [];
    }
    return entity.timing_configuration.validation_data.allowed_repeat_every.map(
      (item: { label: string; seconds: number }) => {
        return {
          label: item.label,
          value: item.seconds.toString()
        };
      }
    );
  };

  const entityConfigurations: Record<string, EntitySpec> = {};
  connector.targets[0].entities.forEach((entity) => {
    entityConfigurations[entity.name] = entityConfiguration(entity);
  });

  const entityNames = Object.keys(entityConfigurations);

  const handleBackfillDate = async (date: Date) => {
    try {
      await setConnectorBackfillDate(connector.id, date);
      notification('Backfill date set', 'success');
      setBackfillDate(date);
      await reloadConnector();
    } catch (e) {
      notification('Error setting backfill date', 'warning');
      console.error(e);
      setBackfillDate(null);
    }
  };

  const handleSyncNow = async () => {
    try {
      await executeConnectorNow(connector.id);
      notification('Sync will begin as soon as possible', 'success');
      await reloadConnector();
    } catch (e) {
      notification('Error starting sync', 'warning');
      console.error(e);
    }
  };

  if (internalEntityState === null) {
    return null;
  }

  const getMainEntity = (entity: any, data: any) => {
    for (const value in data) {
      if (Object.prototype.hasOwnProperty.call(data, value)) {
        const values = data[value];
        if (values.includes(entity.name)) {
          return value;
        }
      }
    }
    return false;
  };

  const getMainEntityStatus = (entity: any, data: any) => {
    for (const value in data) {
      if (Object.prototype.hasOwnProperty.call(data, value)) {
        const values = data[value];

        if (values.includes(entity.name)) {
          const main = connector.targets[0].entities.find(
            (entity) => entity.name === value
          ) || { id: 0 };
          const mainActivated = connector.targets[0].entities.find(
            (entity) => entity.id === main.id
          );

          return mainActivated?.is_active;
        }
      }
    }
    return false;
  };

  const handleToggle =
    (entity: PersistedEntityConfiguration, name: string, targetId: number) =>
    async (value: boolean) => {
      try {
        if (tree(connector.targets[0].entities)[entity.name]) {
          if (value === true) {
            if (
              includeMainEntity(entity, tree(connector.targets[0].entities)) &&
              getMainEntityStatus(
                entity,
                tree(connector.targets[0].entities)
              ) === false
            ) {
              let name = getMainEntity(
                entity,
                tree(connector.targets[0].entities)
              );

              setMainEntity(
                connector.targets[0].entities.find((entity) => {
                  return entity.name === name;
                }) || ({} as PersistedEntityConfiguration)
              );

              setMainValue(value);
              setShowActivateMainEntityConfirmation(true);
              setMainTargetId(targetId);
            } else {
              try {
                await updateEntity(value, name, targetId);
                notification('Entity updated', 'success');
              } catch (e) {
                notification('Error updating entity', 'warning');
                console.error(e);
              } finally {
                //await reloadConnector();
                window.location.reload();
              }
            }
          } else {
            setMainEntity(entity);
            setMainValue(value);
            setMainTargetId(targetId);
            setShowMainEntityConfirmation(true);
          }
        } else {
          if (
            includeMainEntity(entity, tree(connector.targets[0].entities)) &&
            getMainEntityStatus(entity, tree(connector.targets[0].entities)) ===
              false
          ) {
            let name = getMainEntity(
              entity,
              tree(connector.targets[0].entities)
            );

            setMainEntity(
              connector.targets[0].entities.find((entity) => {
                return entity.name === name;
              }) || ({} as PersistedEntityConfiguration)
            );

            setMainValue(value);
            setMainTargetId(targetId);
            setShowActivateMainEntityConfirmation(true);
          } else {
            await updateConnectorEntity(connector.id, {
              name,
              is_active: value,
              target_id: targetId
            });
            setInternalEntityState({
              ...internalEntityState,
              [name]: {
                ...internalEntityState[name],
                active_by_target: {
                  ...internalEntityState[name].active_by_target,
                  [targetId]: value
                }
              }
            });
            notification('Entity updated', 'success');
            await reloadConnector();
          }
        }
      } catch (e) {
        notification('Error updating entity', 'warning');
        console.error(e);
      }
    };

  const handleEntityBackfillDate = (name: string) => async (value: Date) => {
    try {
      setInternalEntityState({
        ...internalEntityState,
        [name]: {
          ...internalEntityState[name],
          next_time: value
        }
      });
      await updateConnectorEntity(connector.id, {
        name,
        next_time: value
      });
      await reloadConnector();
      notification('Entity updated', 'success');
    } catch (e) {
      notification('Error updating entity', 'warning');
      console.error(e);
    }
  };

  const handleRepeatEvery = (name: string) => async (value: string | null) => {
    if (!value) {
      notification('Invalid value', 'warning');
      return;
    }
    try {
      await updateConnectorEntity(connector.id, {
        name,
        timing_configuration: { repeat_every: parseInt(value) }
      });
      await reloadConnector();
      notification('Entity updated', 'success');
    } catch (e) {
      notification('Error updating entity', 'warning');
      console.error(e);
    }
  };

  const handleCronExpression = (name: string) => async (value: string) => {
    const [cronText, isValid] = parsedCron(value);
    setInternalEntityState({
      ...internalEntityState,
      [name]: {
        ...internalEntityState[name],
        cron_text: cronText,
        cron_expression: value
      }
    });

    if (!isValid) {
      return;
    }
    try {
      await updateConnectorEntity(connector.id, {
        name,
        timing_configuration: { cron_expression: value }
      });
      notification('Entity updated', 'success');
      await reloadConnector();
    } catch (e) {
      notification('Error updating entity', 'warning');
      console.error(e);
    }
  };

  const tree = (entitiesConfigs: PersistedEntityConfiguration[]) => {
    const data = entitiesConfigs.filter((entity) => {
      if (entity.skip_write) return false;
      return true;
    });

    const treeRecord: Record<any, any> = {};

    for (const entity of data) {
      const name = entity.name;
      const everyRecord = entity.every_record;
      if (everyRecord) {
        if (treeRecord[everyRecord.name]) {
          treeRecord[everyRecord.name].push(name);
        } else {
          treeRecord[everyRecord.name] = [name];
        }
      }
    }

    entitiesConfigs.forEach((entity) => {
      if (entity.skip_write) delete treeRecord[entity.name];
      return true;
    });

    return treeRecord;
  };

  const sortedEntities = connector.targets[0].entities
    .sort((a, b) => {
      if (
        tree(connector.targets[0].entities)[a.name] &&
        !tree(connector.targets[0].entities)[b.name]
      ) {
        return -1;
      } else if (
        !tree(connector.targets[0].entities)[a.name] &&
        tree(connector.targets[0].entities)[b.name]
      ) {
        return 1;
      }
      return 0;
    })
    .filter((entity) => {
      return !!entityConfigurations[entity.name];
    })
    .filter((entity) => {
      if (!textFilter) {
        return true;
      }
      return entity.name.toLowerCase().includes(textFilter.toLowerCase());
    });

  const displayEntityName = (entity: string, owner: string) => {
    if (!entity) return;
    const reg_ex = new RegExp(owner + '_', 'g');
    let entityName = entity.replace(reg_ex, '');
    return entityName
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const includeMainEntity = (entity: any, data: any) => {
    for (const value in data) {
      if (Object.prototype.hasOwnProperty.call(data, value)) {
        const values = data[value];
        if (values.includes(entity.name)) {
          return true;
        }
      }
    }
    return false;
  };

  const updateEntity = async (value: any, name: any, targetId: any) => {
    await updateConnectorEntity(connector.id, {
      name,
      is_active: value,
      target_id: targetId
    });
    setInternalEntityState({
      ...internalEntityState,
      [name]: {
        ...internalEntityState[name],
        active_by_target: {
          ...internalEntityState[name].active_by_target,
          [targetId]: value
        }
      }
    });
    tree(connector.targets[0].entities)[name] &&
      tree(connector.targets[0].entities)[name].forEach((child: any) => {
        updateEntity(value, child, targetId);
      });
  };

  return (
    <>
      <div className="block sm:flex md:items-center md:justify-between px-4 py-6 rounded-lg">
        <div className="min-w-0 flex-1 self-center">
          <h1 className="text-sm sm:text-xl text-gray-500 font-bold sm:truncate sm:tracking-tight">
            Current status of entities extracted
          </h1>
        </div>
        <div className="flex mt-2">
          <DatePicker
            value={backfillDate}
            onOk={handleBackfillDate}
            placeholder="Set backfill date"
          />
          <button
            type="button"
            className="ml-3 btnMovinglake w-fit"
            onClick={handleSyncNow}
          >
            <VscDebugRerun className="h-4 w-4 mr-1" />
            Sync Now
          </button>
        </div>
      </div>
      <div className="container mx-auto px-1 lg:px-0">
        <div className="grid grid-cols-1 mt-1">
          <div className="col-span-2">
            <InputGroup>
              <AutoComplete
                data={entityNames}
                placeholder="Search entity"
                onChange={setTextFilter}
                value={textFilter}
              />
              <InputGroup.Button tabIndex={-1}>
                <ImSearch />
              </InputGroup.Button>
            </InputGroup>
          </div>

          <ul className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
            {sortedEntities
              .filter((entity) => {
                if (entity.skip_write) return false;
                return true;
              })
              .map((entity, key) => {
                if (
                  !(
                    key >=
                      (activePage === 1
                        ? activePage - 1
                        : (activePage - 1) * itemsPerPage) &&
                    key < activePage * itemsPerPage
                  )
                ) {
                  return null;
                }
                if (textFilter && !entity.name.includes(textFilter)) {
                  return null;
                }
                return (
                  <li
                    key={entity.id}
                    className="overflow-hidden rounded-xl border border-gray-200"
                  >
                    <div
                      className={`gap-x-4 border-b border-gray-900/5 p-6 ${
                        tree(connector.targets[0].entities)[entity.name]
                          ? 'text-white bg-[#223c7d]'
                          : 'bg-gray-50'
                      }`}
                    >
                      <div className={`xl:flex items-cente`}>
                        <div className="flex items-center gap-5 mb-5">
                          <img
                            src={imgSrc}
                            alt={entity.name}
                            className="h-10 w-10 sm:h-12 sm:w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                          />
                          <div
                            className={`text-sm sm:text-base font-bold ${
                              tree(connector.targets[0].entities)[entity.name]
                                ? 'text-white'
                                : 'text-gray-900'
                            }`}
                          >
                            {displayEntityName(
                              entity.name,
                              connector.connector_cat_name.toLowerCase() || ''
                            )}
                          </div>
                        </div>
                        <div className="relative ml-auto">
                          <div className="flex gap-2">
                            {connector.targets.map((target, key) => (
                              <>
                                <span>{target.destination_type_name}</span>
                                <Toggle
                                  checked={
                                    internalEntityState[entity.name]
                                      .active_by_target &&
                                    internalEntityState[entity.name]
                                      .active_by_target[target.destination_id]
                                  }
                                  onChange={handleToggle(
                                    entity,
                                    entity.name,
                                    target.destination_id
                                  )}
                                />
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div>
                        {includeMainEntity(
                          entity,
                          tree(connector.targets[0].entities)
                        ) && (
                          <p
                            className={`${
                              tree(connector.targets[0].entities)[entity.name]
                                ? 'text-white'
                                : 'text-red-600'
                            } font-bold`}
                          >
                            Requires the entity{' '}
                            {displayEntityName(
                              entity.every_record?.name || '',
                              connector.connector_cat_name.toLowerCase() || ''
                            )}
                          </p>
                        )}
                      </div>
                    </div>

                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                      <div className="grid grid-cols-3 gap-x-2 py-3">
                        <dt className="text-gray-500">Scheduling</dt>
                        <dd>
                          {entityConfigurations[entity.name]
                            .timing_configuration.strategy ===
                          'repeat_every' ? (
                            <SelectPicker
                              size="xs"
                              className="w-full"
                              placement="autoVerticalEnd"
                              value={entity.repeat_every.toString()}
                              onChange={handleRepeatEvery(entity.name)}
                              data={timingSelectData(
                                entityConfigurations[entity.name]
                              )}
                              cleanable={false}
                            />
                          ) : (
                            <td
                              style={{ width: '20%', padding: '18px 0 0 0 ' }}
                            >
                              <div
                                className="d-flex flex-column justify-content-between"
                                style={{ height: '100%' }}
                              >
                                <Input
                                  size="sm"
                                  style={{
                                    width: '90%',
                                    marginTop: 'auto',
                                    marginBottom: 'auto'
                                  }}
                                  placeholder="Cron expression"
                                  onChange={handleCronExpression(entity.name)}
                                  value={
                                    internalEntityState[entity.name]
                                      .cron_expression
                                  }
                                />
                                <span
                                  className="text-muted"
                                  style={{
                                    fontSize: 11,
                                    textAlign: 'left',
                                    marginLeft: 4
                                  }}
                                >
                                  {internalEntityState[entity.name].cron_text}
                                </span>
                              </div>
                            </td>
                          )}
                        </dd>
                        <dd>
                          <DatePicker
                            value={internalEntityState[entity.name].next_time}
                            onOk={handleEntityBackfillDate(entity.name)}
                            placeholder="Immediately"
                            size="xs"
                            className="w-full"
                            placement="autoVerticalEnd"
                          />
                        </dd>
                      </div>
                      {entity.custom_params_templates && (
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Custom Entity</dt>
                          <dd className="flex items-start gap-x-2">
                            <button
                              onClick={() => {
                                setEditingEntity(entity);
                                setShowParamsModal(true);
                                setCustomParams({});
                              }}
                            >
                              Custom Entity
                            </button>
                          </dd>
                        </div>
                      )}
                    </dl>
                  </li>
                );
              })}
          </ul>
        </div>
        <PaginationC
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={sortedEntities.length}
          pageRangeDisplayed={itemsPerPage}
          onPageChange={(pageNumber: number) => setActivePage(pageNumber)}
        />
      </div>
      <Rodal
        className="customParams popupmodal"
        style={{ height: 'fit-content !important' }}
        visible={showParamsModal}
        onClose={() => {
          setShowParamsModal(false);
          setEditingEntity({} as EntitySpec);
          setActiveParamsPage(1);
          setCustomParams({});
        }}
      >
        <h5 className="ms-1 fw-normal my-0">
          Custom{' '}
          <b>
            <i>{editingEntity.name}</i>
          </b>{' '}
        </h5>
        <div className="mt-4">
          <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {
              actions.setValues({});

              const combinedParams = { ...values, ...customParams };

              const currentParams = { ...connectorCustomParams };
              currentParams[editingEntity.name] = {
                customParams: combinedParams
              };
              setConnectorCustomParams(currentParams);

              setEditingEntity({} as EntitySpec);
              setShowParamsModal(false);
              notification(
                `${editingEntity.name} custom params set`,
                'success'
              );
            }}
          >
            <Form>
              {editingEntity.custom_params_templates?.map(
                (param: any, key: number) => {
                  if (
                    !(
                      key >=
                        (activeParams === 1
                          ? activeParams - 1
                          : (activeParams - 1) * itemsPerPage) &&
                      key < activeParams * itemsPerPage
                    )
                  ) {
                    return null;
                  }

                  return (
                    <div className="row mt-3" key={key}>
                      <div className="col-md-5 d-flex align-items-center">
                        <Whisper
                          placement="bottomEnd"
                          speaker={<Popover>{param.description}</Popover>}
                        >
                          <h6 className="text-center">
                            <AiFillInfoCircle /> &nbsp;
                            {param.name}:
                          </h6>
                        </Whisper>
                      </div>
                      <div className="col-md-7">
                        {param.type === 'str' && (
                          <Field
                            type="text"
                            name={param.name}
                            autoComplete="off"
                            placeholder={param.name}
                            style={{
                              borderRadius: 5,
                              border: 'solid 1px',
                              margin: 0,
                              width: '100%',
                              padding: 10
                            }}
                            value={param.value}
                          />
                        )}

                        {param.type === 'int' && (
                          <Field
                            type="number"
                            name={param.name}
                            autoComplete="off"
                            placeholder={param.name}
                            style={{
                              borderRadius: 5,
                              border: 'solid 1px',
                              margin: 0,
                              width: '100%',
                              padding: 10
                            }}
                            value={param.value}
                          />
                        )}

                        {param.type === 'bool' && (
                          <Toggle
                            checked={
                              customParams[param.name]
                                ? customParams[param.name]
                                : param.value
                            }
                            onChange={(value) => {
                              setCustomParams({
                                ...customParams,
                                [param.name]: value
                              });
                            }}
                            style={{ marginLeft: '45%' }}
                          />
                        )}

                        {param.type === 'date' && (
                          <Field
                            type="date"
                            name={param.name}
                            autoComplete="off"
                            placeholder={param.name}
                            style={{
                              borderRadius: 5,
                              border: 'solid 1px',
                              margin: 0,
                              width: '100%',
                              padding: 10
                            }}
                            value={param.value}
                          />
                        )}

                        {param.type === 'datetime' && (
                          <Field
                            type="datetime-local"
                            name={param.name}
                            autoComplete="off"
                            placeholder={param.name}
                            style={{
                              borderRadius: 5,
                              border: 'solid 1px',
                              margin: 0,
                              width: '100%',
                              padding: 10
                            }}
                            value={param.value}
                          />
                        )}

                        {param.type === 'list' && (
                          <SelectPicker
                            data={param.list_values.map((item: any) => ({
                              label: item,
                              value: item
                            }))}
                            placeholder="Choose an option"
                            menuStyle={{ zIndex: 9999 }}
                            style={{ width: '100%' }}
                            value={
                              customParams[param.name]
                                ? customParams[param.name]
                                : param.value
                            }
                            onChange={(value) => {
                              setCustomParams({
                                ...customParams,
                                [param.name]: value
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                }
              )}
              {editingEntity.custom_params_templates?.length > 10 && (
                <div className="row mt-4">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <Pagination
                      activePage={activeParams}
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={
                        editingEntity.custom_params_templates?.length
                      }
                      pageRangeDisplayed={itemsPerPage}
                      firstPageText={<BsChevronBarLeft />}
                      lastPageText={<BsChevronBarRight />}
                      prevPageText={<BsChevronLeft />}
                      nextPageText={<BsChevronRight />}
                      itemClass="item"
                      activeLinkClass="active-item"
                      onChange={(paramsPageNumber: number) =>
                        setActiveParamsPage(paramsPageNumber)
                      }
                    />
                  </div>
                </div>
              )}
              <div className="row mt-4">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <button
                    className="btn btn-danger btn-lg justify-content-center"
                    style={{ width: '100%', fontSize: 14 }}
                    type="submit"
                  >
                    Set configuration
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </Rodal>
      <ConfirmationModal
        show={showMainEntityConfirmation}
        title="Are you sure you want to deactivate this main entity?"
        description={`If you deactivate this entity the next entities will also be deactivated.`}
        elements={
          tree(connector.targets[0].entities)[mainEntity.name] &&
          tree(connector.targets[0].entities)[mainEntity.name].map(
            (entitySon: string) =>
              displayEntityName(
                entitySon,
                connector.connector_cat_name.toLowerCase() || ''
              )
          )
        }
        onConfirm={async () => {
          try {
            await updateEntity(mainValue, mainEntity.name, mainTargetId);
            notification('Entity updated', 'success');
          } catch (e) {
            notification('Error updating entity', 'warning');
            console.error(e);
          } finally {
            //await reloadConnector();
            window.location.reload();
          }
        }}
        onCancel={() => {
          setShowMainEntityConfirmation(false);
        }}
      />

      <ConfirmationModal
        show={showActivateMainEntityConfirmation}
        title={`The main entity ${displayEntityName(
          mainEntity.name,
          connector.connector_cat_name.toLowerCase() || ''
        )} is disabled`}
        description={`To activate their children you must activate the main entity first.`}
        btnLabel="Activate main entity"
        onConfirm={async () => {
          try {
            await updateEntity(mainValue, mainEntity.name, mainTargetId);
            notification('Entity updated', 'success');
          } catch (e) {
            notification('Error updating entity', 'warning');
            console.error(e);
          } finally {
            //await reloadConnector();
            window.location.reload();
          }
        }}
        onCancel={() => {
          setMainEntity({} as PersistedEntityConfiguration);
          setShowActivateMainEntityConfirmation(false);
        }}
      />
    </>
  );
};
export default SchedulingConfiguration;
