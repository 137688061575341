import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Field, Form, Formik } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

type CompleteOrganizationProps = {
  show: boolean;
  handleOrganizationUpdate: (values: any) => void;
};

const CompleteOrganizationModal = ({
  show,
  handleOrganizationUpdate
}: CompleteOrganizationProps) => {
  const [open, setOpen] = useState(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const cancelButtonRef = useRef(null);

  const validationOrgSchema = Yup.object().shape({
    name: Yup.string().required('This data is required'),
    org_name: Yup.string().required('This data is required')
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-0 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <Formik
                  initialValues={{
                    name: '',
                    org_name: ''
                  }}
                  validationSchema={validationOrgSchema}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={handleOrganizationUpdate}
                >
                  {({ errors }) => (
                    <Form>
                      <div>
                        <div className="mx-auto flex items-center justify-center rounded-full">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 ml-3"
                          >
                            Tell us more about you
                          </Dialog.Title>
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                          <div className="mt-2">
                            <div>
                              <div className="relative mt-2 rounded-md shadow-sm">
                                <div className="relative mt-2">
                                  <Field
                                    type="text"
                                    name="name"
                                    className={`peer block w-full border-0 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 ${
                                      errors.name && 'placeholder:text-red-500'
                                    }`}
                                    placeholder="What is your name?"
                                    aria-invalid="true"
                                    aria-describedby="email-error"
                                  />
                                  {errors.name && (
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                      <ExclamationCircleIcon
                                        className="h-5 w-5 text-red-500"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  )}
                                  <div
                                    className={`absolute inset-x-0 bottom-0 border-t border-gray-400 peer-focus:border-t-2 peer-focus:border-b-gray-900 ${
                                      errors.name && 'border-red-500'
                                    }`}
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                              {errors.name && (
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  id="email-error"
                                >
                                  {errors.name}
                                </p>
                              )}
                              <div className="relative mt-2 rounded-md shadow-sm">
                                <div className="relative mt-2">
                                  <Field
                                    type="text"
                                    name="org_name"
                                    className={`peer block w-full border-0 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 ${
                                      errors.name && 'placeholder:text-red-500'
                                    }`}
                                    placeholder="Company name"
                                    aria-invalid="true"
                                    aria-describedby="company-error"
                                  />
                                  {errors.org_name && (
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                      <ExclamationCircleIcon
                                        className="h-5 w-5 text-red-500"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  )}
                                  <div
                                    className={`absolute inset-x-0 bottom-0 border-t border-gray-400 peer-focus:border-t-2 peer-focus:border-b-gray-900 ${
                                      errors.name && 'border-red-500'
                                    }`}
                                    aria-hidden="true"
                                  />
                                </div>
                                {errors.org_name && (
                                  <p
                                    className="mt-2 text-sm text-red-600"
                                    id="company-error"
                                  >
                                    {errors.org_name}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-6 grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3 gap-y-3">
                        <button type="submit" className="modalBtnMovinglake">
                          Save
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CompleteOrganizationModal;
