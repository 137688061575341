import React, { useState } from 'react';
import { ConnectorDetailResponse } from '../../types/api';
import Path from '../../Path';
import { useNavigate } from 'react-router-dom';
import {
  attachDestination,
  detachDestination,
  notification
} from '../../services/services';
import { FiEdit2 } from 'react-icons/fi';
import { VscDebugDisconnect } from 'react-icons/vsc';
import {
  PlusCircleIcon,
  XCircleIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import SelectDestinationModal from './modals/SelectDestinationModal';
import ConfirmationModal from './modals/ConfirmationModal';

type ConnectorAdministrationProps = {
  connector: ConnectorDetailResponse;
  reloadConnector: () => Promise<void>;
};

const ConnectorAdministration = ({
  connector,
  reloadConnector
}: ConnectorAdministrationProps) => {
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [showNoDestinationsAlert, setShowNoDestinationsAlert] = useState(false);
  const [destinationToBeDetached, setDestinationToBeDetached] = useState<
    number | null
  >(null);
  const [showDestinationSelectModal, setShowDestinationSelectModal] =
    useState(false);
  const navigate = useNavigate();

  const handleDestinationDetach = async () => {
    if (destinationToBeDetached === null) {
      notification('No destination selected', 'warning');
      return;
    }
    try {
      await detachDestination(connector.id, destinationToBeDetached);
      await reloadConnector();
    } catch (e) {
      notification('Failed to detach destination', 'warning');
    }
    setDestinationToBeDetached(null);
    setShowDisconnectModal(false);
  };

  const handleDestinationAttachment = async (destination: { id: number }) => {
    try {
      await attachDestination(connector.id, {
        destination_id: destination.id
      });
      await reloadConnector();
      notification('Destination attached', 'success');
    } catch (e) {
      notification('Failed to attach destination', 'warning');
    }
  };

  return (
    <>
      <div className="flex md:items-center md:justify-between px-4 py-6 rounded-lg">
        <div className="min-w-0 flex-1 self-center">
          <h1 className="text-sm sm:text-xl text-gray-500 font-bold sm:truncate sm:tracking-tight">
            Active destinations for this connector
          </h1>
        </div>
        <div className="flex">
          <button
            type="button"
            className="btnMovinglake w-fit"
            onClick={() => setShowDestinationSelectModal(true)}
          >
            <PlusCircleIcon className="h-6 w-6 mr-1" />
            Attach Destination
          </button>
        </div>
      </div>

      {showNoDestinationsAlert && (
        <div className="rounded-md bg-red-100 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">
                Cannot have less than one destination
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-red-100 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                  onClick={() => setShowNoDestinationsAlert(false)}
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ul className="divide-y divide-gray-100 mt-3">
        {connector.targets.map((destination) => (
          <li
            key={destination.destination_id}
            className="relative py-4 hover:bg-gray-50"
          >
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
                <div className="flex min-w-0 gap-x-4">
                  <img
                    className="h-16 w-16 flex-none rounded-full bg-gray-50"
                    src={`/logo512.png`}
                    alt=""
                  />
                  <div className="min-w-0 flex-auto">
                    <p className="text-lg font-semibold leading-6 text-gray-900">
                      <span className="absolute -top-px bottom-0" />
                      {destination.destination_name}
                    </p>
                    <p className="mt-1 flex text-xs leading-5 text-gray-500">
                      {destination.destination_type_name}
                    </p>
                  </div>
                </div>
                <div className="flex shrink-0 items-center gap-x-4">
                  <FiEdit2
                    size={22}
                    onClick={() => {
                      navigate(
                        Path.EditDestinationForm.replace(
                          ':id',
                          destination.destination_id.toString()
                        )
                      );
                    }}
                  />
                  <VscDebugDisconnect
                    size={22}
                    onClick={() => {
                      if (connector.targets.length === 1) {
                        setShowNoDestinationsAlert(true);
                        return;
                      }
                      setShowDisconnectModal(true);
                      setDestinationToBeDetached(destination.destination_id);
                    }}
                  />
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      <SelectDestinationModal
        show={showDestinationSelectModal}
        onClose={() => setShowDestinationSelectModal(false)}
        onSelected={handleDestinationAttachment}
        onCancel={() => null}
        confirmButtonText="Attach"
        cancelButtonText="Cancel"
        title="Choose a destination to attach"
        onLoaded={() => null}
      />
      <ConfirmationModal
        show={showDisconnectModal}
        title="Disconnect Destination"
        description="Are you sure you want to proceed?"
        onConfirm={handleDestinationDetach}
        onCancel={() => {
          setShowDisconnectModal(false);
          setDestinationToBeDetached(null);
        }}
      />
    </>
  );
};

export default ConnectorAdministration;
