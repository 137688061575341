import { NOTIFICATION_TYPE, Store } from 'react-notifications-component';
import {
  AcceptInviteRequest,
  AccountSummaryResponse,
  AiQueryResponse,
  BillingDetailsResponse,
  BillingHistoryResponse,
  BillingPortalResponse,
  BillingSubscriptionGetResponse,
  ChangePasswordRequest,
  ConnectorCategoriesResponse,
  ConnectorCreateResponse,
  ConnectorDetailResponse,
  ConnectorsListResponse,
  ConnectorTemplateListResponse,
  ConnectorTemplateResponse,
  CreateUDFProjectRequest,
  DestinationListed,
  DestinationPersistenceRequest,
  DestinationPersistenceResponse,
  DestinationResponse,
  DestinationsResponse,
  DestinationTemplateResponse,
  DestinationTemplatesResponse,
  GithubOwnersResponse,
  LoginRequest,
  LoginResponse,
  RecoverPasswordRequest,
  ResetPasswordRequest,
  SignupRequest,
  SignupResponse,
  UdfExecutionDetail,
  UdfExecutionsListResponse,
  UdfProjectDetailResponse,
  SqlResponse,
  UsageHistogramResponse,
  SchemaResponse,
  QueryCard,
  ClientAPIKeysListResponse,
  ClientAPIKeysCreateResponse,
  DocumentationStatusResponse,
  SendInviteRequest,
  EditInviteRequest,
  SuscriptionPayload
} from '../types/api';
import api, { destinationsApi } from './api';

const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

export const Login = async (values: LoginRequest): Promise<LoginResponse> => {
  const data = {
    ...values,
    grant_type: 'password',
    client_id: process.env.REACT_APP_CLIENT_ID
  };
  const response = await api.post('/o/token/', data, { headers });
  await api.post('/auth/pending_tokens/', { ...values }, { headers });
  return response.data;
};

export const getAccountSummary = async (): Promise<AccountSummaryResponse> => {
  const response = await api.get('/api/v2/account-summary/');
  return response.data;
};

export const getDocumentationStatus = async (
  url: string
): Promise<DocumentationStatusResponse> => {
  const response = await api.get('/api/v2/doc-status/', {
    params: {
      url: url
    }
  });
  return response.data;
};

export const Signup = async (
  values: SignupRequest
): Promise<SignupResponse> => {
  const payload = { ...values };
  const response = await api.post('/auth/signup/', payload);
  return response.data;
};

export const RecoverPassword = (values: RecoverPasswordRequest) => {
  return api.post('/auth/recover_password/', values);
};

export const changePassword = (values: ChangePasswordRequest, id: number) => {
  return api.post(`/users/${id}/set_password/`, values);
};

export const resetPassword = (values: ResetPasswordRequest) => {
  return api.post('/auth/password_reset/', values);
};

export const sendInvite = async (values: SendInviteRequest) => {
  const data = {
    ...values
  };
  const response = await api.post('/auth/send_invite/', data);
  return response.data;
};

export const reSendInvite = async (id: number) => {
  const data = {
    id: id
  };
  const response = await api.post('/auth/re_send_invite/', data);
  return response.data;
};

export const editOrgUserEmail = async (values: EditInviteRequest) => {
  const data = {
    ...values
  };
  const response = await api.post('/orgusers/edit_email/', data);
  return response.data;
};

export const editInvite = async (values: EditInviteRequest) => {
  const data = {
    ...values
  };
  const response = await api.post('/orgusers/edit_invite/', data);
  return response.data;
};

export const getOrgUsers = async (params: any) => {
  const response = await api.get('/orgusers/org_users/', {
    params
  });
  return response.data;
};

export const getOrgInvites = async (params: any) => {
  const response = await api.get('/orgusers/org_invites/', {
    params
  });
  return response.data;
};

export const deleteOrgUser = async (id: number) => {
  return await api.delete(`/orgusers/${id}/`);
};

export const deleteOrgInvite = async (id: number) => {
  const data = {
    id: id
  };
  return await api.post(`/orgusers/del_invite/`, data);
};

export const acceptInvite = async (values: AcceptInviteRequest) => {
  return await api.post('/auth/accept_invite/', values);
};

export const getConnectorTemplateList =
  async (): Promise<ConnectorTemplateListResponse> => {
    const response = await api.get('/api/v2/connector-templates/');
    return response.data;
  };

export const getCategories = async (): Promise<ConnectorCategoriesResponse> => {
  const response = await api.get('/api/v2/connector-templates/categories/');
  return response.data;
};

export const getConnectorTemplate = async (
  id: number
): Promise<ConnectorTemplateResponse> => {
  const response = await api.get(`/api/v2/connector-templates/${id}/`);
  return response.data;
};

export const getConnectorDetails = async (
  id: number
): Promise<ConnectorDetailResponse> => {
  const response = await api.get(`/api/v2/connectors/${id}/`);
  return response.data;
};

export const getConnectors = async (): Promise<ConnectorsListResponse> => {
  const response = await api.get('/api/v2/connectors/');
  return response.data;
};

export type ConnectorCreateRequest = {
  name: string;
  description: string;
  connector_category_id: number;
  credentials: Record<string, any>;
  token_uid: string;
  targets: {
    destination_id: number;
    entities: {
      id: number;
      timing_configuration:
        | { cron_expression: string }
        | { repeat_every: number };
      is_active: boolean;
      next_time: null | Date;
      url_request: null | string;
    }[];
    custom_params: {};
  }[];
};

export const createConnector = async (
  params: ConnectorCreateRequest
): Promise<ConnectorCreateResponse> => {
  const response = await api.post('/api/v2/connectors/', params);
  return response.data;
};

type ConnectorPatchRequest = {
  name?: string;
  description?: string;
  is_active?: boolean;
  credentials?: Record<string, any>;
  token_uid?: string;
};

export const partialConnectorUpdate = async (
  id: number,
  data: ConnectorPatchRequest
) => {
  return await api.patch(`/api/v2/connectors/${id}/`, data);
};

export const deleteConnector = async (id: number) => {
  return await api.delete(`/api/v2/connectors/${id}/`);
};

export const executeConnectorNow = async (id: number) => {
  return await api.post(`/api/v2/connectors/${id}/execute-now/`);
};

export const setConnectorBackfillDate = async (id: number, date: Date) => {
  return await api.put(`/api/v2/connectors/${id}/backfill-date/`, {
    value: date.toISOString()
  });
};

type DestinationAttachmentsRequest = {
  destination_id: number;
};

export const attachDestination = async (
  id: number,
  data: DestinationAttachmentsRequest
) => {
  return await api.post(`/api/v2/connectors/${id}/destinations/`, data);
};

export const detachDestination = async (id: number, destinationId: number) => {
  return await api.delete(
    `/api/v2/connectors/${id}/destinations/${destinationId}/`
  );
};

type ConnectorEntityUpdateParams = {
  name: string;
  is_active?: boolean;
  target_id?: number;
  next_time?: Date;
  timing_configuration?: {
    cron_expression?: string;
    repeat_every?: number;
  };
};

export const updateConnectorEntity = async (
  id: number,
  params: ConnectorEntityUpdateParams
) => {
  return await api.put(`/api/v2/connectors/${id}/entity/`, params);
};

type OrganizationUpdateParams = {
  name: string;
  org_name: string;
};

export const updateOrganization = async (params: OrganizationUpdateParams) => {
  return await api.put(`/api/v2/connectors/complete-org/`, params);
};

export const updateCustomParams = async (custom_params: any, id: number) => {
  return await api.put(`/api/v2/connectors/set-custom-params/`, {
    custom_params: custom_params,
    id: id
  });
};

export const getUsageHistogram = async (
  connectorId: number,
  startDate: Date,
  endDate: Date,
  bins: number,
  entityId: number | null
): Promise<UsageHistogramResponse> => {
  const response = await api.get('/api/v2/usage/histogram/', {
    params: {
      connector_id: connectorId,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      bins: bins,
      entity_id: entityId
    }
  });
  return response.data;
};

export const getDestinationTemplates =
  async (): Promise<DestinationTemplatesResponse> => {
    const response = await api.get('/api/v2/destination-templates/');
    return response.data;
  };

export const getDestinationTemplate = async (
  id: number
): Promise<DestinationTemplateResponse> => {
  const response = await api.get(`/api/v2/destination-templates/${id}/`);
  return response.data;
};

export const getExternalCredentialsUrl = async (
  id: number,
  value: object
): Promise<{ id: string }> => {
  const values = {
    credentials_json: value ?? {},
    connector_cat: id
  };
  const response = await api.post('/api/v2/auth-flows/', values);
  return response.data;
};

export type ValidateCredentialsRequest = {
  connector_template_id: number;
  credentials: Record<string, any>;
};

export const validateCredentials = async (
  value: ValidateCredentialsRequest
): Promise<boolean> => {
  try {
    await api.post('/api/v2/connectors/validate-credentials/', value);
    return true;
  } catch (e) {
    return false;
  }
};

export const getDestinations = async (): Promise<DestinationsResponse> => {
  const response = await api.get('/api/v2/destinations/');
  return response.data;
};

export const getExistingDestination = async (
  id: number
): Promise<DestinationResponse> => {
  const response = await api.get(`/api/v2/destinations/${id}/`);
  return response.data;
};

export const createDestination = async (
  values: DestinationPersistenceRequest
): Promise<DestinationPersistenceResponse> => {
  const response = await destinationsApi.post('/api/v2/destinations/', values);
  return response.data;
};

export const updateDestination = async (
  id: number,
  values: DestinationPersistenceRequest
): Promise<DestinationPersistenceResponse> => {
  const response = await destinationsApi.put(
    `/api/v2/destinations/${id}/`,
    values
  );
  return response.data;
};

export const deleteDestination = async (id: number) => {
  return await api.delete(`/api/v2/destinations/${id}/`);
};

export const getBillingDetails = async (): Promise<BillingDetailsResponse> => {
  const response = await api.get('/billing/details/');
  return response.data;
};

export const getBillingHistory = async (): Promise<BillingHistoryResponse> => {
  const response = await api.get('/billing/history/');
  return response.data;
};

export const getBillingSubscription =
  async (): Promise<BillingSubscriptionGetResponse> => {
    const response = await api.get('/billing/subscription/');
    return response.data;
  };

export const getBillingPortal = async (): Promise<BillingPortalResponse> => {
  const response = await api.get('/billing/portal/');
  return response.data;
};

export const createSubscription = async (data: SuscriptionPayload) => {
  const response = await api.post('/billing/new_subscription/', data);
  return response.data;
};

export const getGithubOwners = async (): Promise<GithubOwnersResponse> => {
  const response = await api.get('/api/v2/udfs/github_authorized_owners/');
  return response.data;
};

export const createUdfProject = async (values: CreateUDFProjectRequest) => {
  return await api.post('/api/v2/udfs/projects/', values);
};

export const updateUdfProject = async (
  id: number,
  values: CreateUDFProjectRequest
) => {
  return await api.put(`/api/v2/udfs/projects/${id}/`, values);
};

export const getUdfProject = async (
  id: number
): Promise<UdfProjectDetailResponse> => {
  const response = await api.get(`/api/v2/udfs/projects/${id}/`);
  return response.data;
};

export const executeUdfProject = async (
  id: number
): Promise<UdfExecutionDetail> => {
  const response = await api.post(`/api/v2/udfs/projects/${id}/executions/`);
  return response.data;
};

export const getExecutionLogs = async (
  execution: UdfExecutionDetail
): Promise<string> => {
  const response = await api.get(
    `/api/v2/udfs/projects/${execution.project_id}/executions/${execution.id}/logs/`
  );
  return response.data.contents;
};

export const getUdfExecutions = async (
  id: number,
  page?: number
): Promise<UdfExecutionsListResponse> => {
  let path = `/api/v2/udfs/projects/${id}/executions/`;
  if (page !== undefined) {
    path += `?page=${page}`;
  }

  const response = await api.get(path);
  return response.data;
};

export const issueAiQuery = async (
  source: DestinationListed,
  value: string
): Promise<AiQueryResponse> => {
  const params = {
    source: source,
    query: value
  };
  const response = await api.post('/api/v2/ai-query/', params);
  return response.data;
};

export const getSchema = async (
  source: DestinationListed
): Promise<SchemaResponse> => {
  const response = await api.get(`/api/v2/destinations/${source.id}/schema/`);
  return response.data;
};

export const getDataPreview = async (
  source: DestinationListed,
  tableName: string
): Promise<SqlResponse> => {
  const response = await api.get(
    `/api/v2/destinations/${source.id}/preview/${tableName}/`
  );
  return response.data;
};

export const issueSqlQuery = async (
  source: DestinationListed,
  value: string
): Promise<SqlResponse> => {
  const params = {
    source: source,
    query: value
  };
  const response = await api.post('/api/v2/sql-query/', params);
  return response.data;
};

export const saveQueryCard = async (query: QueryCard): Promise<QueryCard> => {
  const response = await api.post('/api/v2/queries/', query);
  return response.data;
};

export const loadQueryCards = async (): Promise<QueryCard[]> => {
  const response = await api.get('/api/v2/queries/');
  return response.data;
};

export const createClientAPIKey =
  async (): Promise<ClientAPIKeysCreateResponse> => {
    const response = await api.post('/api/v2/applications/');
    return response.data;
  };

export const getClientAPIKeys =
  async (): Promise<ClientAPIKeysListResponse> => {
    const response = await api.get('/api/v2/applications/');
    return response.data;
  };

export const deleteClientAPIKey = async (id: number) => {
  return await api.delete(`/api/v2/applications/${id}/`);
};

export const notification = (
  message: string,
  type: NOTIFICATION_TYPE | undefined
) => {
  Store.addNotification({
    message: message,
    type: type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 2000,
      onScreen: true
    }
  });
};
