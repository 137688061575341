import { DestinationState } from '../types/state';
import { DestinationResponse } from '../types/api';

export enum DestinationActionType {
  DESTINATION_TYPE = 'DESTINATION_TYPE',
  EXISTING_DESTINATION = 'EXISTING_DESTINATION',
  DESTINATION_TEMPLATE = 'DESTINATION_TEMPLATE',
  DESTINATION_FORM_CREDENTIAL_VALUES = 'DESTINATION_FORM_CREDENTIAL_VALUES',
  DESTINATION_NAME = 'DESTINATION_NAME',
  RESET_FORM = 'RESET_FORM'
}

export type DestinationAction = {
  type: DestinationActionType;
  payload: any;
};

const initialState = {
  template: null,
  existingDestination: null,
  destinationFormValues: {
    templateId: 0,
    name: '',
    credentials: {}
  }
};

const Destination = (
  state: DestinationState = initialState,
  action: DestinationAction
) => {
  switch (action.type) {
    case DestinationActionType.EXISTING_DESTINATION:
      const destination = action.payload as DestinationResponse;
      return {
        ...state,
        existingDestination: destination,
        destinationFormValues: {
          ...state.destinationFormValues,
          name: destination.name,
          credentials: {
            ...state.destinationFormValues.credentials,
            ...destination.credential_json
          }
        }
      };
    case DestinationActionType.DESTINATION_TEMPLATE:
      return {
        ...state,
        template: action.payload,
        destinationFormValues: {
          ...state.destinationFormValues,
          credentials: {},
          templateId: action.payload.id
        }
      };
    case DestinationActionType.DESTINATION_TYPE:
      return {
        ...state,
        template: null,
        destinationFormValues: {
          ...state.destinationFormValues,
          templateId: action.payload.id
        }
      };
    case DestinationActionType.DESTINATION_FORM_CREDENTIAL_VALUES:
      return {
        ...state,
        destinationFormValues: {
          ...state.destinationFormValues,
          credentials: {
            ...state.destinationFormValues.credentials,
            ...action.payload
          }
        }
      };
    case DestinationActionType.DESTINATION_NAME:
      return {
        ...state,
        destinationFormValues: {
          ...state.destinationFormValues,
          name: action.payload
        }
      };
    case DestinationActionType.RESET_FORM:
      return initialState;
    default:
      return state;
  }
};

export default Destination;
