import React from 'react';

const Loader = () => {
  return (
    <div className="wrapper-loder">
      <svg className="w-fit h-fit">
        <image href="/images/loader2.svg" className="w-full h-full" />
      </svg>
    </div>
  );
};

export default Loader;
