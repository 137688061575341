import React, { useEffect, useState } from 'react';
import {
  formatDuration,
  formatRelative,
  intervalToDuration,
  parseISO
} from 'date-fns';
import { useParams } from 'react-router-dom';
import {
  executeUdfProject,
  getExecutionLogs,
  getUdfExecutions,
  getUdfProject,
  notification
} from '../../services/services';
import {
  UdfExecutionDetail,
  UdfExecutionsListResponse,
  UdfProjectDetailResponse
} from '../../types/api';
import { Tooltip, Whisper } from 'rsuite';
import cronstrue from 'cronstrue';
import { BsDownload } from 'react-icons/bs';
import { RiGitRepositoryCommitsFill } from 'react-icons/ri';
import { BiGitBranch } from 'react-icons/bi';
import PaginationC from '../common/PaginationC';
import { FaPlay } from 'react-icons/fa';

type Status = {
  [key: string]: string;
  CREATING: string;
  PENDING: string;
  CREATED: string;
  FAILED: string;
};

type Statuses = {
  [key: string]: string;
  success: string;
  failure: string;
  cancelled: string;
  running: string;
};

const statusDTB: Status = {
  CREATING: 'The DBT project is being created',
  PENDING: 'The DBT project is being created',
  CREATED: 'The DBT has been created',
  FAILED: 'The DBT has failed'
};

const statuses: Statuses = {
  success: 'text-green-700 bg-green-50 ring-green-600/20',
  running: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  cancelled: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  failure: 'text-red-700 bg-red-50 ring-red-600/20'
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const DbtDetails = () => {
  const params = useParams();
  const [projectDetails, setProjectDetails] =
    useState<UdfProjectDetailResponse | null>(null);
  const [executions, setExecutions] =
    useState<UdfExecutionsListResponse | null>(null);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    const projectId = parseInt(params['id'] || '');
    if (projectDetails || !projectId) {
      return;
    }

    (async () => {
      try {
        const response = await getUdfProject(projectId);
        setProjectDetails(response);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [params, projectDetails]);

  useEffect(() => {
    const projectId = parseInt(params['id'] || '');
    if (!projectId) {
      return;
    }

    (async () => {
      try {
        const response = await getUdfExecutions(projectId, activePage);
        setExecutions(response);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [params, activePage]);

  const handleLogsDownload = async (execution: UdfExecutionDetail) => {
    const content = await getExecutionLogs(execution);
    const link = document.createElement('a');
    link.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(content);
    link.download = execution.log_reference || 'logs.txt';
    link.click();
    document.removeChild(link);
  };

  const repositoryLink = () => {
    if (!projectDetails) {
      return <></>;
    }
    const parts = projectDetails.github_repo.split('/');
    const login = parts[parts.length - 2];
    const name = parts[parts.length - 1];

    return (
      <a
        href={`https://github.com/${login}/${name}`}
        target="_blank"
        rel="noreferrer"
      >
        {name}
      </a>
    );
  };

  const branchLink = () => {
    if (!projectDetails) {
      return <></>;
    }
    const parts = projectDetails.github_repo.split('/');
    const login = parts[parts.length - 2];
    const name = parts[parts.length - 1];

    return (
      <a
        href={`https://github.com/${login}/${name}/tree/${projectDetails.github_branch}`}
        target="_blank"
        rel="noreferrer"
      >
        {projectDetails.github_branch}
      </a>
    );
  };

  const commitLink = (execution: UdfExecutionDetail) => {
    if (!execution.commit_hash || !projectDetails) {
      return <></>;
    }
    const parts = projectDetails.github_repo.split('/');
    const login = parts[parts.length - 2];
    const name = parts[parts.length - 1];

    return (
      <a
        href={`https://github.com/${login}/${name}/commit/${execution.commit_hash}`}
        target="_blank"
        rel="noreferrer"
        className="ml-3"
      >
        {execution.commit_hash.slice(0, 7)}
      </a>
    );
  };

  const duration = (execution: UdfExecutionDetail): string => {
    if (!execution.end_time) {
      return 'N/A';
    }
    const duration = intervalToDuration({
      start: parseISO(execution.start_time.toString()),
      end: parseISO(execution.end_time.toString())
    });
    return formatDuration(duration, {
      format: ['hours', 'minutes'],
      zero: true
    });
  };

  const executeNow = async () => {
    if (!projectDetails || !executions) {
      return;
    }
    try {
      const execution = await executeUdfProject(projectDetails.id);
      setExecutions({
        ...executions,
        results: [execution, ...executions.results]
      });
      notification('Execution requested', 'success');
    } catch (e) {
      notification(`Failed to start execution ${e}`, 'warning');
    }
  };

  if (!projectDetails || !executions) {
    return <></>;
  }

  return (
    <>
      <div className="px-4 py-6">
        <div className="md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex self-center">
            <img
              className="h-12 w-12 flex-none"
              src={`/images/gitlogo.png`}
              alt=""
            />
            <h1 className="ml-2 self-center text-xl md:text-3xl text-gray-500 font-bold sm:truncate sm:tracking-tight">
              {projectDetails.name.replace(/[_-]/g, ' ')}
            </h1>
            <Whisper
              followCursor
              speaker={
                <Tooltip>
                  {statusDTB[projectDetails.lifecycle.toUpperCase()]}
                </Tooltip>
              }
            >
              <div className="ml-5 mt-1 flex items-center gap-x-1.5">
                <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                  <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                </div>
                <p className="text-xs leading-5 text-gray-500">
                  {projectDetails.lifecycle.toUpperCase()}
                </p>
              </div>
            </Whisper>
          </div>
          <div className="mt-3 grid grid-cols-1 md:grid-cols-2 gap-1 md:flex md:gap-0 md:mt-0">
            <button
              type="button"
              className={`bg-black text-white hover:bg-gray-600 inline-flex items-center md:rounded px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10`}
              onClick={executeNow}
            >
              <FaPlay className="h-4 w-4 mr-1" /> Run now
            </button>
          </div>
        </div>
        <div className="mt-3 grid grid-cols-1">
          <div className="col-span-1">
            <p className="md:text-base">
              <b>Scheduling:</b>{' '}
              <span>
                {cronstrue.toString(projectDetails.run_configuration['all']) +
                  ' UTC'}
              </span>
            </p>
            <div className="flex gap-5 mt-3">
              <p className="flex gap-2 self-center md:text-base">
                <b className="flex items-center">
                  <RiGitRepositoryCommitsFill /> Respository:
                </b>
                {repositoryLink()}
              </p>
              <p className="flex gap-2 self-center mt-0 md:text-base">
                <b className="flex items-center">
                  <BiGitBranch /> Branch:
                </b>
                {branchLink()}
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr className="h-px my-0 mb-1 bg-gray-200 border-0" />
      <ul className="divide-y divide-gray-100">
        {executions.results.map((execution: any, key: number) => (
          <li
            key={execution.id}
            className="flex items-center justify-between gap-x-6 py-5"
          >
            <div className="min-w-0">
              <div className="flex items-start gap-x-3">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  ID {execution.id} {commitLink(execution)}
                </p>
                <p
                  className={classNames(
                    statuses[execution.outcome.toLowerCase()],
                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                  )}
                >
                  {execution.outcome.toLowerCase()}
                </p>
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap">
                  Started{' '}
                  {formatRelative(
                    parseISO(execution.start_time.toString()),
                    new Date()
                  )}
                </p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="truncate">Duration {duration(execution)}</p>
              </div>
            </div>
            <div className="flex flex-none items-center gap-x-4">
              <button
                onClick={() => handleLogsDownload(execution)}
                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              >
                <span className="flex gap-2">
                  <BsDownload size={18} />
                  Logs
                </span>
              </button>
            </div>
          </li>
        ))}
      </ul>
      <PaginationC
        activePage={activePage}
        itemsCountPerPage={6}
        totalItemsCount={executions.count}
        pageRangeDisplayed={5}
        onPageChange={(pageNumber: number) => {
          setActivePage(pageNumber);
        }}
      />
    </>
  );
};

export default DbtDetails;
