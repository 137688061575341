/*
import React, { useEffect, useState } from 'react';
import {
  issueAiQuery,
  issueSqlQuery,
  notification,
  saveQueryCard
} from '../../services/services';
import {
  AiQueryResponse,
  SqlResponseGraphData,
  SqlResponse,
  DestinationListed,
  QueryCard as QueryCardType
} from '../../types/api';
import { Mixpanel } from '../common/Mixpanel';
/*
import {
  MdTextSnippet,
  MdOutlineCode,
  MdTableRows,
  MdOutlineAreaChart,
  MdOutlineDataSaverOn
} from 'react-icons/md';
import Chart from './ChartType';
import CodeEditor from '@uiw/react-textarea-code-editor';


interface QueryCardProps {
  destination: DestinationListed | undefined;
  cardToLoad: QueryCardType | null;
}

const QueryCard: React.FC<QueryCardProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [question, setQuestion] = useState<string | undefined>(undefined);
  const [sql, setSql] = useState<string | undefined>(undefined);
  const [data, setData] = useState<SqlResponseGraphData | undefined>(undefined);
  const [graphType, setGraphType] = useState<string | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState<string>('sql');
  const [sqlError, setSqlError] = useState<string | undefined>(undefined);
  const [queryNamespace, setQueryNamespace] = useState<string>('');
  const [queryName, setQueryName] = useState<string>('');
  const [queryDescription, setQueryDescription] = useState<string>('');
  const [selectedXAxis, setSelectedXAxis] = useState<number>(0);
  const [queryCardSaved, setQueryCardSaved] = useState<boolean>(false);

  useEffect(() => {
    setQueryCardSaved(false);
  }, [question, sql, graphType, queryName, queryDescription, queryNamespace]);

  useEffect(() => {
    if (props.cardToLoad) {
      setSql(props.cardToLoad.query);
      setQueryNamespace(props.cardToLoad.namespace);
      setQueryName(props.cardToLoad.name);
      setQueryDescription(props.cardToLoad.description);
      setQuestion(props.cardToLoad.question);
      setGraphType(props.cardToLoad.chart_type);
      setSelectedXAxis(props.cardToLoad.x_axis_index);
      handleQuery(props.cardToLoad.query);
    }
  }, [props.cardToLoad]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCodeEditorKeyDown = (e: any) => {
    if ((e.keyCode === 10 || e.keyCode === 13) && (e.ctrlKey || e.metaKey)) {
      Mixpanel.track('Queries - Ctrl Enter Submit');
      handleQuery('');
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  const handleSaveQueryCard = () => {
    let queryCard: QueryCardType = {
      id: null,
      organization_id: null,
      created_at: null,
      updated_at: null,
      name: queryName,
      description: queryDescription,
      destination: props.destination?.id || 0,
      namespace: queryNamespace,
      question: question || '',
      query: sql || '',
      chart_type: graphType || '',
      x_axis_index: selectedXAxis
    };
    saveQueryCard(queryCard).then(
      (response) => {
        notification('Query card saved successfully', 'success');
        setQueryCardSaved(true);
      },
      (error) => {
        notification(
          'There was an error while saving the query card ' +
            JSON.stringify(error),
          'danger'
        );
      }
    );
  };

  const handleQuestion = async () => {
    if (!props.destination) {
      notification('Please choose a destination', 'warning');
      return;
    }
    if (!question) {
      notification('Please write a question', 'warning');
      return;
    }
    Mixpanel.track('Queries - AI Insights Question Submit', {
      is_click: true,
      destination: props.destination.name
    });
    setSql(undefined);
    setData(undefined);
    setLoading(true);
    let response: AiQueryResponse;
    try {
      response = await issueAiQuery(props.destination, question);
    } catch (error: any) {
      if (error.response.data && error.response.data.reason) {
        Mixpanel.track('Queries - AI Insights Question Error', {
          is_error: true,
          destination: props.destination.name
        });
        setSqlError(JSON.stringify(error.response.data.reason));
        setSql(error.response.data.query || '');
      }
      Mixpanel.track('Queries - AI Insights Question Error', {
        is_error: true,
        destination: props.destination.name
      });
      setLoading(false);
      notification('There was an error while querying the database', 'danger');
      return;
    }
    setLoading(false);
    notification('Success!', 'success');
    Mixpanel.track('Queries - AI Insights Question Submit Successful', {
      is_success: true,
      destination: props.destination.name
    });
    if (response && response.results) {
      setGraphType(response.chart_type || 'line');
      setSql(response.ai_query || '');
      setData(response.results);
      setSelectedTab('sql');
    }
  };

  const handleQuery = async (query: string) => {
    query = query || sql || '';
    setSqlError('');
    if (!props.destination) {
      notification('Please choose a destination', 'warning');
      return;
    }
    if (!query) {
      notification('Please write a SQL query', 'warning');
      return;
    }
    Mixpanel.track('Queries - AI Insights Question Submit', {
      is_click: true,
      destination: props.destination.name
    });
    setQuestion(undefined);
    setData(undefined);
    setLoading(true);
    let response: SqlResponse;
    try {
      response = await issueSqlQuery(props.destination, query);
    } catch (error: any) {
      setSqlError(JSON.stringify(error.response.data));
      notification(
        'SQL query failed ' + JSON.stringify(error.response.data),
        'danger'
      );
      setLoading(false);
      return;
    }
    setLoading(false);
    notification('Success!', 'success');
    Mixpanel.track('Queries - AI Insights Query Submit Successful', {
      is_success: true,
      destination: props.destination.name
    });
    if (response && response.results) {
      setData(response.results);
    }
  };

  return (
    <>
    <h1>query card</h1>
    {/*
    <div>
      {loading && (
        <div className="my-4">
          <Spinner className="mr-2" animation="border" role="status"></Spinner>
          <span className="sr-only">
            Loading... this can take upto 30 seconds
          </span>
        </div>
      )}
      <Row>
        <Col className="mb-2">
          <h4>Query Card</h4>
        </Col>
      </Row>
      <Tabs
        defaultActiveKey={selectedTab}
        id="fill-tab-example"
        className="mb-3"
        fill
        onSelect={(k) => {
          setSelectedTab(k || '');
        }}
        activeKey={selectedTab}
      >
        <Tab
          eventKey="sql"
          title={
            <span title="SQL Query">
              <MdOutlineCode />
              <br />
              SQL Query
            </span>
          }
        ></Tab>
        <Tab
          eventKey="question"
          title={
            <span title="Question">
              <MdTextSnippet />
              <br />
              Question
            </span>
          }
        >
          <Form.Control
            as="textarea"
            rows={10}
            value={question}
            placeholder="Ask a question about your data"
            onChange={(e) => setQuestion(e.target.value)}
          />
          <Button variant="primary" onClick={handleQuestion} className="my-4">
            Submit question
          </Button>
        </Tab>
        <Tab
          eventKey="data"
          title={
            <span title="Data Table">
              <MdTableRows />
              <br />
              Data Table
            </span>
          }
        >
          {data && (
            <Row>
              <Col style={{ overflow: 'scroll' }}>
                {
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        {data.columns.map((column) => (
                          <th key={column}>{column}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data.rows.map((row) => (
                        <tr>
                          {row.map((column) => (
                            <td>{column}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
              </Col>
            </Row>
          )}
        </Tab>
        <Tab
          eventKey="graph"
          title={
            <span title="Graph">
              <MdOutlineAreaChart />
              <br />
              Graph
            </span>
          }
        >
          {data && graphType && (
            <Row>
              <Col>
                <Chart
                  data={data}
                  chartType={graphType}
                  xAxisIndex={selectedXAxis}
                  setChartType={setGraphType}
                  setXAxis={setSelectedXAxis}
                />
              </Col>
            </Row>
          )}
        </Tab>
        <Tab
          eventKey="save"
          title={
            <span title={queryCardSaved ? 'Saved' : 'Unsaved'}>
              <MdOutlineDataSaverOn />
              <br />
              {queryCardSaved ? 'Saved' : 'Unsaved'}
            </span>
          }
        >
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Query Folder</Form.Label>
              <Form.Control
                type="text"
                placeholder="Query Folder..."
                value={queryNamespace}
                onChange={(e) => {
                  setQueryNamespace(e.target.value);
                }}
              />
              <Form.Label>Query Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Query Name..."
                value={queryName}
                onChange={(e) => {
                  setQueryName(e.target.value);
                }}
              />
              <Form.Label>Query Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Query Description"
                value={queryDescription}
                onChange={(e) => {
                  setQueryDescription(e.target.value);
                }}
              />
            </Form.Group>

            <Button
              variant="primary"
              type="button"
              onClick={handleSaveQueryCard}
            >
              Save
            </Button>
          </Form>
        </Tab>
      </Tabs>
      <div
        id="editor"
        style={{ display: selectedTab === 'sql' ? 'block' : 'none' }}
      >
        <CodeEditor
          value={sql}
          language="sql"
          placeholder="Please enter your SQL code."
          onChange={(evn) => setSql(evn.target.value)}
          onKeyDown={handleCodeEditorKeyDown}
          padding={15}
          style={{
            fontSize: 12,
            backgroundColor: '#f5f5f5',
            fontFamily:
              'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace'
          }}
        />
        <div className="text-danger">{sqlError}</div>
        <Button
          variant="primary"
          onClick={() => {
            handleQuery('');
          }}
          className="my-4"
        >
          Submit Query
        </Button>
      </div>
    </div>
    }
    </>
  );
};
export default QueryCard;

*/
import React from 'react';

const QueryCard = () => {
  return <div>QueryCard</div>;
};

export default QueryCard;
