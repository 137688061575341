import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { DestinationListed } from '../../../types/api';
import {
  getDestinationTemplates,
  getDestinations
} from '../../../services/services';
import { SelectPicker } from 'rsuite';

type SelectedDestination = {
  id: number;
  name: string;
  type: string;
};

type ExistingDestinationSelectorProps = {
  show: boolean;
  onClose: () => void;
  onSelected: (destination: SelectedDestination) => void;
  onCancel: () => void;
  onLoaded: (loaded: boolean) => void;
  confirmButtonText: string;
  cancelButtonText: string;
  title: string;
};

const SelectDestinationModal = ({
  show,
  onClose,
  onSelected,
  onCancel,
  confirmButtonText,
  cancelButtonText,
  title,
  onLoaded
}: ExistingDestinationSelectorProps) => {
  const cancelButtonRef = useRef(null);
  const [destinationTypes, setDestinationTypes] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedDestinationType, setSelectedDestinationType] =
    useState<string>('');
  const [destinations, setDestinations] = useState<DestinationListed[]>([]);
  const [selectedDestinationId, setSelectedDestinationId] = useState<
    number | null
  >(null);
  const [shownDestinationsForSelect, setShownDestinationsForSelect] = useState<
    DestinationListed[]
  >([]);

  useEffect(() => {
    if (destinations.length > 0) {
      return;
    }

    (async () => {
      try {
        const response = await getDestinations();
        setDestinations(response);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [destinations.length]);

  useEffect(() => {
    if (destinationTypes.length > 0) {
      return;
    }
    (async () => {
      try {
        const response = await getDestinationTemplates();
        setDestinationTypes(
          response.map((destination) => {
            return { label: destination.name, value: destination.name };
          })
        );
      } catch (e) {
        console.error(e);
      }
    })();
  }, [destinationTypes.length]);

  const handleDestinationTypeSelect = (value: string | null) => {
    if (value === null) {
      return;
    }
    setSelectedDestinationType(value);
    setShownDestinationsForSelect(
      destinations.filter((destination) => {
        return destination.type_adapter_name === value;
      })
    );
  };

  useEffect(() => {
    if (destinations.length > 0) onLoaded(true);
  }, [destinations, onLoaded]);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => onClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-0 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center rounded-full">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 ml-3"
                    >
                      {title}
                    </Dialog.Title>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <div className="mt-2">
                      <div>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <SelectPicker
                            data={destinationTypes}
                            placeholder="Pick a destination type"
                            menuStyle={{ zIndex: 9999 }}
                            value={selectedDestinationType}
                            onChange={handleDestinationTypeSelect}
                            className="w-full"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <div className="mt-2">
                      <div className="d-flex justify-content-center mt-4">
                        {selectedDestinationType !== '' &&
                          shownDestinationsForSelect.length === 0 && (
                            <p className="text-danger">
                              No {selectedDestinationType} destinations
                              available
                            </p>
                          )}
                        {selectedDestinationType !== '' &&
                          shownDestinationsForSelect.length > 0 && (
                            <SelectPicker
                              data={shownDestinationsForSelect.map(
                                (destination) => ({
                                  label: destination.name,
                                  value: destination.id
                                })
                              )}
                              placeholder={`Pick a ${selectedDestinationType} destination`}
                              onChange={setSelectedDestinationId}
                              menuStyle={{ zIndex: 9999 }}
                              className="w-full"
                              value={selectedDestinationId}
                            />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 gap-y-3">
                  {selectedDestinationType !== '' && selectedDestinationId && (
                    <button
                      onClick={() => {
                        const destination = destinations.find(
                          (destination) =>
                            destination.id === selectedDestinationId
                        );
                        if (!destination) {
                          onClose();
                          return;
                        }
                        onSelected({
                          id: destination.id,
                          name: destination.name,
                          type: destination.type_adapter_name
                        });
                        onClose();
                      }}
                      type="submit"
                      className="modalBtnMovinglake"
                    >
                      {confirmButtonText}
                    </button>
                  )}

                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => {
                      onClose();
                      onClose();
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SelectDestinationModal;
