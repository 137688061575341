//@ts-ignore
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '');
let env_check = process.env.REACT_APP_MIXPANEL_TOKEN ? true : false;
console.log('env_check', env_check);

let actions = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name: string, props?: { [key: string]: any }) => {
    if (env_check) {
      mixpanel.track(name, props);
    }
  },
  people: {
    set: (props: { [key: string]: any }) => {
      if (env_check) mixpanel.people.set(props);
    },
    increment: (props: string, count?: number) => {
      if (env_check) mixpanel.people.set(props, count);
    }
  }
};

export let Mixpanel = actions;
