import { Dialog, Transition } from '@headlessui/react';
import { Field, Form, Formik } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { notification } from '../../../services/services';

type CompleteOrganizationProps = {
  show: boolean;
  newAPIKey: any;
  onClose: () => void;
};

const ApiKeyModal = ({
  show,
  newAPIKey,
  onClose
}: CompleteOrganizationProps) => {
  const [open, setOpen] = useState(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const cancelButtonRef = useRef(null);

  const handleCopyClientId = () => {
    if (newAPIKey === undefined) return;
    navigator.clipboard
      .writeText(newAPIKey?.client_id)
      .then((_) => notification('Client ID copied', 'success'))
      .catch((_) => notification('Client ID not copied', 'warning'));
  };

  const handleCopyClientSecret = () => {
    if (newAPIKey === undefined) return;
    navigator.clipboard
      .writeText(newAPIKey?.client_secret)
      .then((_) => notification('Client secret copied', 'success'))
      .catch((_) => notification('Client secret not copied', 'warning'));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-0 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <Formik
                  initialValues={{ client_id: '', client_secret: '' }}
                  onSubmit={(_) => {}}
                >
                  <Form>
                    <div>
                      <div className="mx-auto rounded-full">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900 mb-3"
                        >
                          New API key created
                        </Dialog.Title>
                        <p>
                          Your client secret will be visible only once after
                          creation. Please copy your client secret and keep it
                          safe.
                        </p>
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <div className="mt-2">
                          <div>
                            <div className="relative mt-2 rounded-md shadow-sm">
                              <div className="flex mt-2">
                                <Field
                                  type="text"
                                  name="client_id"
                                  className={`peer block w-full border-0 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6`}
                                  autoComplete="off"
                                  value={newAPIKey?.client_id}
                                  readOnly
                                />
                                <button
                                  title="Copy to clipboard"
                                  className="btnMovinglake"
                                  onClick={handleCopyClientId}
                                >
                                  Copy
                                </button>
                              </div>
                            </div>
                            <div className="relative mt-2 rounded-md shadow-sm">
                              <div className="flex mt-2">
                                <Field
                                  type="text"
                                  name="client_secret"
                                  className={`peer block w-full border-0 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6`}
                                  autoComplete="off"
                                  value={newAPIKey?.client_secret}
                                  readOnly
                                />
                                <button
                                  title="Copy to clipboard"
                                  className="btnMovinglake"
                                  onClick={handleCopyClientSecret}
                                >
                                  Copy
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3 gap-y-3">
                      <button
                        type="submit"
                        className="modalBtnMovinglake"
                        onClick={onClose}
                      >
                        OK
                      </button>
                    </div>
                  </Form>
                </Formik>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ApiKeyModal;
