import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ConnectorListed, ConnectorsListResponse } from '../../types/api';
import { getConnectors } from '../../services/services';
import Path from '../../Path';
import { Mixpanel } from '../../components/common/Mixpanel';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { Popover, Whisper } from 'rsuite';
import EmptyData from '../common/EmptyData';
import { GrConnectivity } from 'react-icons/gr';
import Loader from '../common/Loader';

type connectorTab = 'usage' | 'execution' | 'management';

const connectorPath = (
  connector: ConnectorListed,
  tab?: connectorTab
): string => {
  const base = Path.ShowConnector.replace(':id', connector.id.toString());
  if (tab) {
    return `${base}?tab=${tab}`;
  } else {
    return base;
  }
};

type Status = {
  [key: string]: string;
  CREATION_STARTED: string;
  DISABLED: string;
  RUNNING: string;
};

const status: Status = {
  CREATION_STARTED: 'Your connector is preparing for data extraction',
  DISABLED: 'Make sure you have validated your email address',
  RUNNING: 'Your connector is extracting data'
};

const statusColor1: Status = {
  CREATION_STARTED: 'bg-blue-500/20',
  DISABLED: 'bg-yellow-500/20',
  RUNNING: 'bg-emerald-500/20'
};

const statusColor2: Status = {
  CREATION_STARTED: 'bg-blue-500',
  DISABLED: 'bg-yellow-500',
  RUNNING: 'bg-emerald-500'
};

const ConnectorList = () => {
  const navigate = useNavigate();
  const [connectors, setConnectors] = useState<ConnectorsListResponse>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) {
      return;
    }
    Mixpanel.track('Dashboard - Root Connector List', { is_page_view: true });

    (async () => {
      try {
        const response = await getConnectors();
        setConnectors(response);
        setLoaded(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [loaded, connectors]);

  const onNewConnectorClick = () => {
    navigate('/choose-source');
  };

  return (
    <>
      {loaded ? (
        <>
          <div className="flex md:items-center md:justify-between px-4 py-6 rounded-lg">
            <div className="min-w-0 flex-1 self-center">
              <h1 className="text-xl md:text-3xl text-gray-500 font-bold sm:truncate sm:tracking-tight">
                Connectors
              </h1>
            </div>
            <div className="flex">
              <button
                type="button"
                className="btnMovinglake w-fit"
                onClick={() => {
                  navigate('/choose-source');
                }}
              >
                <PlusCircleIcon className="h-5 w-5 mr-1" />
                New Source
              </button>
            </div>
          </div>
          <hr className="h-px my-3 mb-6 bg-gray-200 border-0" />
          <ul className="divide-y divide-gray-100 mt-3">
            {connectors.length > 0 &&
              connectors.map((connector) => (
                <li
                  key={connector.id}
                  className="relative py-4 hover:bg-gray-50 animateList"
                >
                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
                      <div className="flex min-w-0 gap-x-4">
                        <img
                          className="h-16 w-16 flex-none bg-gray-50"
                          src={`${process.env.REACT_APP_API_URL}/static/media/${connector.icon}`}
                          alt=""
                        />
                        <div className="min-w-0 flex-auto">
                          <p className="text-lg font-semibold leading-6 text-gray-900">
                            <span className="absolute -top-px bottom-0" />
                            {connector.name}
                          </p>
                          <p className="mt-1 flex text-xs leading-5 text-gray-500">
                            Created by {connector.user}
                          </p>
                        </div>
                      </div>
                      <div className="flex shrink-0 items-center gap-x-4">
                        <div className="hidden sm:flex sm:flex-col sm:items-end">
                          <p className="text-sm leading-6 text-gray-900">
                            {connector.connector_cat_name} source
                          </p>
                          <p className="text-sm leading-6 text-gray-900 mt-0">
                            ID: {connector.id}
                          </p>
                          <Whisper
                            followCursor
                            speaker={
                              <Popover>
                                {status[connector.lifecycle_status]}
                              </Popover>
                            }
                          >
                            <div className="mt-1 flex items-center gap-x-1.5">
                              <div
                                className={`flex-none rounded-full ${
                                  statusColor1[connector.lifecycle_status]
                                } p-1`}
                              >
                                <div
                                  className={`h-1.5 w-1.5 rounded-full ${
                                    statusColor2[connector.lifecycle_status]
                                  }`}
                                />
                              </div>
                              <p className="text-xs leading-5 text-gray-500">
                                {connector.lifecycle_status}
                              </p>
                            </div>
                          </Whisper>
                        </div>
                        <Link to={connectorPath(connector)}>
                          <ChevronRightIcon
                            className="h-5 w-5 flex-none text-gray-400"
                            aria-hidden="true"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              ))}

            {connectors.length === 0 && (
              <EmptyData
                onNewClicked={onNewConnectorClick}
                Icon={GrConnectivity}
                title="No connectors yet"
                description="Create a new connector to start extracting data"
              />
            )}
          </ul>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ConnectorList;
