import { useEffect } from 'react';

export default function AmazonSPLogin() {
  useEffect(() => {
    window.location.href =
      'https://connector.production.movinglake.com/amazon-sp-login/' +
      window.location.search;
  });
  return null;
}
