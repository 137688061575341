import { AppState } from '../types/state';
import { setAccountData } from '../services/account';
import { AccountSummaryResponse } from '../types/api';

export enum AppActionType {
  APP_LOAD = 'APP_LOAD',
  LOGOUT = 'LOGOUT',
  ADD_DESTINATION = 'ADD_DESTINATION',
  REMOVE_DESTINATION = 'REMOVE_DESTINATION',
  ADD_CONNECTOR = 'ADD_CONNECTOR',
  REMOVE_CONNECTOR = 'REMOVE_CONNECTOR',
  IS_CREATING_CONNECTOR = 'IS_CREATING_CONNECTOR',
  ADD_APIKEY = 'ADD_APIKEY',
  REMOVE_APIKEY = 'REMOVE_APIKEY'
}

type AppAction = {
  type: AppActionType;
  payload:
    | {
        session: any;
        account: any;
      }
    | null
    | { id: number; name: string; type: string }
    | number;
};

const initialState = {
  session: null,
  account: null,
  isCreatingConnector: false
};

const App = (state: AppState = initialState, action: AppAction) => {
  switch (action.type) {
    case AppActionType.IS_CREATING_CONNECTOR: {
      return { ...state, isCreatingConnector: action.payload };
    }
    case AppActionType.ADD_CONNECTOR: {
      if (state.account === null) {
        return { ...state };
      }
      const newAccount = {
        ...state.account,
        resources: {
          ...state.account.resources,
          connectors: [
            ...(state.account.resources.connectors || []),
            action.payload
          ]
        }
      } as AccountSummaryResponse;
      setAccountData(newAccount);
      return {
        ...state,
        account: {
          ...newAccount,
          isCreatingConnector: false
        }
      };
    }
    case AppActionType.ADD_DESTINATION: {
      if (state.account === null) {
        return { ...state };
      }
      const newAccount = {
        ...state.account,
        resources: {
          ...state.account.resources,
          destinations: [
            ...(state.account.resources.destinations || []),
            action.payload
          ]
        }
      } as AccountSummaryResponse;
      setAccountData(newAccount);
      return {
        ...state,
        account: {
          ...newAccount
        }
      };
    }
    case AppActionType.REMOVE_CONNECTOR: {
      if (state.account === null) {
        return { ...state };
      }
      const newAccount = {
        ...state.account,
        resources: {
          ...state.account.resources,
          connectors: state.account.resources.connectors.filter(
            (connector: any) => connector.id !== action.payload
          )
        }
      };
      setAccountData(newAccount);
      return {
        ...state,
        account: {
          ...newAccount
        }
      };
    }
    case AppActionType.REMOVE_DESTINATION: {
      if (state.account === null) {
        return { ...state };
      }
      const newAccount = {
        ...state.account,
        resources: {
          ...state.account.resources,
          destinations: state.account.resources.destinations.filter(
            (destination: any) => destination.id !== action.payload
          )
        }
      };
      setAccountData(newAccount);
      return {
        ...state,
        account: {
          ...newAccount
        }
      };
    }
    case AppActionType.ADD_APIKEY: {
      if (state.account === null) {
        return { ...state };
      }
      const newAccount = {
        ...state.account,
        resources: {
          ...state.account.resources,
          api_keys: [
            ...(state.account.resources.api_keys || []),
            action.payload
          ]
        }
      } as AccountSummaryResponse;
      setAccountData(newAccount);
      return {
        ...state,
        account: {
          ...newAccount
        }
      };
    }
    case AppActionType.REMOVE_APIKEY: {
      if (state.account === null) {
        return { ...state };
      }
      const newAccount = {
        ...state.account,
        resources: {
          ...state.account.resources,
          api_keys: state.account.resources.api_keys.filter(
            (api_key: any) => api_key.id !== action.payload
          )
        }
      };
      setAccountData(newAccount);
      return {
        ...state,
        account: {
          ...newAccount
        }
      };
    }
    case AppActionType.APP_LOAD: {
      return {
        ...state,
        ...(action.payload as AppState)
      };
    }
    case AppActionType.LOGOUT: {
      return {
        ...state,
        session: null,
        account: null
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default App;
