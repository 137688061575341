import React, { useState, useEffect } from 'react';
import { getDestinations } from '../../services/services';
import { DestinationListed, QueryCard as QueryCardType } from '../../types/api';
import { Mixpanel } from '../common/Mixpanel';
import QueryCard from './QueryCard';
import QueryBrowser from './QueryBrowser';

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const
    },
    title: {
      display: true,
      text: 'SQL Query Results Graph'
    }
  }
};

const Queries = () => {
  const [dataSources, setDataSources] = useState<DestinationListed[]>([]);
  const [selectedDataSource, setSelectedDataSource] = useState<number | null>(
    null
  );
  const [cardToLoad, setCardToLoad] = useState<QueryCardType | null>(null);
  console.log(cardToLoad);

  Mixpanel.track('Dashboard - Data Visualization - Queries', {
    is_page_view: true
  });

  useEffect(() => {
    fetchDataSources();
  }, []);

  const fetchDataSources = async () => {
    const destinations = await getDestinations();
    const sqlDestinations = destinations.filter(
      (destination) => destination.type_adapter_name !== 'Google Sheets'
    );

    setDataSources(sqlDestinations);
    setSelectedDataSource(sqlDestinations[0].id);
    return getDestinations();
  };

  return (
    <>
      <div className="container-setting">
        <h4 className="payment-details-1 mb-5">
          CREATE YOUR QUERIES AND VISUALIZE YOUR DATA
        </h4>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-2 py-2 border border-primary rounded shadow-sm">
              <div>
                <h4 className="mt-0 mb-3">Source</h4>
                <div title="Sources">
                  <div
                    //variant="success"
                    id="dropdown-basic"
                    style={{ display: 'flex' }}
                  >
                    {
                      <div>
                        <img
                          height="30px"
                          src={`${process.env.REACT_APP_API_URL}/static/media/${
                            dataSources.find(
                              (dataSource) =>
                                dataSource.id === selectedDataSource
                            )?.icon
                          }`}
                          alt={
                            dataSources.find(
                              (dataSource) =>
                                dataSource.id === selectedDataSource
                            )?.name
                          }
                          className="mx-2"
                        />
                        <span className="mx-2">
                          {' '}
                          {
                            dataSources.find(
                              (dataSource) =>
                                dataSource.id === selectedDataSource
                            )?.name
                          }
                        </span>
                      </div>
                    }
                  </div>
                  <div>
                    {dataSources.map((dataSource) => (
                      <div
                        key={dataSource.id}
                        //action
                        //active={selectedDataSource === dataSource.id}
                        onClick={() => setSelectedDataSource(dataSource.id)}
                      >
                        <img
                          height="30px"
                          src={`${process.env.REACT_APP_API_URL}/static/media/${dataSource.icon}`}
                          alt={dataSource.name}
                          className="mx-2"
                        />
                        <span className="mx-2"> {dataSource.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-2 p-2 border border-primary rounded shadow-sm">
              <QueryBrowser style={{}} setCardToLoad={setCardToLoad} />
            </div>
            <div className="p-2 border border-primary rounded shadow-sm">
              <QueryCard
              //destination={dataSources.find(
              //  (dataSource) => dataSource.id === selectedDataSource
              //)}
              //cardToLoad={cardToLoad}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="right-bg" style={{ height: '500px' }}></div>
    </>
  );
};

export default Queries;
