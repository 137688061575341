import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  getConnectorDetails,
  getConnectorTemplate,
  getExternalCredentialsUrl,
  notification,
  partialConnectorUpdate,
  validateCredentials,
  getDocumentationStatus
} from '../../services/services';
import { State } from '../../types/state';
import Path from '../../Path';
import { ConnectorActionType } from '../../reducers/connector';
import { Mixpanel } from '../common/Mixpanel';
import Loader from '../common/Loader';
import { Loader as Loading } from 'rsuite';
import SelectDestinationModal from './modals/SelectDestinationModal';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import ConfirmationModal from './modals/ConfirmationModal';
import { DynamicForm } from '../common/DynamicForm';

const DataSourceForm = () => {
  const formState = useSelector((state: State) => state.connector);
  const account = useSelector((state: State) => state.app.account);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [doneLoading, setDoneLoading] = useState(false);
  const [frameLoaded, setFrameLoaded] = useState(false);
  const [validatingLoading, setValidatingLoading] = useState(false);
  const [credentialsValidated, setCredentialsValidated] = useState(false);
  const [documentationFrame, setdocumentationFrame] = useState(``);
  const [destinationsExist, setDestinationsExist] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: rawConnectorId } = useParams();
  const connectorId = parseInt(rawConnectorId || '');
  const isEditing = connectorId > 0;

  useEffect(() => {
    if (!account?.resources) {
      return;
    }
    if (account?.resources.destinations.length === 0) {
      Mixpanel.track('Connector Configuration - Page View', {
        is_page_view: true,
        is_onboarding: true
      });
      setIsOnboarding(true);
    } else {
      Mixpanel.track('Connector Configuration - Page View', {
        is_page_view: true,
        is_onboarding: false
      });
    }
  }, [account]);

  useEffect(() => {
    if (!connectorId) {
      return;
    }
    if (doneLoading) {
      return;
    }

    (async () => {
      const existingConnector = await getConnectorDetails(connectorId);
      const connectorTemplate = await getConnectorTemplate(
        existingConnector.connector_cat
      );
      dispatch({
        type: ConnectorActionType.EXISTING_CONNECTOR,
        payload: existingConnector
      });
      dispatch({
        type: ConnectorActionType.CONNECTOR_TEMPLATE,
        payload: connectorTemplate
      });
      setDoneLoading(true);
    })();
  }, [connectorId, dispatch, doneLoading]);

  useEffect(() => {
    if (doneLoading) {
      return;
    }
    if (formState.sourceFormValues.templateId) {
      (async () => {
        const connectorTemplate = await getConnectorTemplate(
          formState.sourceFormValues.templateId
        );
        dispatch({
          type: ConnectorActionType.CONNECTOR_TEMPLATE,
          payload: connectorTemplate
        });
        setDoneLoading(true);
      })();
    }
  }, [dispatch, doneLoading, formState]);

  if (doneLoading) {
    let docName = formState.template?.connector.name.toLowerCase();
    if (docName === 'amazon seller') docName = 'amazon_sp';
    if (docName === 'google analytics 4') docName = 'google_analytics';
    if (docName === 'mercado libre') docName = 'mercado_libre';
    if (docName === 'ownerrez') docName = 'ownerez';
    if (docName === 'postgres xmin') docName = 'postgres_xmin';
    if (docName === 'sql query') docName = 'sql_query';
    if (docName === 'trackhs') docName = 'track_hs';

    (async () => {
      const statusDoc = await getDocumentationStatus(
        `https://docs.movinglake.io/docs/sources/${docName}/#source-guide/`
      );
      if (statusDoc.status === 200) {
        setdocumentationFrame(
          `https://docs.movinglake.io/docs/sources/${docName}/#source-guide`
        );
      }
      setFrameLoaded(true);
    })();
  }

  const validateExternalCredentials = async () => {
    setValidatingLoading(true);
    if (!formState.template?.connector.id) {
      return;
    }
    try {
      const values = formState.sourceFormValues.credentials;
      const { id } = await getExternalCredentialsUrl(
        formState.template.connector.id,
        values
      );
      formState.sourceFormValues.tokenUid = id;
      window.open(
        `${process.env.REACT_APP_API_URL}/auth-connector/?token_uid=${id}`,
        '_blank'
      );
    } catch (e) {
      console.log(e);
      notification("Couldn't get external credentials", 'warning');
    }
    setValidatingLoading(false);
  };

  const handleValidateCredentials = async () => {
    setValidatingLoading(true);
    if (!formState.template?.connector.id) {
      return;
    }
    const isValid = await validateCredentials({
      connector_template_id: formState.template.connector.id,
      credentials: formState.sourceFormValues.credentials
    });
    setCredentialsValidated(isValid);
    Mixpanel.track('Connector Creation - Validate Credentials Success', {
      is_click: true,
      is_onboarding: isOnboarding,
      connector_name: formState.template?.connector.name,
      is_valid: isValid
    });
    if (isValid) {
      notification('Credentials validated', 'success');
    } else {
      notification('Failed to validate credentials', 'warning');
    }
    setValidatingLoading(false);
  };

  const handleEditSave = async () => {
    if (!credentialsValidated) {
      return;
    }
    try {
      await partialConnectorUpdate(connectorId, {
        credentials: formState.sourceFormValues.credentials,
        token_uid: formState.sourceFormValues.tokenUid
      });
      notification('Connector saved', 'success');
      navigate(Path.ShowConnector.replace(':id', connectorId.toString()));
    } catch (e: any) {
      console.log(e);
      notification(
        "Couldn't save connector " + JSON.stringify(e.response.data),
        'warning'
      );
    }
  };

  const handleNextStepInCreation = () => {
    if (isOnboarding) {
      navigate(Path.ChooseDestination);
    } else {
      setShowModal(true);
    }
  };

  let nextLink = null;
  if (credentialsValidated) {
    if (isEditing) {
      nextLink = (
        <Link to={'#'}>
          <button onClick={handleEditSave} className="btnMovinglake w-fit">
            Save
          </button>
        </Link>
      );
    } else {
      nextLink = (
        <Link to={'#'}>
          <button
            onClick={handleNextStepInCreation}
            className="btnMovinglake w-fit"
          >
            Next
          </button>
        </Link>
      );
    }
  }

  const onDestinationSelected = (exists: boolean) => {
    setDestinationsExist(exists);
  };

  return (
    <>
      {doneLoading && frameLoaded ? (
        <div>
          <main>
            <div className="lg:pl-96 xl:pl-[33%]">
              <div className="px-4 py-10 lg:py-6">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg lg:text-3xl font-semibold leading-6 text-gray-900">
                      {formState.template?.connector.name} - Source Credentials
                    </h3>
                    <div className="mt-3 max-w-xl text-sm text-gray-500">
                      <p>
                        Please enter your source credentials to connect your{' '}
                        <b> {formState.sourceFormValues.name} </b> source to
                        MovingLake.
                      </p>
                    </div>
                    {!destinationsExist ? (
                      <div className="rounded-md bg-yellow-50 p-4 mt-5">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <ExclamationTriangleIcon
                              className="h-5 w-5 text-yellow-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-yellow-800">
                              It seems like you don't have any destinations yet.
                            </h3>
                            <div className="mt-2 text-sm text-yellow-700">
                              <p>
                                To create a connector, you need to have at least
                                one destination.
                                <Link to={Path.ChooseDestination}>
                                  {' '}
                                  Please create a destination to continue.{' '}
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="mt-4">
                        <DynamicForm
                          type={
                            ConnectorActionType.CONNECTOR_FORM_SOURCE_CREDENTIAL_VALUES
                          }
                          credentials={formState.sourceFormValues.credentials}
                          schema_form={
                            formState.template?.credentials.schema_form
                          }
                          onSubmit={handleValidateCredentials}
                        >
                          {!validatingLoading ? (
                            <div className="mt-4 flex gap-x-5">
                              {formState.template?.connector
                                .is_user_authentication ? (
                                <button
                                  onClick={validateExternalCredentials}
                                  className="btnMovinglake w-fit"
                                >
                                  Get external credentials
                                </button>
                              ) : null}
                              <button
                                type="submit"
                                id="validateBtn"
                                className="btnMovinglake w-fit"
                              >
                                Validate Credentials
                              </button>
                              {destinationsExist && nextLink}
                            </div>
                          ) : (
                            <Loading
                              vertical={true}
                              size="sm"
                              className="mt-4"
                            />
                          )}
                        </DynamicForm>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>

          <aside className="fixed inset-y-0 hidden lg:w-96 xl:w-1/3 overflow-y-auto border-r border-gray-200 lg:block">
            <iframe
              title="Movinglake Docs"
              src={`${
                documentationFrame !== ''
                  ? documentationFrame
                  : 'https://docs.movinglake.io/'
              }`}
              className="w-full h-full bg-white"
            ></iframe>
          </aside>
        </div>
      ) : (
        <Loader />
      )}
      <SelectDestinationModal
        show={showModal}
        onLoaded={onDestinationSelected}
        onClose={() => setShowModal(false)}
        onSelected={(destination) => {
          dispatch({
            type: ConnectorActionType.CONNECTOR_SET_DESTINATION,
            payload: destination
          });
          navigate(Path.NewConnectorEntitiesForm);
        }}
        onCancel={() => navigate(Path.ChooseDestination)}
        confirmButtonText="Use Selected"
        cancelButtonText="Create a new one"
        title="Would you like to use an existing destination?"
      />
      <ConfirmationModal
        show={!destinationsExist}
        title="You don’t have any destinations yet"
        description="Please create a destination to continue."
        onConfirm={() => navigate(Path.ChooseDestination)}
        onCancel={() => console.log('cancel')}
      />
    </>
  );
};
export default DataSourceForm;
