import React, { Fragment, useEffect, useRef, useState } from 'react';
import 'rodal/lib/rodal.css';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { changePassword, notification } from '../../services/services';
import { State } from '../../types/state';
import { ChangePasswordRequest } from '../../types/api';
import { Mixpanel } from '../common/Mixpanel';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

type ChangePasswordProps = {
  show: boolean;
  onHide: () => void;
};

const ChangePassword = ({ show, onHide }: ChangePasswordProps) => {
  const [open, setOpen] = useState(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const cancelButtonRef = useRef(null);

  const user = useSelector((state: State) => state.app?.account?.user || null);

  const handleSubmit = (values: ChangePasswordRequest) => {
    if (values.password === '') {
      Mixpanel.track('Dashboard - Settings Change Password', {
        is_click: true
      });
      notification('Password are Empty', 'danger');
      if (values.password !== values.password2) {
        notification('Passwords do not match', 'danger');
      }
    } else if (user?.id) {
      Mixpanel.track('Dashboard - Settings Change Password', {
        is_click: true
      });
      changePassword(values, user.id)
        .then((_) => {})
        .catch((err) => {
          console.log('err', err);
          notification('Passwords must be 6 characters', 'danger');
        });
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => {
            onHide();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-0 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <Formik
                    initialValues={{ password: '', password2: '' }}
                    onSubmit={handleSubmit}
                  >
                    {({ errors }) => (
                      <Form>
                        <div>
                          <div className="mx-auto flex items-center justify-center rounded-full">
                            <Dialog.Title
                              as="h3"
                              className="text-base font-semibold leading-6 text-gray-900 ml-3"
                            >
                              Change Password
                            </Dialog.Title>
                          </div>
                          <div className="mt-3 text-center sm:mt-5">
                            <div className="mt-2">
                              <div>
                                <div className="relative mt-2 rounded-md shadow-sm">
                                  <div className="relative mt-2">
                                    <Field
                                      type="password"
                                      name="password"
                                      className={`peer block w-full border-0 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 ${
                                        errors.password &&
                                        'placeholder:text-red-500'
                                      }`}
                                      placeholder="Password"
                                      aria-invalid="true"
                                      aria-describedby="email-error"
                                    />
                                    {errors.password && (
                                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                        <ExclamationCircleIcon
                                          className="h-5 w-5 text-red-500"
                                          aria-hidden="true"
                                        />
                                      </div>
                                    )}
                                    <div
                                      className={`absolute inset-x-0 bottom-0 border-t border-gray-400 peer-focus:border-t-2 peer-focus:border-b-gray-900 ${
                                        errors.password && 'border-red-500'
                                      }`}
                                      aria-hidden="true"
                                    />
                                  </div>
                                </div>
                                {errors.password && (
                                  <p
                                    className="mt-2 text-sm text-red-600"
                                    id="email-error"
                                  >
                                    {errors.password}
                                  </p>
                                )}
                                <div className="relative mt-2 rounded-md shadow-sm">
                                  <div className="relative mt-2">
                                    <Field
                                      type="password"
                                      name="password2"
                                      className={`peer block w-full border-0 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6 ${
                                        errors.password2 &&
                                        'placeholder:text-red-500'
                                      }`}
                                      placeholder="Confirm Password"
                                      aria-invalid="true"
                                      aria-describedby="company-error"
                                    />
                                    {errors.password2 && (
                                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                        <ExclamationCircleIcon
                                          className="h-5 w-5 text-red-500"
                                          aria-hidden="true"
                                        />
                                      </div>
                                    )}
                                    <div
                                      className={`absolute inset-x-0 bottom-0 border-t border-gray-400 peer-focus:border-t-2 peer-focus:border-b-gray-900 ${
                                        errors.password2 && 'border-red-500'
                                      }`}
                                      aria-hidden="true"
                                    />
                                  </div>
                                  {errors.password2 && (
                                    <p
                                      className="mt-2 text-sm text-red-600"
                                      id="company-error"
                                    >
                                      {errors.password2}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-6 grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 gap-y-3">
                          <button
                            type="submit"
                            className="modalBtnMovinglake w-full"
                          >
                            Confirm
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => {
                              setOpen(false);
                              onHide();
                            }}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ChangePassword;
