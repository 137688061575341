import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FiEdit2 } from 'react-icons/fi';
import { AiFillDelete, AiOutlineReload } from 'react-icons/ai';
import { MdSupervisedUserCircle, MdVerified, MdClose } from 'react-icons/md';
import { BiUserCircle } from 'react-icons/bi';
import { EditInviteRequest, SendInviteRequest } from '../../types/api';
import {
  notification,
  sendInvite,
  getOrgUsers,
  deleteOrgUser,
  getOrgInvites,
  reSendInvite,
  deleteOrgInvite,
  editOrgUserEmail,
  editInvite
} from '../../services/services';
import { AxiosError } from 'axios';
import { State } from '../../types/state';
import { Popover, Whisper } from 'rsuite';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Path from '../../Path';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import EditUserModal from './modals/EditUserModal';
import ConfirmationModal from '../connector/modals/ConfirmationModal';

type userDeleted = {
  id: number;
  email: string;
  name: string;
};

const UsersOrganization = () => {
  const [orgUsers, setOrgUsers] = useState([]);
  const [orgInvites, setOrgInvites] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const organization = useSelector(
    (state: State) => state.app?.account?.organization
  );
  const user = useSelector((state: State) => state.app?.account?.user);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showInviteEditModal, setShowInviteEditModal] = useState(false);
  const [editingUser, setEditingUser] = useState(0);
  const [editingInvite, setEditingInvite] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteUser, setDeleteUser] = useState<userDeleted>();

  const handleEdit = (values: SendInviteRequest) => {
    (async () => {
      try {
        const params = { ...values };
        const editUser: EditInviteRequest = {
          id: editingUser,
          email: params.email
        };

        const res = await editOrgUserEmail(editUser);

        if (res.status === 'success') {
          setLoaded(false);
          setShowEditModal(false);
          setEditingUser(0);
          setOrgUsers(orgUsers);
          notification('User email updated', 'success');
        }
      } catch (e: any) {
        notification(JSON.stringify(e.response.data.message), 'warning');
      }
    })();
  };

  const handleInviteEdit = (values: SendInviteRequest) => {
    (async () => {
      try {
        const params = { ...values };
        const invite: EditInviteRequest = {
          id: editingInvite,
          email: params.email
        };

        const res = await editInvite(invite);

        if (res.status === 'success') {
          setLoaded(false);
          setShowInviteEditModal(false);
          setEditingInvite(0);
          setOrgInvites(orgInvites);
          notification('Invite updated', 'success');
        }
      } catch (e: any) {
        notification(JSON.stringify(e.response.data.message), 'warning');
      }
    })();
  };

  useEffect(() => {
    if (loaded) {
      return;
    }

    (async () => {
      try {
        const response = await getOrgUsers(organization);
        setOrgUsers(response.users);
        const invites = await getOrgInvites(organization);
        setOrgInvites(invites.invites);
        setLoaded(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [loaded, orgUsers, organization]);

  if (!user?.is_org_admin) {
    return <Navigate to={Path.ChooseDataSource} />;
  }

  const handleDelete = (id: any, email: any) => async () => {
    try {
      if (email.split('@')[1] === 'movinglake.com') {
        notification("You can't delete @movinglake.com users", 'warning');
        return;
      }
      await deleteOrgUser(id);
      notification('User deleted successfully', 'success');
      setOrgUsers(orgUsers.filter((item: any) => item.id !== id));
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status === 400) {
        notification('User not deletable', 'warning');
        return;
      } else {
        notification('Something went wrong', 'warning');
        console.error(e);
      }
    }
    setDeleteUser(undefined);
    setShowDeleteModal(false);
  };

  const handleInviteDelete = (id: number) => async () => {
    try {
      await deleteOrgInvite(id);
      notification('Invite deleted', 'success');
      setOrgInvites(orgInvites.filter((item: any) => item.id !== id));
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.status === 400) {
        notification('Invite not deletable', 'warning');
        return;
      } else {
        notification('Something went wrong', 'warning');
        console.error(e);
      }
    }
  };

  const handleSubmit = (values: SendInviteRequest) => {
    (async () => {
      try {
        const params = { ...values };
        const res = await sendInvite(params);

        if (res.status === 'success') {
          setLoaded(false);
          setOrgInvites(orgInvites);
          notification('Invitation sent successfully', 'success');
        }
      } catch (e: any) {
        notification(JSON.stringify(e.response.data.message), 'warning');
      }
    })();
  };

  const handleInvite = (id: number) => {
    (async () => {
      (async () => {
        try {
          const res = await reSendInvite(id);

          if (res.status === 'success')
            notification('Invitation sent successfully', 'success');
        } catch (e: any) {
          notification(JSON.stringify(e.response.data.message), 'warning');
        }
      })();
    })();
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required')
  });

  return (
    <div>
      <div>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ errors }) => (
            <Form id="userOrgForm">
              <div className="sm:flex md:items-center md:justify-between px-4 py-6 rounded-lg">
                <div className="min-w-0 flex gap-1 self-center">
                  <MdSupervisedUserCircle className="h-10 w-10" />
                  <h1 className="text-xl self-center md:text-3xl text-gray-500 font-bold sm:truncate sm:tracking-tight">
                    {organization?.name} Team
                  </h1>
                </div>
                <div className="flex gap-5 mt-5">
                  <div>
                    <Field
                      type="email"
                      name="email"
                      autoComplete="off"
                      className="peer block w-full md:w-96 border-0 border-b-2 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Send an invite by email"
                    />
                    <span className="text-danger error-message">
                      {errors.email}
                    </span>
                  </div>
                  <button type="submit" className="btnMovinglake h-full w-fit">
                    <PlusCircleIcon className="h-5 w-5 mr-1" />
                    <span className="hidden lg:block lg:ml-1">Send Invite</span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <hr className="h-px my-3 mb-6 bg-gray-200 border-0" />
        <div className="bg-white">
          <div className="mx-auto">
            <div className="mx-auto lg:mx-0">
              <h2 className="text-base font-bold tracking-tight text-gray-900 sm:text-xl">
                {organization?.name} members
              </h2>
              <p className="mt-3 text-base leading-8 text-gray-600">
                Here you can see all the members of your organization, and their
                status.
              </p>
            </div>
            <ul className="mx-auto mt-5 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-5 sm:gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none">
              {orgUsers.map((item: any, key) => (
                <li key={item.id} className="border shadow-lg rounded-lg py-10">
                  <BiUserCircle className="mx-auto h-16 w-16" />
                  <h3 className="flex gap-1 items-center justify-center mt-2 text-base font-semibold leading-7 tracking-tight text-gray-900">
                    {item.first_name}
                    {item.is_active ? (
                      <Whisper
                        followCursor
                        speaker={
                          <Popover>{'This user is currently active'}</Popover>
                        }
                      >
                        <span>
                          <MdVerified className="text-[#85C17C]" />
                        </span>
                      </Whisper>
                    ) : (
                      <Whisper
                        followCursor
                        speaker={
                          <Popover>
                            {'This user is not currently active'}
                          </Popover>
                        }
                      >
                        <span>
                          <MdClose className="text-red" />
                        </span>
                      </Whisper>
                    )}
                  </h3>
                  <p className="text-sm leading-6 text-gray-600">
                    {item.email}
                  </p>
                  {user?.id !== item.id && (
                    <div className="flex justify-center mt-3">
                      <button
                        type="button"
                        className="inline-flex items-center md:rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"
                        onClick={() => {
                          setShowEditModal(true);
                          setEditingUser(item.id);
                        }}
                      >
                        <FiEdit2 className="h-4 w-4 mr-1" /> Edit
                      </button>
                      <button
                        type="button"
                        className="-ml-px inline-flex items-center md:rounded-r-md bg-red-800 text-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-red-900 focus:z-10"
                        onClick={() => {
                          setShowDeleteModal(true);
                          setDeleteUser({
                            id: item.id,
                            email: item.email,
                            name: item.first_name
                          });
                        }}
                      >
                        <AiFillDelete className="h-4 w-4 mr-1" /> Delete
                      </button>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <h2 className="mt-10 text-base font-bold tracking-tight text-gray-900 sm:text-xl">
          {organization?.name} invites
        </h2>
        <ul className="divide-y divide-white/5">
          {orgInvites.map((item: any, key) => (
            <li
              key={item.id}
              className="relative md:flex md:items-center md:space-x-4 py-2"
            >
              <div className="min-w-0 flex-auto">
                <div className="flex items-center gap-x-3">
                  <div
                    className={`${
                      item.pending
                        ? 'text-rose-400 bg-rose-400/10'
                        : 'text-green-400 bg-green-400/10'
                    } flex-none rounded-full p-1`}
                  >
                    <div className="h-2 w-2 rounded-full bg-current" />
                  </div>
                  <h2 className="min-w-0 text-sm font-semibold leading-6 text-gray-500">
                    Invite sent to{' '}
                    <span className="text-gray-900">{item.email}</span>
                  </h2>
                </div>
              </div>
              <div className="flex justify-center mt-2">
                <button
                  type="button"
                  className="inline-flex items-center md:rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"
                  onClick={() => {
                    setShowInviteEditModal(true);
                    setEditingInvite(item.id);
                  }}
                >
                  <FiEdit2 className="h-4 w-4 mr-1" /> Edit
                </button>
                <button
                  type="button"
                  className="-ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  onClick={() => handleInvite(item.id)}
                >
                  <AiOutlineReload className="h-4 w-4 mr-1" /> Resend Invite
                </button>
                <button
                  type="button"
                  className="-ml-px inline-flex items-center md:rounded-r-md bg-red-800 text-white px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-red-900 focus:z-10"
                  onClick={handleInviteDelete(item.id)}
                >
                  <AiFillDelete className="h-4 w-4 mr-1" /> Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <EditUserModal
        title="Edit user email"
        show={showEditModal}
        onHandleSubmit={handleEdit}
        onCancel={() => {
          setShowEditModal(false);
        }}
      />
      <EditUserModal
        title="Edit invite email"
        show={showInviteEditModal}
        onHandleSubmit={handleInviteEdit}
        onCancel={() => {
          setShowInviteEditModal(false);
        }}
      />
      <ConfirmationModal
        show={showDeleteModal}
        title="Delete User"
        description={`Are you sure you want to delete ${deleteUser?.name} from ${organization?.name}?`}
        onConfirm={handleDelete(deleteUser?.id, deleteUser?.email)}
        onCancel={() => setShowDeleteModal(false)}
      />
    </div>
  );
};

export default UsersOrganization;
