import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { GrLinkedinOption } from 'react-icons/gr';

import {
  getAccountSummary,
  Login,
  notification
} from '../../services/services';
import Loader from '../common/Loader';
import { State } from '../../types/state';
import { LoginRequest } from '../../types/api';
import { AppActionType } from '../../reducers/app';
import { setAccountData, setSessionData } from '../../services/account';
import Path from '../../Path';
import { XCircleIcon } from '@heroicons/react/24/solid';

const LogIn = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const session = useSelector((state: State) => state.app?.session);
  const searchParams = useSearchParams()[0];

  useEffect(() => {
    if (searchParams.get('verified') === 'true') {
      notification('Email verified successfully', 'success');
    } else if (searchParams.get('verified') === 'false') {
      notification('Problem verifying email', 'warning');
    }
  }, [searchParams]);

  let pendingTokens = {};
  try {
    const tokens = `${searchParams.get('pending_tokens')}`.replace(/'/g, '"');
    pendingTokens = JSON.parse(tokens || '{}');
  } catch (e) {
    console.error(
      `Error parsing pending_tokens: ${searchParams.get('pending_tokens')}`
    );
  }

  if (session) {
    return <Navigate to={Path.Root} />;
  }

  const handleSubmit = (values: LoginRequest) => {
    setInvalid(false);
    (async () => {
      setLoading(true);
      try {
        const params = { ...values, pending_tokens: pendingTokens };
        const session = await Login(params);
        setSessionData(session);

        const account = await getAccountSummary();
        setAccountData(account);

        dispatch({
          type: AppActionType.APP_LOAD,
          payload: { account, session }
        });
        setInvalid(false);
      } catch (e) {
        setInvalid(true);
      }
      setLoading(false);
    })();
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  return (
    <>
      {!loading ? (
        <div className="flex min-h-full flex-1">
          <div className="mx-auto z-10 flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-5 xl:px-24">
            <div className="mx-auto max-w-md shadow-2xl bg-white py-3 px-6 rounded-lg">
              <div>
                <div className="flex flex-wrap gap-2 md:gap-5 px-5">
                  <img
                    className="h-16 w-16 md:h-20 md:w-20 mx-auto"
                    src={`/logo512.png`}
                    alt="Your Company"
                  />
                  <p className="text-gray-900 self-center font-bold text-2xl md:text-5xl leading-10">
                    MovingLake
                  </p>
                </div>
                <h2 className="mt-2 md:mt-8 text-xl md:text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Sign in to your account
                </h2>
                <p className="mt-2 text-sm leading-6 text-gray-500">
                  Don't have an account?{' '}
                  <Link
                    to="/signup"
                    className="font-semibold text-submarine-600 hover:text-submarine-500"
                  >
                    SignUp
                  </Link>
                </p>
              </div>

              <div className="mt-5">
                <div>
                  <Formik
                    initialValues={{ username: '', password: '' }}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={handleSubmit}
                  >
                    {({ errors }) => (
                      <Form className="space-y-6">
                        {(errors.username && errors.password) || invalid ? (
                          <div className="rounded-md bg-red-50 p-4">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <XCircleIcon
                                  className="h-5 w-5 text-red-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-3">
                                <h3 className="text-sm font-medium text-red-800">
                                  Invalid login
                                </h3>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Email address
                          </label>
                          <div className="mt-2">
                            <Field
                              type="email"
                              name="username"
                              autoComplete="off"
                              className={`${
                                errors.username
                                  ? 'border-red-500 border-10'
                                  : 'border-0'
                              } block w-full rounded-md py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-submarine-600 sm:text-sm sm:leading-6`}
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Password
                          </label>
                          <div className="mt-2">
                            <Field
                              type="password"
                              name="password"
                              autoComplete="off"
                              className={`${
                                errors.password
                                  ? 'border-red-500 border-10'
                                  : 'border-0'
                              } block w-full rounded-md py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-submarine-600 sm:text-sm sm:leading-6`}
                            />
                          </div>
                        </div>

                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <input
                              id="checkbox_1"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-submarine-600 focus:ring-submarine-600"
                            />
                            <label className="ml-3 block text-sm leading-6 text-gray-700">
                              Remember me
                            </label>
                          </div>

                          <div className="text-sm leading-6">
                            <Link
                              to="/forgotpassword"
                              className="font-semibold text-submarine-600 hover:text-submarine-500"
                            >
                              Forgot password?
                            </Link>
                          </div>
                        </div>

                        <div>
                          <button
                            type="submit"
                            className="flex w-full justify-center btnMovinglake"
                          >
                            Sign in
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>

                <div className="mt-10">
                  <div className="relative">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex justify-center text-sm font-medium leading-6">
                      <span className="bg-white px-6 text-gray-900">
                        Follow us
                      </span>
                    </div>
                  </div>

                  <div className="mt-6 grid grid-cols-2 gap-4">
                    <a
                      href="https://twitter.com/MovingLake"
                      className="flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
                    >
                      <svg
                        className="h-5 w-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                      <span className="text-sm font-semibold leading-6">
                        Twitter
                      </span>
                    </a>

                    <a
                      href="https://www.linkedin.com/company/movinglake/"
                      className="flex w-full items-center justify-center gap-3 rounded-md bg-[#0e76a8] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
                    >
                      <GrLinkedinOption className="h-5 w-5" />
                      <span className="text-sm font-semibold leading-6">
                        Linkedin
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute w-full h-full">
            <svg className="w-full h-full">
              <image href="/images/fondo6.svg" className="w-full h-full" />
            </svg>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default LogIn;
