import React, { useEffect, useState } from 'react';
import { ConnectorDetailResponse } from '../../types/api';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import DateRangePicker from 'rsuite/DateRangePicker';
import SelectPicker from 'rsuite/SelectPicker';
import { BarChart, XAxis, YAxis, Tooltip, Bar } from 'recharts';
import 'rsuite/dist/rsuite.min.css';
import { getUsageHistogram, notification } from '../../services/services';

type UsageHistogramProps = {
  connector: ConnectorDetailResponse;
};

const UsageHistogram = ({ connector }: UsageHistogramProps) => {
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfDay(subDays(new Date(), 6)),
    endOfDay(new Date())
  ]);
  const [selectedEntity, setSelectedEntity] = useState<number | null>(null);
  const [data, setData] = useState<{ start: string; count: number }[]>([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    // Función para actualizar las medidas de la pantalla cuando cambia el tamaño de la ventana.
    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    // Agregar un event listener para el evento 'resize'.
    window.addEventListener('resize', handleResize);

    // Eliminar el event listener cuando el componente se desmonta para evitar pérdida de rendimiento.
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await getUsageHistogram(
          connector.id,
          dateRange[0],
          dateRange[1],
          10,
          selectedEntity
        );
        setData(
          response.map((bin) => ({
            start: bin.start.toString().substring(0, 10),
            count: bin.count
          }))
        );
      } catch (e) {
        notification('Error fetching usage data', 'warning');
        console.error(e);
      }
    })();
  }, [dateRange, selectedEntity, connector.id]);

  const selectEntityData = connector.targets[0].entities.map((entity) => ({
    label: entity.name,
    value: entity.id
  }));

  return (
    <>
      <div className="mb-5 flex flex-wrap gap-3 sm:gap-10 w-full">
        <DateRangePicker
          appearance="default"
          placeholder="Select date range"
          defaultValue={dateRange}
          onOk={(value) => setDateRange(value)}
          className="w-80"
        />
        <SelectPicker
          data={selectEntityData}
          className="w-80"
          onSelect={setSelectedEntity}
          defaultValue={selectedEntity}
          placeholder="Choose a entity"
        />
      </div>
      <div className="flex">
        <div className="mx-1 sm:mx-auto mt-5 md:mt-10">
          <BarChart
            data={data}
            width={windowWidth / 1.4}
            height={windowHeight / 3}
          >
            <XAxis dataKey="start" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="count" fill="#82ca9d" />
          </BarChart>
        </div>
      </div>
    </>
  );
};

export default UsageHistogram;
