const Path = {
  // Test routes
  Test: '/test',

  // Authentication routes
  Login: '/login',
  Logout: '/logout',
  SignUp: '/signup',
  ForgotPassword: '/forgotpassword',
  ResetPassword: '/reset-password',
  AcceptInvite: '/accept-invite',

  // Partner Routes
  AmazonSPLogin: '/amazon-sp-login',
  GithubLogin: '/github-login-callback',

  // Connector form
  ChooseDataSource: '/choose-source',
  NewDataSourceForm: '/connectors/new',
  EditDataSourceForm: '/connectors/:id/edit',
  NewConnectorEntitiesForm: '/connectors/new/entities',

  // Destination form
  ChooseDestination: '/choose-destination',
  NewDestinationForm: '/destinations/new',
  EditDestinationForm: '/destinations/:id/edit',

  // Dashboard routes
  Root: '/',
  ConnectorList: '/connectors',
  DestinationList: '/destinations',
  ShowConnector: '/connectors/:id',
  DbtProjectDetails: '/dbt/:id',

  // Data Visualization routes
  DataOverview: '/data-visualization/data-overview',
  Queries: '/data-visualization/queries',
  Dashboards: '/data-visualization/dashboards',
  Stories: '/data-visualization/stories',

  SelectedDestination: '/selecteddestination',
  Settings: '/settings',
  Billing: '/billing',
  Apikey: '/apikey',

  //organization user management module
  UsersOrg: '/organization-users'
};

export default Path;
