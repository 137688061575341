import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import {
  createDestination,
  getDestinationTemplate,
  getDocumentationStatus,
  getExistingDestination,
  notification,
  updateDestination
} from '../../services/services';
import { Loader as Loading } from 'rsuite';
import { State } from '../../types/state';
import { DynamicForm } from '../common/DynamicForm';
import { DestinationActionType } from '../../reducers/destination';
import Path from '../../Path';
import { AppActionType } from '../../reducers/app';
import { ConnectorActionType } from '../../reducers/connector';
import { Mixpanel } from '../common/Mixpanel';
import { formatCredentials } from '../../services/format';
import Loader from '../common/Loader';
import ConfirmationModal from '../connector/modals/ConfirmationModal';

const DestinationForm = () => {
  const formState = useSelector((state: State) => state.destination);
  const isCreatingConnector = useSelector(
    (state: State) => state.app.isCreatingConnector
  );
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const { id: rawDestinationId } = useParams();
  const destinationId = parseInt(rawDestinationId || '');
  const navigate = useNavigate();
  const [documentationFrame, setdocumentationFrame] = useState(``);
  const [frameLoaded, setFrameLoaded] = useState(false);
  const [validatingLoading, setValidatingLoading] = useState(false);
  const [datasetModal, setDatasetModal] = useState(false);
  useEffect(() => {
    if (loaded || !!destinationId) {
      return;
    }
    (async () => {
      try {
        const destinationTemplate = await getDestinationTemplate(
          formState.destinationFormValues.templateId
        );
        dispatch({
          type: DestinationActionType.DESTINATION_TEMPLATE,
          payload: destinationTemplate
        });
        dispatch({
          type: DestinationActionType.DESTINATION_NAME,
          payload: ''
        });
        setLoaded(true);
        Mixpanel.track('Connector Creation - Configure Destinations', {
          is_page_view: true,
          source_type: destinationTemplate.name
        });
      } catch (e) {
        notification("Couldn't load destination template", 'warning');
        console.log(e);
      }
    })();
  }, [formState, destinationId, dispatch, loaded]);

  useEffect(() => {
    if (loaded || !destinationId) {
      return;
    }
    (async () => {
      try {
        const existingDestination = await getExistingDestination(destinationId);
        const destinationTemplate = await getDestinationTemplate(
          existingDestination.type_adapter
        );
        dispatch({
          type: DestinationActionType.DESTINATION_TEMPLATE,
          payload: destinationTemplate
        });
        dispatch({
          type: DestinationActionType.EXISTING_DESTINATION,
          payload: existingDestination
        });
        setLoaded(true);
      } catch (e) {
        notification("Couldn't load destination", 'warning');
        console.log(e);
      }
    })();
  }, [destinationId, dispatch, formState, loaded]);

  const handleSubmit = async () => {
    setValidatingLoading(true);
    try {
      const payload = formatCredentials({
        name: formState.destinationFormValues.name,
        type_adapter: formState.destinationFormValues.templateId,
        credential_json: formState.destinationFormValues.credentials
      });
      if (destinationId) {
        await updateDestination(destinationId, payload);
        notification('Destination updated successfully', 'success');
        navigate(Path.DestinationList);
      } else {
        const response = await createDestination(payload);
        dispatch({
          type: AppActionType.ADD_DESTINATION,
          payload: {
            id: response.id,
            name: response.name,
            adapter: response.type_adapter
          }
        });
        notification('Destination created successfully', 'success');

        if (isCreatingConnector) {
          dispatch({
            type: ConnectorActionType.CONNECTOR_SET_DESTINATION,
            payload: {
              id: response.id,
              name: response.name,
              type: formState.template!.name
            }
          });
          //navigate(Path.ChooseDataSource);
          //Ir a la ruta anterior
          navigate(-2);
          return;
        } else {
          navigate(Path.DestinationList);
        }
      }
    } catch (err: any) {
      if (err.response.data.message.includes("doesn't exists")) {
        console.log(err.response.data.message);
        setDatasetModal(true);
      } else {
        notification('There was an error creating the destination', 'warning');
      }
    }
    setValidatingLoading(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  if (!formState) {
    return null;
  }

  let docName = formState.template?.code;
  if (docName === 's3buffered') docName = 'amazon_s3';
  if (docName === 'postgres') docName = 'postgresql';
  if (docName === 'gsheets') docName = 'google_sheets';

  (async () => {
    const statusDoc = await getDocumentationStatus(
      `https://docs.movinglake.io/docs/destinations/${docName}/#destination-guide/`
    );
    if (statusDoc.status === 200) {
      setdocumentationFrame(
        `https://docs.movinglake.io/docs/destinations/${docName}/#destination-guide`
      );
    }
    setTimeout(() => {
      setFrameLoaded(true);
    }, 500);
  })();

  return (
    <>
      {loaded && frameLoaded ? (
        <div>
          <main>
            <div className="lg:pl-96 xl:pl-[33%]">
              <div className="px-4 py-10 lg:py-6">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg lg:text-3xl font-semibold leading-6 text-gray-900">
                      {formState.template?.name} - Source Credentials
                    </h3>
                    <div className="mt-3 max-w-xl text-sm text-gray-500">
                      <p>
                        Enter your credentials for your{' '}
                        {formState.template?.name} destination
                      </p>
                    </div>
                    <div className="mt-4">
                      <Formik
                        initialValues={{ name: '' }}
                        onSubmit={handleSubmit}
                      >
                        <Form>
                          <div className="grid grid-cols-1 mb-5">
                            <div className="sm:col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                <span> Destination name </span>
                              </label>
                              <div className="relative">
                                <Field
                                  type="input"
                                  name="name"
                                  id="name"
                                  value={formState.destinationFormValues.name}
                                  onChange={(e: any) =>
                                    dispatch({
                                      type: DestinationActionType.DESTINATION_NAME,
                                      payload: e.target.value
                                    })
                                  }
                                  placeholder="Enter a unique name for the destination"
                                  required={true}
                                  aria-invalid="true"
                                  className="peer block w-full border-0 py-1.5 text-gray-900 focus:border-0 focus:ring-0 sm:text-sm sm:leading-6"
                                />
                                <div
                                  className="absolute inset-x-0 bottom-0 border-t border-gray-400 peer-focus:border-t-2 peer-focus:border-b-gray-900"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                        </Form>
                      </Formik>
                      <DynamicForm
                        type={
                          DestinationActionType.DESTINATION_FORM_CREDENTIAL_VALUES
                        }
                        credentials={
                          formState.destinationFormValues.credentials
                        }
                        schema_form={
                          formState.template?.scheme_form?.schema_form
                        }
                        onSubmit={handleSubmit}
                      >
                        <div className="mt-4 flex gap-x-5">
                          {!validatingLoading ? (
                            <button
                              type="submit"
                              className="btnMovinglake w-fit"
                            >
                              Save
                            </button>
                          ) : (
                            <Loading
                              vertical={true}
                              size="sm"
                              className="mt-4"
                            />
                          )}
                          <Link to={'#'}>
                            <button
                              className="btnMovinglake w-fit"
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                          </Link>
                        </div>
                      </DynamicForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          <aside className="fixed inset-y-0 hidden lg:w-96 xl:w-1/3 overflow-y-auto border-r border-gray-200 lg:block">
            <iframe
              title="Movinglake Docs"
              src={`${
                documentationFrame !== ''
                  ? documentationFrame
                  : 'https://docs.movinglake.io/'
              }`}
              className="w-full h-full bg-white"
            ></iframe>
          </aside>
        </div>
      ) : (
        <Loader />
      )}
      <ConfirmationModal
        show={datasetModal}
        title="Dataset not found"
        description="The dataset you are trying to connect to doesn't exist. Please create it in your BigQuery account and try again."
        onConfirm={() => {
          setDatasetModal(false);
        }}
        hideCancel={true}
        onCancel={() => setDatasetModal(false)}
      />
    </>
  );
};

export default DestinationForm;
