import { ConnectorFormState, FormEntityValue } from '../types/state';
import { EntitySpec } from '../types/api';

export enum ConnectorActionType {
  CONNECTOR_TEMPLATE = 'CONNECTOR_TEMPLATE',
  CONNECTOR_FORM_SOURCE_CREDENTIAL_VALUES = 'CONNECTOR_FORM_SOURCE_CREDENTIAL_VALUES',
  EXISTING_CONNECTOR = 'EXISTING_CONNECTOR',
  CONNECTOR_NAME_DATA = 'CONNECTOR_NAME_DATA',
  CONNECTOR_FORM_STEP = 'CONNECTOR_FORM_STEP',
  CONNECTOR_SET_DESTINATION = 'CONNECTOR_SET_DESTINATION',
  CONNECTOR_SET_ENTITIES = 'CONNECTOR_SET_ENTITIES',
  CONNECTOR_UPDATE_ENTITY = 'CONNECTOR_UPDATE_ENTITY',
  CONNECTOR_SET_BACKFILL_DATE = 'CONNECTOR_SET_BACKFILL_DATE'
}

type ConnectorAction = {
  type: ConnectorActionType;
  payload: any;
};

const initialState = {
  template: null,
  existingConnector: null,
  sourceFormValues: {
    templateId: 0,
    name: '',
    description: '',
    credentials: {},
    tokenUid: ''
  },
  destination: {
    id: 0,
    name: '',
    type: ''
  },
  entities: []
};

const Connector = (
  state: ConnectorFormState = initialState,
  action: ConnectorAction
) => {
  switch (action.type) {
    case ConnectorActionType.CONNECTOR_FORM_STEP:
      return {
        ...state,
        currentStep: action.payload.step
      };
    case ConnectorActionType.EXISTING_CONNECTOR:
      return {
        ...state,
        existingConnector: action.payload,
        sourceFormValues: {
          ...state.sourceFormValues,
          credentials: {
            ...state.sourceFormValues.credentials,
            ...action.payload.origin_credential_json.secrets
          }
        }
      };
    case ConnectorActionType.CONNECTOR_TEMPLATE:
      return {
        ...state,
        template: action.payload
      };
    case ConnectorActionType.CONNECTOR_FORM_SOURCE_CREDENTIAL_VALUES:
      return {
        ...state,
        sourceFormValues: {
          ...state.sourceFormValues,
          credentials: {
            ...state.sourceFormValues.credentials,
            ...action.payload
          }
        }
      };
    case ConnectorActionType.CONNECTOR_NAME_DATA:
      return {
        ...state,
        sourceFormValues: {
          ...state.sourceFormValues,
          name: action.payload.name,
          description: action.payload.description,
          templateId: action.payload.id
        }
      };
    case ConnectorActionType.CONNECTOR_SET_DESTINATION:
      return {
        ...state,
        destination: {
          ...action.payload
        }
      };
    case ConnectorActionType.CONNECTOR_SET_ENTITIES:
      const entityMap: Record<number, FormEntityValue> = {};
      action.payload.forEach((entity: EntitySpec) => {
        let timing_configuration:
          | { repeat_every: number }
          | { cron_expression: string };
        if (entity.timing_configuration.strategy === 'cron_expression') {
          timing_configuration = { cron_expression: '' };
        } else {
          timing_configuration = {
            repeat_every:
              entity.timing_configuration.validation_data.default_value
          };
        }
        entityMap[entity.id] = {
          id: entity.id,
          is_active: true,
          timing_configuration: timing_configuration,
          next_time: null,
          url_request: null
        };
      });

      return {
        ...state,
        entities: entityMap
      };
    case ConnectorActionType.CONNECTOR_UPDATE_ENTITY:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: {
            ...state.entities[action.payload.id],
            ...action.payload
          }
        }
      };
    case ConnectorActionType.CONNECTOR_SET_BACKFILL_DATE:
      const newEntities = Object.keys(state.entities).reduce(
        (acc: Record<number, FormEntityValue>, key) => {
          const newKey = parseInt(key);
          return {
            ...acc,
            [newKey]: {
              ...state.entities[newKey],
              next_time: action.payload
            }
          };
        },
        {}
      );
      return {
        ...state,
        entities: newEntities
      };
    default:
      return state;
  }
};

export default Connector;
