import React, { useEffect, useState } from 'react';
import { loadQueryCards } from '../../services/services';
import { QueryCard as QueryCardType } from '../../types/api';
import { Mixpanel } from '../common/Mixpanel';

interface QueryCardProps {
  style: React.CSSProperties;
  setCardToLoad: (card: QueryCardType) => void;
}

const QueryBrowser: React.FC<QueryCardProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [queries, setQueries] = useState<QueryCardType[]>([]);
  const [error, setError] = useState<string>('');
  const [selectedNamespace, setSelectedNamespace] = useState<string>('');

  const getAllNamespaces = (): string[] => {
    const namespaces = new Set<string>();
    queries.forEach((query) => {
      namespaces.add(query.namespace);
    });
    return Array.from(namespaces);
  };

  const handleLoadQueries = () => {
    Mixpanel.track('Query Browser Refresh');
    setLoading(true);
    loadQueryCards().then(
      (data) => {
        console.log(data);
        setQueries(data);
        setError('');
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setError(error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    handleLoadQueries();
  }, []);

  return (
    <div style={props.style}>
      <div>
        <div>
          <h4>Query Browser</h4>
        </div>
        <div>
          <button onClick={handleLoadQueries}>Refresh</button>
        </div>
      </div>
      <div>
        <div>
          {loading && (
            <div>
              {/*
               <Spinner animation="border" role="status"></Spinner>
              */}
              <span className="sr-only">Loading...</span>
            </div>
          )}
          {error && <p>{error}</p>}
          {!loading && !error && queries && (
            <div className="mt-3">
              <div
                style={{ maxHeight: '300px', overflowY: 'scroll', padding: 0 }}
              >
                <h5>Folder</h5>
                <div style={{ marginTop: '10px' }}>
                  {getAllNamespaces().map((folder, idx) => (
                    <div
                      //action
                      //active={folder === selectedNamespace}
                      onClick={() => {
                        setSelectedNamespace(folder);
                      }}
                      key={idx}
                      style={{ overflow: 'auto' }}
                    >
                      {folder}
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{ maxHeight: '300px', overflowY: 'scroll', padding: 0 }}
              >
                <h5>Query Name</h5>
                {selectedNamespace && (
                  <div style={{ marginTop: '10px' }}>
                    {queries
                      .filter((query) => query.namespace === selectedNamespace)
                      .map((query, idx) => (
                        <div
                          //action
                          onClick={() => {
                            props.setCardToLoad(query);
                          }}
                          key={idx}
                          style={{ overflow: 'auto' }}
                        >
                          {query.name}
                        </div>
                      ))}
                  </div>
                )}
              </div>
              {!queries.length && (
                <div>
                  <p>No queries found. Saved queries will appear here.</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default QueryBrowser;
