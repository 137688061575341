import { useEffect } from 'react';

export default function GithubLogin() {
  useEffect(() => {
    window.location.href =
      process.env.REACT_APP_API_URL +
      '/github-login-callback/' +
      window.location.search;
  });
  return null;
}
