import { DestinationPersistenceRequest } from '../types/api';
import { notification } from './services';

enum AdapterTypes {
  WEBHOOK = 1,
  BIG_QUERY = 2,
  SNOWFLAKE = 3,
  POSTGRES = 4,
  REDSHIFT = 5,
  SQL_SERVER = 6,
  GSHEETS = 7,
  S3_BUFFERED = 8,
  MYSQL = 9,
  DTRAVEL = 10
}

// Check and change credentials format where possible
export const formatCredentials = (
  destination: DestinationPersistenceRequest
): DestinationPersistenceRequest => {
  if (destination.type_adapter === AdapterTypes.GSHEETS) {
    const gsheetsURLRegex =
      /^(https:\/\/)docs\.google\.com\/spreadsheets\/d\/[A-Za-z0-9_-]+\/edit\?(gid=0|usp=sharing)$/i;
    destination.credential_json['sheet-url'] =
      destination.credential_json['sheet-url'].trim();
    if (!gsheetsURLRegex.test(destination.credential_json['sheet-url'])) {
      notification('Incorrect URL pattern. Check your credentials.', 'warning');
      return destination;
    }
    // Change URL parameters to 'gid=0'
    const gsheetUrl: Array<string> =
      destination.credential_json['sheet-url'].split('?');
    gsheetUrl[gsheetUrl.length - 1] = '?gid=0';
    destination.credential_json['sheet-url'] = gsheetUrl.join('');
  }
  return destination;
};
