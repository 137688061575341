import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react';
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  ChevronRightIcon,
  PencilSquareIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { State } from '../types/state';
import { FaBezierCurve } from 'react-icons/fa';
import { TbApiApp } from 'react-icons/tb';
import {
  MdBackupTable,
  MdDashboard,
  MdManageAccounts,
  MdMenuBook,
  MdOutlineQueryStats,
  MdSupervisedUserCircle
} from 'react-icons/md';
import { GiBossKey } from 'react-icons/gi';
import { RiBillLine } from 'react-icons/ri';
import Nabvar from './Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { cleanupSessionAndAccount } from '../services/account';
import { AppActionType } from '../reducers/app';
import Path from '../Path';
import ChangePassword from './authentication/ChangePassword';
import { useBilling } from '../contexts/BillingContext';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type SidebarPageProps = {
  component: any;
  Show: string;
};

function daysBetween(timestamp: number) {
  const timeStamp = new Date(timestamp * 1000).getTime();
  const currentDate = new Date().getTime();
  return Math.floor((timeStamp - currentDate) / (1000 * 60 * 60 * 24)) + 1;
}

const SidebarPage = ({ component, Show }: SidebarPageProps) => {
  const account = useSelector((state: State) => state.app?.account);
  const { billingSubscription, clearBillingDetails } = useBilling();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);

  if (!account) {
    return null;
  }

  const billingEnabled = account.organization.billing_enabled;
  const teamSectionEnabled = account.user.is_org_admin;

  const navigation = [
    {
      name: 'Connectors',
      to: '/connectors',
      icon: FaBezierCurve,
      current: false,
      visible: true
    },
    {
      name: 'Destinations',
      to: '/destinations',
      icon: TbApiApp,
      current: false,
      visible: true
    },
    {
      name: 'Data Analysis',
      to: '#',
      icon: MdOutlineQueryStats,
      current: false,
      visible: true,
      children: [
        {
          name: 'DataOverview',
          to: '/data-visualization/data-overview',
          icon: MdBackupTable,
          current: false,
          visible: true
        },
        {
          name: 'Queries',
          to: '/data-visualization/queries',
          icon: MdOutlineQueryStats,
          current: false,
          visible: true
        },
        {
          name: 'Dashboards',
          to: '/data-visualization/dashboards',
          icon: MdDashboard,
          current: false,
          visible: true
        },
        {
          name: 'Stories',
          to: '/data-visualization/stories',
          icon: MdMenuBook,
          current: false,
          visible: true
        }
      ]
    },
    {
      name: 'Account',
      to: '#',
      icon: MdManageAccounts,
      current: false,
      visible: true,
      children: [
        {
          name: 'Team',
          to: '/organization-users',
          icon: MdSupervisedUserCircle,
          current: false,
          visible: teamSectionEnabled
        },
        {
          name: 'Settings',
          to: '/settings',
          icon: MdManageAccounts,
          current: false,
          visible: true
        },
        {
          name: 'Billing',
          to: '/billing',
          icon: RiBillLine,
          current: false,
          visible: billingEnabled
        },
        {
          name: 'API Keys',
          to: '/apikey',
          icon: GiBossKey,
          current: false,
          visible: true
        }
      ]
    }
  ];

  const handleLogout = () => {
    setTimeout(() => {
      cleanupSessionAndAccount();
      dispatch({
        type: AppActionType.LOGOUT,
        payload: null
      });
      navigate(Path.Login);
      try {
        if (clearBillingDetails && typeof clearBillingDetails === 'function') {
          clearBillingDetails();
        }
      } catch {
        console.log('Error cleaning billing details');
      }
    }, 600);
  };

  return (
    <>
      <div>
        {/* Mobile view - Sidebar */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5 hover:bg-submarine-500"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div
                    className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-2"
                    id="mobileSidebar"
                  >
                    <div className="flex h-16 shrink-0 items-center">
                      <p className="text-white text-lg font-bold">MovingLake</p>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {navigation
                              .filter((item) => item.visible === true)
                              .map((item) => (
                                <li key={item.name}>
                                  {!item.children ? (
                                    <Link
                                      to={item.to}
                                      className={classNames(
                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6'
                                      )}
                                      style={{
                                        textDecoration: 'none',
                                        color: 'white'
                                      }}
                                    >
                                      <item.icon
                                        className={classNames(
                                          'h-6 w-6 shrink-0'
                                        )}
                                        aria-hidden="true"
                                      />
                                      {item.name}
                                    </Link>
                                  ) : (
                                    <Disclosure as="div">
                                      {({ open }) => (
                                        <>
                                          <Disclosure.Button
                                            className={classNames(
                                              'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6'
                                            )}
                                          >
                                            <item.icon
                                              className="h-6 w-6 shrink-0"
                                              aria-hidden="true"
                                            />
                                            {item.name}
                                            <ChevronRightIcon
                                              className={classNames(
                                                'ml-auto h-5 w-5 shrink-0'
                                              )}
                                              aria-hidden="true"
                                            />
                                          </Disclosure.Button>
                                          <Disclosure.Panel
                                            as="ul"
                                            className="mt-1 px-2"
                                          >
                                            {item.children.map((subItem) => (
                                              <li
                                                key={subItem.name}
                                                className="mt-1"
                                              >
                                                <Link
                                                  to={subItem.to}
                                                  className={classNames(
                                                    'text-white rounded-md py-2 pr-2 pl-9 text-sm leading-6 flex gap-x-3'
                                                  )}
                                                  style={{
                                                    textDecoration: 'none',
                                                    color: 'white'
                                                  }}
                                                >
                                                  <subItem.icon
                                                    className={classNames(
                                                      'h-6 w-6 shrink-0'
                                                    )}
                                                    aria-hidden="true"
                                                  />
                                                  {subItem.name}
                                                </Link>
                                              </li>
                                            ))}
                                          </Disclosure.Panel>
                                        </>
                                      )}
                                    </Disclosure>
                                  )}
                                </li>
                              ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Desktop view - Sidebar */}
        <div
          className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"
          id="desktopSidebar"
        >
          <div className="sidebar-colors flex grow flex-col gap-y-5 overflow-y-auto bg-gradient-to-tr px-6">
            <div className="flex h-16 shrink-0 items-center gap-x-2 justify-center">
              <p className="text-white text-3xl font-bold">MovingLake</p>
              <hr />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation
                      .filter((item) => item.visible === true)
                      .map((item) => (
                        <li key={item.name}>
                          {!item.children ? (
                            <Link
                              to={item.to}
                              className={classNames(
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6'
                              )}
                              style={{
                                textDecoration: 'none',
                                color: 'white'
                              }}
                            >
                              <item.icon
                                className={classNames('h-6 w-6 shrink-0')}
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          ) : (
                            <Disclosure as="div" id="sidebarItems">
                              {({ open }) => (
                                <>
                                  <Disclosure.Button
                                    className={classNames(
                                      'text-white flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6'
                                    )}
                                  >
                                    <item.icon
                                      className="h-6 w-6 shrink-0"
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                    <ChevronRightIcon
                                      className={classNames(
                                        open ? 'rotate-90' : '',
                                        'ml-auto h-5 w-5 shrink-0'
                                      )}
                                      aria-hidden="true"
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel
                                    as="ul"
                                    className="mt-1 px-2"
                                  >
                                    {item.children
                                      .filter((item) => item.visible === true)
                                      .map((subItem) => (
                                        <li key={subItem.name} className="mt-1">
                                          <Link
                                            to={subItem.to}
                                            className={classNames(
                                              'rounded-md py-2 pr-2 pl-9 text-sm leading-6 flex gap-x-3'
                                            )}
                                            style={{
                                              textDecoration: 'none',
                                              color: 'white'
                                            }}
                                          >
                                            <subItem.icon
                                              className={classNames(
                                                'h-6 w-6 shrink-0 '
                                              )}
                                              aria-hidden="true"
                                            />
                                            {subItem.name}
                                          </Link>
                                        </li>
                                      ))}
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          )}
                        </li>
                      ))}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto">
                  <div className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-sky-700">
                    <span aria-hidden="true" className="mx-auto">
                      {account?.user.email}
                    </span>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/* Mobile view - Barra de Navegacion Superior */}
        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-2 shadow-md sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-800 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">
            MovingLake
          </div>
          <Menu as="div" className="flex items-center gap-x-5 ml-3">
            <div
              id="navbarOrg"
              className={
                'text-indigo-200 hover:text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
              }
            >
              <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border  text-[0.625rem] font-medium text-white">
                O
              </span>
              <span className="truncate text-gray-700">
                {account?.organization.name}
              </span>
            </div>
            <div>
              <Menu.Button className="relative flex rounded-full bg-transparent text-sm focus:outline-none ">
                <span className="absolute -inset-1.5" />
                <span className="sr-only">Menu</span>
                <UserCircleIcon
                  className="h-6 w-6 text-gray-700"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute top-14 right-8 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'flex gap-x-3 px-3 py-2 text-sm text-gray-800'
                      )}
                      onClick={() => setModalShow(true)}
                    >
                      <PencilSquareIcon className="h-6 w-6" /> Change Password
                    </span>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'flex gap-x-3 px-3 py-2 text-sm text-gray-800'
                      )}
                      onClick={handleLogout}
                    >
                      <ArrowLeftOnRectangleIcon className="h-6 w-6" /> Log out
                    </span>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>

        <main className="lg:pl-72">
          <Nabvar show={false} />
          {billingSubscription !== null &&
            billingSubscription.status === 'trialing' && (
              <div className="flex items-center bg-[#223c7d] px-6 py-1">
                <div className="text-sm leading-6 text-white mx-auto">
                  <strong className="mr-2">
                    Your free trial ends in{' '}
                    {daysBetween(billingSubscription?.current_period_end || 0)}{' '}
                    days.
                  </strong>
                  <Link to="/billing" className="text-white hover:text-white">
                    Add a payment method to avoid service interruption.
                  </Link>
                </div>
              </div>
            )}
          {billingSubscription !== null &&
            billingSubscription.status === 'canceled' && (
              <div className="flex items-center bg-[#223c7d] px-6 py-1">
                <div className="text-sm leading-6 text-white mx-auto">
                  <strong className="mr-2">
                    Your subscription has been canceled.
                  </strong>
                  <Link to="/billing" className="text-white hover:text-white">
                    Get a new subscription to avoid service interruption.
                  </Link>
                </div>
              </div>
            )}
          <div className="py-4 px-4 sm:px-6 lg:px-8">{component}</div>
        </main>
      </div>
      <ChangePassword show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default SidebarPage;
