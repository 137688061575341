import React from 'react';
// import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from 'react-redux';
// import { MdLocationOn } from "react-icons/md";
// import { BsChevronRight, BsChevronLeft, BsChevronBarRight, BsChevronBarLeft ,BsPlusCircleFill} from "react-icons/bs";
// import Switch from "react-switch";
// import { Link } from 'react-router-dom';
// import { getConnectorTemplate } from '../../services/services';
// import {State} from "../../types/state";
// import {ConnectorActionType} from "../../reducers/connector";
// const Pagination= require("react-js-pagination").default;

const ConnectorDestinationsForm = () => {
  return <p>TODO</p>;

  // const token = useSelector((state: State) => state.app?.token?.access_token);
  // const destination_data = useSelector((state: State) => state.source?.destination_data);
  // const data = useSelector((state: State) => state.connector?.template?.entities || []);
  // const [active, setActivePage] = useState(1);
  // const filterData = JSON.parse(localStorage.getItem('filterData') || '[]');
  // const finalData = (destination_data && destination_data.length && destination_data) || (filterData && filterData.length && filterData) || []
  // const id = parseInt(localStorage.getItem("sourceId") || '0');
  // const [ids, setIds] = useState<number[]>([])
  // const dispatch = useDispatch();
  //
  //
  // const handleChange = (item: number) => {
  //     if (ids.length > 0) {
  //         const index = ids.findIndex(id => id === item);
  //         if (index !== -1) {
  //             ids.splice(index, 1)
  //             setIds([...ids])
  //         } else {
  //             setIds([...ids, item])
  //         }
  //     } else {
  //         setIds([item])
  //     }
  // }
  //
  // useEffect(() => {
  //     if (!data.length && token) {
  //         (async () => {
  //            const connectorTemplate = await getConnectorTemplate(token, id)
  //            dispatch({ type: ConnectorActionType.CONNECTOR_TEMPLATE, payload: connectorTemplate })
  //         })()
  //     }
  // }, [data, id, dispatch, token]);
  //
  //
  // return (
  //     <>
  //         <p className="header-destination">Please enter credentials for the selected destination(s):</p>
  //         <div className="container mt-5">
  //
  //             <Accordion>
  //                 {
  //                     finalData && finalData.length && finalData.map((item: any, key: number) => (
  //                         <Accordion.Item eventKey={key.toString()} key={key}>
  //                             <Accordion.Header>
  //                                 <MdLocationOn className='table-icon' /> <h2 className="px-3 m-0" style={{ fontSize: "15px", fontWeight: "400", color: "rgba(0, 0, 0, 0.6)" }}>{item.name}</h2> <img src={item.icon} alt="img" style={{ height: "30px" }} className="table-icon" />
  //                             </Accordion.Header>
  //                             <Accordion.Body>
  //                                 <p className="create-new-one mt-5">Select one of your existing destinations or create a new one:</p>
  //                                 <div className='services_1 mt-5 mb-5'>
  //                                     <select className='choose'>
  //                                         <option value="1">Select</option>
  //                                         <option value="2">7 day</option>
  //                                         <option value="3">14 day</option>
  //                                     </select>
  //                                     <p className="m-0 px-5">or</p>
  //                                     <div>
  //                                     <Link to="/newdestination">
  //                                     <button className="new-dest">Create new destination<BsPlusCircleFill/></button>
  //                                     </Link>
  //                                 </div>
  //                                 </div>
  //
  //                                 <div className='table-info mt-5'>
  //                                     <table className="table">
  //                                         <thead>
  //                                             <tr>
  //                                                 <th>Entity </th>
  //                                                 <th>Description  </th>
  //                                                 <th className='rows'>  </th>
  //                                                 <th className="col-3">Active </th>
  //                                             </tr>
  //                                         </thead>
  //                                         <tbody>
  //                                             {
  //                                                 data && data.length > 0
  //                                                     ?
  //                                                     data.map((item, key: number) => {
  //                                                         if (key >= (active === 1 ? active - 1 : (active - 1) * 5) && key < active * 5) {
  //                                                             return (
  //                                                                 <tr key={key}>
  //                                                                     <td><p>{item.name}</p></td>
  //                                                                     <td><p>{item.description}</p></td>
  //                                                                     <td></td>
  //                                                                     <td>
  //                                                                         <Switch onChange={() => handleChange(item.id)} checked={ids.findIndex((id) => id === item.id) <= -1} checkedIcon={false} uncheckedIcon={false} offColor={"#ff0033"} offHandleColor={"#FFDCDC"} onHandleColor={"#D6FBD0"} activeBoxShadow={"none"} />
  //                                                                     </td>
  //                                                                 </tr>
  //                                                             )
  //                                                         }
  //                                                         return null
  //                                                     })
  //                                                     :
  //                                                     null
  //                                             }
  //                                         </tbody>
  //                                         <tfoot style={{ height: '60px' }}>
  //                                             <tr className='tfoot'>
  //                                                 <td className='total col-2'>{data?.length} total</td>
  //                                                 <td className='col-5'></td>
  //                                                 <td className='col-3'></td>
  //                                                 <td className='next-btn col-2 mt-3' style={{ display: 'flex' }}>
  //                                                     <Pagination
  //                                                         activePage={active}
  //                                                         itemsCountPerPage={5}
  //                                                         totalItemsCount={data.length}
  //                                                         pageRangeDisplayed={5}
  //                                                         firstPageText={<BsChevronBarLeft />}
  //                                                         lastPageText={<BsChevronBarRight />}
  //                                                         prevPageText={<BsChevronLeft />}
  //                                                         nextPageText={<BsChevronRight />}
  //                                                         itemClass="item"
  //                                                         activeLinkClass="active-item"
  //                                                         onChange={(pageNumber: number) => setActivePage(pageNumber)}
  //                                                     />
  //
  //                                                 </td>
  //                                             </tr>
  //                                         </tfoot>
  //                                     </table>
  //                                 </div>
  //                             </Accordion.Body>
  //                         </Accordion.Item>
  //                     ))
  //                 }
  //             </Accordion>
  //             <button className='run-con'><img src="./images/connector.png" alt="run-connector" className='pe-2 ps-0' />RUN CONNECTOR</button>
  //         </div>
  //     </>
  // )
};

export default ConnectorDestinationsForm;
