import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { FieldArray, Form, Formik } from 'formik';
import { DestinationActionType } from '../../reducers/destination';
import { ConnectorActionType } from '../../reducers/connector';
import { FieldSpec } from '../../types/api';
import { DynamicFormField } from './DynamicFormField';
import { ConnectorFormState, DestinationState, State } from '../../types/state';

export const DynamicForm = (props: {
  children?: React.ReactNode;
  type: DestinationActionType | ConnectorActionType;
  credentials: Record<string, any>;
  schema_form?: FieldSpec[];
  onSubmit: () => Promise<void>;
}) => {
  const dispatch = useDispatch();
  const formState = useSelector((state: State) =>
    props.type in DestinationActionType ? state.destination : state.connector
  );

  const getFormCredentials = (): Record<string, any> => {
    if (props.type in DestinationActionType) {
      return (formState as DestinationState).destinationFormValues.credentials;
    }
    return (formState as ConnectorFormState).sourceFormValues.credentials;
  };
  const updateState = (key: string, value: any) => {
    const credentials = getFormCredentials();
    const path = key.split('.');
    // Update value if it's not an Array
    if (path.length === 1) {
      dispatch({
        type: props.type,
        payload: { [key]: value }
      });
      return;
    }
    // Update value if it's an Array
    // 'key' is in the form of '<field>.<index>.<subfield>'
    if (path.length !== 3 && path.length !== 2) {
      console.warn('Invalid key');
      return;
    }
    const [field, index, subfield] = path;
    const i = parseInt(index);
    if (isNaN(i)) {
      return;
    }
    const array: Record<string, any>[] = credentials[field] || [];
    if (path.length === 3) {
      array[i] = array[i] || {};
      array[i][subfield] = value;
    } else if (path.length === 2) {
      array.splice(i, 1);
    }
    dispatch({
      type: props.type,
      payload: { [field]: array }
    });
  };

  const buildFieldArray = (
    field: FieldSpec,
    credentials: Record<string, any>
  ) => (
    <div className="sm:col-span-full p-2 rounded-md border border-gray-400">
      <FieldArray name={field.id}>
        {(arrayHelpers) => (
          <div>
            <div className="flex items-center justify-between pl-3 pr-3">
              <span className="sm:col-span-4 mr-4 text-sm font-medium leading-6 text-gray-900">
                {field.placeholder}
              </span>
              <button
                type="button"
                className="btnMovinglake w-fit h-7"
                onClick={() => {
                  arrayHelpers.push(
                    field.fields?.reduce(
                      (acc, v) => ({ ...acc, [v.id]: null }),
                      {}
                    )
                  );
                }}
              >
                <PlusCircleIcon className="h-5 w-5 mr-1" />
                Add
              </button>
            </div>
            {credentials[field.id]?.map((_: any, index: number) => (
              <div className="sm:col-span-full m-2 p-2 rounded-md border border-gray-300">
                {field.fields?.map((subField) => (
                  <DynamicFormField
                    fieldName={`${field.id}.${index}.${subField.id}`}
                    fieldSpec={subField}
                    sourceFormValues={getFormCredentials()}
                    updateValue={updateState}
                  />
                ))}
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="font-medium text-red-600 hover:text-red-500"
                    onClick={() => {
                      updateState(`${field.id}.${index}`, undefined);
                      arrayHelpers.remove(index);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </FieldArray>
    </div>
  );

  const buildFields = (credentials: Record<string, any>) => {
    const fields = props.schema_form?.map((field) =>
      field.type !== 'ARRAY' ? (
        <DynamicFormField
          fieldName={field.id}
          fieldSpec={field}
          sourceFormValues={getFormCredentials()}
          updateValue={updateState}
        />
      ) : (
        buildFieldArray(field, credentials)
      )
    );
    return <>{fields}</>;
  };

  return (
    <Formik initialValues={getFormCredentials()} onSubmit={props.onSubmit}>
      {({ values }) => {
        return (
          <Form>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-12">
              {buildFields(values)}
            </div>
            {props.children}
          </Form>
        );
      }}
    </Formik>
  );
};
