import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getDestinationTemplates } from '../../services/services';
import { DestinationTemplateListed } from '../../types/api';
import Path from '../../Path';
import { DestinationActionType } from '../../reducers/destination';
import { Mixpanel } from '../common/Mixpanel';

const DestinationTemplates = () => {
  const [destinationTypes, setDestinationTypes] = useState<
    DestinationTemplateListed[]
  >([]);
  const dispatch = useDispatch();
  Mixpanel.track('Connector Creation - Choose a Destination', {
    is_page_view: true
  });

  useEffect(() => {
    if (destinationTypes.length > 0) {
      return;
    }

    (async () => {
      const destinations = await getDestinationTemplates();
      setDestinationTypes(destinations);
    })();
  }, [destinationTypes]);

  const handleSelectedDestination =
    (item: DestinationTemplateListed) => async () => {
      try {
        dispatch({
          type: DestinationActionType.DESTINATION_TYPE,
          payload: { id: item.id }
        });
      } catch (e) {
        console.log(e);
      }
    };

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className="container mx-auto">
      <div className="px-4 py-3 sm:p-6 mt-3">
        <h3 className="text-base md:text-3xl text-center font-semibold leading-6 text-gray-900">
          Choose a destination type
        </h3>
      </div>
      <hr className="h-px my-3 mb-6 bg-gray-200 border-0" />
      <div className="p-5 divide-y overflow-hidden rounded-lg bg-transparent sm:grid sm:grid-cols-3 sm:gap-5 sm:divide-y-0">
        {destinationTypes.length > 0 &&
          destinationTypes.map((item, key) => (
            <div
              key={key}
              className={classNames(
                key === 0
                  ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                  : '',
                key === 1 ? 'sm:rounded-tr-lg' : '',
                key === destinationTypes.length - 2 ? 'sm:rounded-bl-lg' : '',
                key === destinationTypes.length - 1
                  ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                  : '',
                'destinationsList mt-5 sm:mt-0 group rounded-lg shadow-md relative bg-gray-50 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
              )}
              onClick={handleSelectedDestination(item)}
            >
              <div>
                <img
                  className="h-16 w-16 flex-none"
                  src={`${process.env.REACT_APP_API_URL}${item.icon}`}
                  alt=""
                />
              </div>
              <div className="mt-8">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  <Link
                    to={Path.NewDestinationForm}
                    className="focus:outline-none"
                  >
                    <span className="absolute inset-0" aria-hidden="true" />
                    {item.name}
                  </Link>
                </h3>
                <p className="mt-2 text-sm text-gray-500">{item.description}</p>
              </div>
              <span
                className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DestinationTemplates;
