import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AcceptInviteRequest } from '../../types/api';
import {
  Login,
  acceptInvite,
  getAccountSummary,
  notification
} from '../../services/services';
import * as Yup from 'yup';
import Path from '../../Path';
import { Field, Form, Formik } from 'formik';
import { GrLinkedinOption } from 'react-icons/gr';
import { useDispatch } from 'react-redux';
import { AppActionType } from '../../reducers/app';
import { setAccountData, setSessionData } from '../../services/account';

const AcceptInvite = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = useSearchParams()[0];
  const [values, setValues] = useState<AcceptInviteRequest>({
    password: '',
    cpassword: '',
    id: 0,
    token: '',
    first_name: '',
    last_name: ''
  });

  useEffect(() => {
    if (values.id !== 0 && values.token !== '') {
      return;
    }

    const id = searchParams.get('id');
    const token = searchParams.get('token');
    if (!id || !token) {
      notification('Invalid invite link', 'warning');
      navigate(Path.Login);
      return;
    }

    setValues({
      ...values,
      token,
      id: parseInt(id)
    });
  }, [navigate, values, searchParams]);

  const handleSubmit = async (formValues: AcceptInviteRequest) => {
    try {
      const payload = {
        ...formValues,
        id: values.id,
        token: values.token
      };
      const res = await acceptInvite(payload);

      if (res.status === 200) {
        notification('Registration complete!', 'success');

        const loginParams = {
          username: res.data.email,
          password: formValues.password,
          pending_tokens: {}
        };
        const session = await Login(loginParams);
        setSessionData(session);

        const account = await getAccountSummary();
        setAccountData(account);

        dispatch({
          type: AppActionType.APP_LOAD,
          payload: { account, session }
        });
        navigate(Path.ChooseDataSource);
      }
    } catch (e) {
      console.log(e);
      notification('Error accepting invite', 'warning');
    }
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
    cpassword: Yup.string()
      .required('Password confirmation is required')
      .min(6, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required')
  });

  return (
    <>
      <div className="flex min-h-full flex-1">
        <div className="mx-auto z-10 flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-5 xl:px-24">
          <div className="mx-auto max-w-md shadow-2xl bg-white py-3 px-6 rounded-lg">
            <div>
              <div className="flex flex-wrap gap-2 md:gap-5 px-5">
                <img
                  className="h-16 w-16 md:h-20 md:w-20 mx-auto"
                  src={`/logo512.png`}
                  alt="Your Company"
                />
                <p className="text-gray-900 self-center font-bold text-2xl md:text-5xl leading-10">
                  MovingLake
                </p>
              </div>
              <h2 className="mt-2 md:mt-8 text-xl md:text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Password Reset
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Select a new password for you account
              </p>
            </div>

            <div className="mt-5">
              <div>
                <Formik
                  initialValues={values}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                  validateOnBlur={false}
                  validateOnChange={false}
                >
                  {({ errors }) => (
                    <Form className="space-y-6">
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Name
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="first_name"
                            autoComplete="off"
                            className={`${
                              errors.first_name
                                ? 'border-red-500 border-10'
                                : 'border-0'
                            } block w-full rounded-md py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-submarine-600 sm:text-sm sm:leading-6`}
                          />
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Last Name
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="last_name"
                            autoComplete="off"
                            className={`${
                              errors.last_name
                                ? 'border-red-500 border-10'
                                : 'border-0'
                            } block w-full rounded-md py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-submarine-600 sm:text-sm sm:leading-6`}
                          />
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Password
                        </label>
                        <div className="mt-2">
                          <Field
                            type="password"
                            name="password"
                            autoComplete="off"
                            className={`${
                              errors.password
                                ? 'border-red-500 border-10'
                                : 'border-0'
                            } block w-full rounded-md py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-submarine-600 sm:text-sm sm:leading-6`}
                          />
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Confirm Password
                        </label>
                        <div className="mt-2">
                          <Field
                            type="password"
                            name="cpassword"
                            autoComplete="off"
                            className={`${
                              errors.cpassword
                                ? 'border-red-500 border-10'
                                : 'border-0'
                            } block w-full rounded-md py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-submarine-600 sm:text-sm sm:leading-6`}
                          />
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="text-sm leading-6">
                          <Link
                            to="/login"
                            className="font-semibold text-submarine-600 hover:text-submarine-500"
                          >
                            Already Registered? Login
                          </Link>
                        </div>
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="flex w-full justify-center btnMovinglake"
                        >
                          Register
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>

              <div className="mt-10">
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-900">
                      Follow us
                    </span>
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-2 gap-4">
                  <a
                    href="https://twitter.com/MovingLake"
                    className="flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
                  >
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                    <span className="text-sm font-semibold leading-6">
                      Twitter
                    </span>
                  </a>

                  <a
                    href="https://www.linkedin.com/company/movinglake/"
                    className="flex w-full items-center justify-center gap-3 rounded-md bg-[#0e76a8] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
                  >
                    <GrLinkedinOption className="h-5 w-5" />
                    <span className="text-sm font-semibold leading-6">
                      Linkedin
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute w-full h-full">
          <svg className="w-full h-full">
            <image href="/images/fondo6.svg" className="w-full h-full" />
          </svg>
        </div>
      </div>
    </>
  );
};

export default AcceptInvite;
