import axios from 'axios';
import { cleanupSessionAndAccount, sessionData } from './account';
import { notification } from './services';

/*
 * Default api handler
 */

const defaultApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

defaultApi.interceptors.request.use((config) => {
  const session = sessionData();
  if (session === null) {
    return config;
  }
  if (config.headers === undefined) {
    config.headers = {};
  }
  if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
    return config;
  }
  config.headers['Authorization'] = `Bearer ${session.access_token}`;
  return config;
});

defaultApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      cleanupSessionAndAccount();
      return (window.location.href = '/login');
    }
    return Promise.reject(error);
  }
);

/*
 * Destinations api handler
 */
export const destinationsApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

destinationsApi.interceptors.request.use((config) => {
  const session = sessionData();
  if (session === null) {
    return config;
  }
  if (config.headers === undefined) {
    config.headers = {};
  }
  if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
    return config;
  }
  config.headers['Authorization'] = `Bearer ${session.access_token}`;
  return config;
});

destinationsApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      cleanupSessionAndAccount();
      return (window.location.href = '/login');
    }
    const errorMessage: string =
      error.response.data.message ?? 'A service error ocurred';
    if (error.response.status >= 400 && error.response.status < 500) {
      notification(errorMessage, 'warning');
      return Promise.reject(error);
    } else {
      notification(errorMessage, 'danger');
      return Promise.reject(error);
    }
  }
);

export default defaultApi;
