import { Dialog, Transition } from '@headlessui/react';
import { Field, Form, Formik } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { Popover, SelectPicker, Toggle, Whisper } from 'rsuite';
import PaginationC from '../../common/PaginationC';

type CutsomParamsProps = {
  show: boolean;
  entity: any;
  customParams: any;
  setActiveParamsPage: (value: any) => void;
  onCustomChange: (value: any, name: string) => void;
  activeParams: number;
  itemsPerPage: number;
  onHandleSubmit: (values: any, actions: any) => void;
  onCancel: () => void;
};

const CustomParamsModal = ({
  show,
  entity,
  customParams,
  setActiveParamsPage,
  onCustomChange,
  activeParams,
  itemsPerPage,
  onHandleSubmit,
  onCancel
}: CutsomParamsProps) => {
  const [open, setOpen] = useState(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onCancel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-0 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <Formik
                  initialValues={{}}
                  onSubmit={(values, actions) => {
                    onHandleSubmit(values, actions);
                  }}
                >
                  <Form>
                    <div>
                      <div className="mx-auto flex items-center justify-center rounded-full">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900 ml-3"
                        >
                          Custom {entity.name}
                        </Dialog.Title>
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <div className="mt-2">
                          {entity.custom_params?.map(
                            (param: any, key: number) => {
                              if (
                                !(
                                  key >=
                                    (activeParams === 1
                                      ? activeParams - 1
                                      : (activeParams - 1) * itemsPerPage) &&
                                  key < activeParams * itemsPerPage
                                )
                              ) {
                                return null;
                              }
                              return (
                                <div className="grid grid-cols-2 mb-5">
                                  <div>
                                    <Whisper
                                      placement="bottomEnd"
                                      speaker={
                                        <Popover>{param.description}</Popover>
                                      }
                                    >
                                      <div className="flex items-center">
                                        <AiFillInfoCircle /> &nbsp;
                                        <p className="self-center text-base text-gray-700">
                                          {param.name}:
                                        </p>
                                      </div>
                                    </Whisper>
                                  </div>
                                  <div>
                                    {param.type === 'str' && (
                                      <Field
                                        type="text"
                                        name={param.name}
                                        autoComplete="off"
                                        placeholder={param.name}
                                        className="w-full border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                      />
                                    )}

                                    {param.type === 'int' && (
                                      <Field
                                        type="number"
                                        name={param.name}
                                        autoComplete="off"
                                        placeholder={param.name}
                                        className="w-full border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                      />
                                    )}

                                    {param.type === 'bool' && (
                                      <Toggle
                                        checked={customParams[param.name]}
                                        onChange={(value) => {
                                          onCustomChange(value, param.name);
                                        }}
                                      />
                                    )}

                                    {param.type === 'date' && (
                                      <Field
                                        type="date"
                                        name={param.name}
                                        autoComplete="off"
                                        placeholder={param.name}
                                        className="w-full border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                      />
                                    )}

                                    {param.type === 'datetime' && (
                                      <Field
                                        type="datetime-local"
                                        name={param.name}
                                        autoComplete="off"
                                        placeholder={param.name}
                                        className="w-full border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                                      />
                                    )}

                                    {param.type === 'list' && (
                                      <SelectPicker
                                        data={param.list_values.map(
                                          (item: any) => ({
                                            label: item,
                                            value: item
                                          })
                                        )}
                                        placeholder="Choose an option"
                                        menuStyle={{ zIndex: 9999 }}
                                        value={customParams[param.name]}
                                        onChange={(value) => {
                                          onCustomChange(value, param.name);
                                        }}
                                        className="w-full"
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3 gap-y-3">
                      <button type="submit" className="modalBtnMovinglake">
                        Save Custom Params
                      </button>
                    </div>
                  </Form>
                </Formik>
                {entity.custom_params?.length > 10 && (
                  <PaginationC
                    activePage={activeParams}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={entity.custom_params?.length}
                    pageRangeDisplayed={itemsPerPage}
                    onPageChange={(paramsPageNumber: number) =>
                      setActiveParamsPage(paramsPageNumber)
                    }
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CustomParamsModal;
