import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  getConnectorTemplateList,
  getCategories,
  notification,
  updateOrganization,
  getAccountSummary
} from '../../services/services';
import {
  ConnectorCategoryListed,
  ConnectorTemplateListed
} from '../../types/api';
import Path from '../../Path';
import { ConnectorActionType } from '../../reducers/connector';
import { AppActionType } from '../../reducers/app';
import { Mixpanel } from '../common/Mixpanel';
import { State } from '../../types/state';
import {
  cleanupSessionAndAccount,
  setAccountData
} from '../../services/account';
import Loader from '../common/Loader';
import ConnectorInfoModal from './modals/ConnectorInfoModal';
import { PlusIcon } from '@heroicons/react/24/outline';
import CompleteOrganizationModal from './modals/CompleteOrganizationModal';
import { Popover, Whisper } from 'rsuite';

type sourceTemplate = {
  id: number;
  name: string;
  connector_categories: { name: string }[];
  icon: string;
};

const SourceTypeSelector = () => {
  const [categories, setCategories] = useState<ConnectorCategoryListed[]>([]);
  const [showCategoryFilters, setShowCategoryFilters] = useState(false);
  const [showModal, setShow] = useState(false);
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);
  const [selectedSource, setSelectedSource] = useState<sourceTemplate | null>(
    null
  );
  const [activeCategories, setActiveCategories] = useState<string[]>([]);
  const [nameFilter, setNameFilter] = useState('');
  const [connectorList, setConnectorList] = useState<ConnectorTemplateListed[]>(
    []
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  Mixpanel.track('Connector Creation - Page View', { is_page_view: true });
  const searchParams = useSearchParams()[0];
  const account = useSelector((state: State) => state.app?.account);
  const [loading, setLoading] = useState(false);

  const filterSource = (source: sourceTemplate) => {
    let matchesName = true;
    let matchesCategory = true;

    if (nameFilter.length > 0) {
      matchesName = source.name
        .toLowerCase()
        .includes(nameFilter.toLowerCase());
    }
    if (activeCategories.length > 0) {
      let sourceCategories = source.connector_categories.map((c) => c.name);
      matchesCategory = !!sourceCategories.find((c) =>
        activeCategories.includes(c)
      );
    }
    return matchesName && matchesCategory;
  };

  const handleModal = (source: sourceTemplate) => {
    setSelectedSource(source);
    Mixpanel.track('Connector Creation - Select Source Type', {
      is_click: true,
      source_type: source.name
    });
    setShow(true);
  };

  const onHandleSubmit = (values: {
    name: string;
    description: string;
    id: number;
  }) => {
    if (selectedSource) {
      values.id = selectedSource.id;

      dispatch({
        type: ConnectorActionType.CONNECTOR_NAME_DATA,
        payload: values
      });
      Mixpanel.track('Connector Creation - Submit Source Type', {
        is_click: true,
        source_type: selectedSource.name
      });
      navigate(Path.NewDataSourceForm);
    } else {
      console.log('All Fields Are required');
    }
  };

  const handleOrganizationUpdate = (values: {
    name: string;
    org_name: string;
  }) => {
    (async () => {
      const params = { ...values };
      const response = await updateOrganization(params);
      if (response.status === 200) {
        if (response.data.error) {
          notification(
            `Company ${response.data.message
              .split('=')[1]
              .replace(/\(|\)/g, '')}`,
            'danger'
          );
        } else {
          notification(response.data.message, 'success');
          const account = await getAccountSummary();
          setAccountData(account);
          setShowOrganizationModal(false);
          window.location.reload();
        }
      }
    })();
  };

  const handleFilter = (name: string) => {
    let newActive = [];
    if (activeCategories.includes(name)) {
      newActive = activeCategories.filter((v) => v !== name);
    } else {
      newActive.push(name);
    }
    setActiveCategories(newActive);
  };

  useEffect(() => {
    if (categories.length > 0) {
      return;
    }
    (async () => {
      const categories = await getCategories();
      setCategories(categories);
    })();
  }, [categories]);

  useEffect(() => {
    if (connectorList && connectorList.length > 0) {
      return;
    }
    (async () => {
      try {
        const connectorTemplates = await getConnectorTemplateList();
        setConnectorList(connectorTemplates);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [connectorList]);

  useEffect(() => {
    dispatch({
      type: AppActionType.IS_CREATING_CONNECTOR,
      payload: true
    });
  }, [dispatch]);

  useEffect(() => {
    if (!account) {
      return;
    }
    if (searchParams.get('verified')) {
      setLoading(true);
      setTimeout(() => {
        cleanupSessionAndAccount();
        dispatch({
          type: AppActionType.LOGOUT,
          payload: null
        });
        setLoading(false);
        navigate(`${Path.Login}?verified=${searchParams.get('verified')}`);
      }, 600);
    } else {
      if (!account?.organization?.is_active) {
        notification('Your email is not verified, please confirm', 'warning');
      }
      if (!account?.organization?.is_complete) {
        setShowOrganizationModal(true);
      }
    }
  }, [account, searchParams, dispatch, navigate]);

  return (
    <>
      <div className="container mx-auto">
        {!loading ? (
          <>
            <div>
              <div className="px-4 py-3 sm:p-6 mt-3">
                <h3 className="text-base md:text-3xl text-center font-semibold leading-6 text-gray-900">
                  Where do you need to get your data from?
                </h3>
                <div className="w-full sm:max-w-xs mx-auto mt-3">
                  <div className="mt-2">
                    <input
                      onChange={(e) => setNameFilter(e.target.value)}
                      className="peer block w-full border-0 border-b-2 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Search our data sources"
                    />
                  </div>
                </div>
                <div className="mx-auto flex">
                  <button
                    type="submit"
                    className="mt-3 mx-auto btnMovinglake w-fit"
                    onClick={() => setShowCategoryFilters(!showCategoryFilters)}
                  >
                    Filter by category
                  </button>
                </div>

                <div className={`${showCategoryFilters && 'flex mt-3'}`}>
                  <ul
                    className={`mt-2 mx-auto ${
                      showCategoryFilters ? 'flex flex-wrap gap-3' : 'hidden'
                    }`}
                  >
                    {categories &&
                      categories.length &&
                      categories.map((catItem, key) => (
                        <li key={key} className="">
                          <button
                            className="btnMovinglake w-fit"
                            onClick={() => handleFilter(catItem.name)}
                          >
                            {catItem.name}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <hr className="h-px my-3 mb-6 bg-gray-200 border-0" />
            <div className="mb-5">
              <ul className="px-5 mt-3 mb-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                {connectorList
                  .filter((item) => filterSource(item))
                  .map((item) => (
                    <li
                      key={item.id}
                      className="sourcesList col-span-1 flex rounded-md shadow-sm bg-gray-50"
                    >
                      <div
                        className={
                          'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                        }
                      >
                        <img
                          src={`${process.env.REACT_APP_API_URL}${item.icon}`}
                          alt=""
                          className="p-1 w-20"
                        />
                      </div>
                      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                        <div className="flex-1 truncate px-4 py-2 ">
                          <p className="font-bold text-black">{item.name}</p>
                          <Whisper
                            followCursor
                            placement="auto"
                            speaker={
                              <Popover
                                title={item.name}
                                className="text-gray-700"
                              >
                                {item.description}
                              </Popover>
                            }
                          >
                            <p className="text-gray-500">{item.description}</p>
                          </Whisper>
                        </div>
                        <div className="flex-shrink-0 pr-2">
                          <button
                            type="button"
                            className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                          >
                            <span className="sr-only">Open options</span>
                            <PlusIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                              onClick={() => handleModal(item)}
                            />
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
      <div className="mt-5">
        <br />
      </div>
      <ConnectorInfoModal
        show={showModal}
        item={selectedSource}
        onHandleSubmit={onHandleSubmit}
        onCancel={() => {
          setShow(false);
        }}
      />

      <CompleteOrganizationModal
        show={showOrganizationModal}
        handleOrganizationUpdate={handleOrganizationUpdate}
      />
    </>
  );
};

export default SourceTypeSelector;
