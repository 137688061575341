import React, { useState, useEffect } from 'react';
import Routers from './router';
import { ReactNotifications } from 'react-notifications-component';
import { useSelector, useDispatch } from 'react-redux';
import 'react-notifications-component/dist/theme.css';
import Loader from './components/common/Loader';
import { State } from './types/state';
import { AppActionType } from './reducers/app';
import { accountData, sessionData } from './services/account';
import { Mixpanel } from './components/common/Mixpanel';

function App() {
  const [isAuth, setAuth] = useState(false);
  const [isComponentReady, setComponentReady] = useState(false);
  const session = useSelector((state: State) => state.app?.session);
  const account = useSelector((state: State) => state.app?.account);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!session) {
      if (sessionData() === null) {
        setComponentReady(true);
        return;
      }
    }

    if (session) {
      dispatch({ type: AppActionType.APP_LOAD, payload: { account, session } });
      setAuth(true);
      if (account && account.user.email) {
        Mixpanel.identify(account.user.email);
      }
      return;
    }

    const storedSession = sessionData();
    const storedAccount = accountData();

    if (storedSession && storedAccount) {
      dispatch({
        type: AppActionType.APP_LOAD,
        payload: { account: storedAccount, session: storedSession }
      });
      setAuth(true);
    }
    setComponentReady(true);
  }, [session, account, dispatch]);

  return (
    <>
      {isComponentReady ? (
        <>
          <ReactNotifications />
          <Routers auth={isAuth} />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default App;
