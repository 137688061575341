import React from 'react';
import { accountData } from '../../services/account';
import { Navigate } from 'react-router-dom';
import Path from '../../Path';

const Root = () => {
  const account = accountData();

  if (!account) {
    return null;
  }

  if (account.resources.connectors.length > 0) {
    return <Navigate to={Path.ConnectorList} />;
  } else if (
    account.organization.feature_flags &&
    account.organization.feature_flags['dbt_only'] === true
  ) {
    if (account.resources.destinations.length > 0) {
      return <Navigate to={Path.DestinationList} />;
    } else {
      return <Navigate to={Path.ChooseDestination} />;
    }
  } else {
    return <Navigate to={Path.ChooseDataSource} />;
  }
};

export default Root;
